import React,{useState,useEffect} from 'react'
import Layout from '../../../components/Layouts/Layouts/Layout'
import MasterNavigationBar from '../../../components/Layouts/Layouts/MasterServicesLayout'
import DashboardHeader from '../../../components/basic/DashboardHeader'
import axios from 'axios'
import { getURLbyEndPoint } from '../../../store/api'

const ValidityAccess = () => {
    const [clientList, setClientList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

 // //filtering data------------------------------------------------------
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  const filteredData = clientList.filter((data) => {
    console.log(searchTerm);
    // Filter based on app NO or forComRegion
    if (
      searchTerm !== "" &&
      !data.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });


  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const fetchDumpFiles = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getAllUserList"));
      if (response.status === 200) {
        const data = response.data.data;
        setClientList(data);
      } else {
        throw new Error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      alert("Failed to fetch data. Please try again later.");
    }
  };

  // useEffect(() => {
  //   fetchDumpFiles();
  // }, []);


  return (
    <Layout>
    <MasterNavigationBar>
    <div className="d-flex flex-column px-3">
      <DashboardHeader heading="Validity Access" backUrl="/special-controls" />
      <hr />
      <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              className="form-control w-50"
              type="search"
              placeholder="Search By Company Name/Email"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

</div>
      </MasterNavigationBar>

      </Layout>
  )
}

export default ValidityAccess