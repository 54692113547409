import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import {
  Form,
  Table,
  Spinner,
  Button,
  Modal,
  CloseButton,
  Pagination,
} from "react-bootstrap";
import { getURLbyEndPointV2 } from "../../../store/api";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import axios from "axios";
import { formatDate } from "../profilePages/ClientSuperAdmin";

const AdBooking = () => {
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [adsData, setAdsData] = useState([]);
  const [formData, setFormData] = useState({});

  const fetchAdsData = async () => {
    try {
      const response = await axios.get(getURLbyEndPointV2("getAllAds"));
      if (response.status === 200) {
        const data = response.data.data;
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
              appDisApi: false,
              isEditing: false,
              remainingDays: calculateRemainingDays(item.adValidity),
              FromDate: "",
              logoFile: null,
            };
          }
          return item;
        });

        setAdsData([...modifiedData]);
      } else {
        console.error("Failed to fetch ads data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateAdValue = async (id, newValue) => {
    try {
      const response = await axios.put(getURLbyEndPointV2("updateAd") + id, {
        adValue: newValue,
      });
      if (response.status === 200) {
        fetchAdsData();
      } else {
        console.error("Failed to update ad value.");
      }
    } catch (error) {
      console.error("Error updating ad value:", error);
    }
  };

  const handleEditClick = (id) => {
    setAdsData((prevData) =>
      prevData.map((item) =>
        item._id === id ? { ...item, isEditing: !item.isEditing } : item
      )
    );
  };

  const handleUpdateClick = (id, newValue) => {
    updateAdValue(id, newValue);
    handleEditClick(id);
  };

  const handleApproveRejectAds = async (userId, status) => {
    apiLoading(true, userId);

    const clientData = adsData.find((user) => user._id === userId);

    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectAds") + userId,
        { status, remark: clientData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        apiLoading(true, userId);
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      apiLoading(true, userId);
    }
  };

  const CompanyActivity = ({ activities }) => {
    let activityArray = [];
    if (typeof activities === "string") {
      activityArray = activities.split(",");
    } else {
      activityArray = activities;
    }
    return (
      <ol>
        {activityArray.map((activity, index) => (
          <li key={index}>{activity}</li>
        ))}
      </ol>
    );
  };

  function downloadImage(url) {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = url.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the image", error));
  }

  const calculateRemainingDays = (validityDays) => {
    const currentDate = new Date();
    const expirationDate = new Date(
      currentDate.getTime() + validityDays * 24 * 60 * 60 * 1000
    );
    const differenceInTime = expirationDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays >= 0 ? differenceInDays : 0;
  };

  //simple functions
  const handleInputChange = (itemId, name, value) => {
    setAdsData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleInputDateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      logoFile: file,
    }));
  };

  const handleUploadTamp = async (id) => {
    if (!formData.logoFile || !formData.FromDate) {
      alert("Please select a file and date to upload.");
      return;
    }

    // Fetch current ads to check for conflicts
    try {
      const currentAdsResponse = await axios.get(
        getURLbyEndPointV2("getAllApprovedAdsData")
      );
      const currentAds = currentAdsResponse.data.data;
      const selectedStartDate = new Date(formData.FromDate);

      for (const ad of currentAds) {
        const startDate = new Date(ad.startsFrom);
        const expiryDate = new Date(startDate);
        expiryDate.setDate(expiryDate.getDate() + parseInt(ad.adValidity, 10));

        if (startDate <= selectedStartDate && selectedStartDate <= expiryDate) {
          alert(
            `An ad is already live during this period. Current live ad expires on ${expiryDate.toDateString()}`
          );
          return;
        }
      }

      const formDataToSend = new FormData();
      formDataToSend.append("logoFile", formData.logoFile);
      formDataToSend.append("fromDate", formData.FromDate);
      // formDataToSend.append('adValidity', formData.adValidity);

      const response = await axios.post(
        getURLbyEndPointV2("uploadAd") + id,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert("An error occurred while uploading the ad.");
      }
      console.error("Error Occurred", error);
    }
  };

  const apiLoading = async (flag, userId) => {
    setAdsData((prevData) => {
      const updatedData = prevData.map((user) => {
        if (user._id === userId) {
          return { ...user, appDisApi: flag };
        }
        return user;
      });
      return updatedData;
    });
  };

  const filteredData = adsData.filter((data) => {
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Paginatio logic----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Reset to the first page whenever the search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchAdsData();
  }, []);

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="AD BOOKING"
            backUrl="/master-publication-ads"
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex gap-3">
              <Form.Check
                inline
                label="All"
                name="group1"
                type="radio"
                checked={reqStatus === ""}
                onChange={() => setReqStatus("")}
              />
              <Form.Check
                inline
                label="Processing"
                name="group1"
                type="radio"
                checked={reqStatus === "Processing"}
                onChange={() => setReqStatus("Processing")}
                defaultChecked
              />
              <Form.Check
                inline
                label="Approved"
                name="group1"
                type="radio"
                checked={reqStatus === "Approved"}
                onChange={() => setReqStatus("Approved")}
              />
              <Form.Check
                inline
                label="Fully Approved"
                name="group1"
                type="radio"
                checked={reqStatus === "FullyApproved"}
                onChange={() => setReqStatus("FullyApproved")}
              />
              <Form.Check
                inline
                label="Rejected"
                name="group1"
                type="radio"
                checked={reqStatus === "Rejected"}
                onChange={() => setReqStatus("Rejected")}
              />
            </div>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search By Company Name."
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Sr No.
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Application No.
                    <br />
                    Date With Time
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Company Details
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Company Logo
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Activity
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Contact
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    validity
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Transaction Details
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Action
                  </th>
                </tr>
              </thead>
              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((data, index) => (
                    <tbody className="text-center">
                      <tr key="index">
                        <td>
                          {filteredData.length - indexOfFirstItem - index}.
                        </td>
                        <td>
                          <div>
                            {data.appNo}{" "}
                            <h6 className="text-success">
                              {formatDateTimeStandard(data.createdAt)}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div>
                            Company Name: <strong>{data.companyName}</strong>
                          </div>
                          <div>
                            Website:{" "}
                            <a
                              href={data.websiteUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {data.websiteUrl}
                            </a>
                          </div>
                        </td>
                        <td>
                          <div>
                            <img
                              src={data.companyLogo}
                              alt="logo"
                              style={{
                                height: "80px",
                                width: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => downloadImage(data.companyLogo)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="text-left">
                            <div
                              className="d-flex justify-content-between flex-wrap"
                              style={{
                                width: "260px",
                                maxWidth: "max-content",
                              }}
                            >
                              <CompanyActivity
                                activities={data.companyActivity}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="text-left">
                            <div className="text-nowrap">
                              Email: {data.emailId}
                            </div>
                            <div className="text-nowrap">
                              Phone: {data.phoneNo}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {data.adValidity} Days
                          </div>
                          {/* <div className="text-danger text-nowrap">
                            {data.remainingDays} days remaining
                          </div> */}
                        </td>
                        <td>
                          <div className="text-nowrap d-flex align-items-center gap-2">
                            {data.isApproved === "Processing" ? (
                              <>
                                {!data.isEditing ? (
                                  <>
                                    <div>Amount: {data.adValue}/-</div>
                                    <FaEdit
                                      variant="link"
                                      onClick={() => handleEditClick(data._id)}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Form.Control
                                      type="number"
                                      value={data.adValue}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setAdsData((prevData) =>
                                          prevData.map((item) =>
                                            item._id === data._id
                                              ? { ...item, adValue: value }
                                              : item
                                          )
                                        );
                                      }}
                                      style={{
                                        width: "110px",
                                        display: "inline-block",
                                      }}
                                    />

                                    <FaCheckSquare
                                      variant="link"
                                      className="text-success fs-4"
                                      onClick={() =>
                                        handleUpdateClick(
                                          data._id,
                                          data.adValue
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div>Amount: {data.adValue}/-</div>
                              </>
                            )}
                          </div>
                          <div className="my-2">
                            {data.isApproved !== "Processing" ? (
                              <PopUpModalButtonReceipt
                                title="Receipt"
                                fileLink={data.receipt}
                                disabled={false}
                              />
                            ) : (
                              <>
                                <PopUpModalButtonReceipt
                                  title="Receipt"
                                  fileLink={data.receipt}
                                  disabled={true}
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="max-content mx-auto">
                            {data.isApproved === "Processing" && (
                              <>
                                <div className="max-content">
                                  <Form.Group>
                                    <Form.Label className="mb-0">
                                      Remark
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      rows={3}
                                      maxLength={50}
                                      onChange={(e) =>
                                        handleInputChange(
                                          data._id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      value={data.remark}
                                      name="remark"
                                    />
                                  </Form.Group>
                                  <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                    {data.appDisApi ? (
                                      <Spinner animation="border text-success" />
                                    ) : (
                                      <>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                          onClick={() =>
                                            handleApproveRejectAds(
                                              data._id,
                                              true
                                            )
                                          }
                                        >
                                          <AiFillCheckCircle className="w-100 icon-size green-color" />
                                        </Button>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                          onClick={() =>
                                            handleApproveRejectAds(
                                              data._id,
                                              false
                                            )
                                          }
                                        >
                                          <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {data.isApproved !== "Processing" && (
                              <>
                                <div
                                  className={
                                    data.isApproved === "Approved"
                                      ? "text-success"
                                      : data.isApproved === "Rejected"
                                      ? "text-danger"
                                       : data.isApproved === "FullyApproved"
                                     ? "text-success"
                                      : ""
                                  }
                                >
                                 {data.isApproved === "FullyApproved"
                                  ? "Approved"
                                  : data.isApproved}
                                </div>
                                <div>
                                  Remark : {data.remark}
                                </div>

                                {data.isApproved === "Approved" && (
                                  <>
                                    <div className="my-3 W-25">
                                      <Form.Group controlId="FromDate">
                                        <div className="d-flex gap-2 justify-content-center align-items-center">
                                          <div className="text-nowrap">
                                            <Form.Label>From Date:</Form.Label>
                                          </div>
                                          <div className="w-50">
                                            <Form.Control
                                              type="date"
                                              name="FromDate"
                                              value={formData.FromDate}
                                              onChange={handleInputDateChange}
                                            />
                                          </div>
                                        </div>
                                      </Form.Group>
                                      <div className="d-flex my-2 w-75 mx-auto">
                                        <input
                                          type="file"
                                          className="form-control"
                                          id="inputGroupFile02"
                                          accept="image/*"
                                          onChange={handleLogoChange}
                                          required
                                        />
                                      </div>
                                      <div className="my-2">
                                        <Button
                                          onClick={() =>
                                            handleUploadTamp(data._id)
                                          }
                                        >
                                          Upload
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {data.isApproved === "FullyApproved" && (
                                  <>
                                    <div>
                                      AD Start From:{" "}
                                      {formatDate(data.startsFrom)}
                                    </div>
                                    <div>
                                      AD Ends At:{" "}
                                      {formatDate(data.endDate)}
                                    </div>
                                    <div>
                                      <Button
                                        size="sm"
                                        onClick={() =>
                                          window.open(
                                            data.adBanner,
                                            "_blank",
                                            "noopener,noreferrer"
                                          )
                                        }
                                      >
                                        View AD
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={12} className="text-center">
                      No Matching Data
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
          {totalPages > 1 && (
            <div className="d-flex justify-content-start">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdBooking;

export function PopUpModalButtonReceipt({ title, fileLink, disabled }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className=" text-nowrap px-2 py-1 text-white rounded small-font"
        onClick={() => setModalShow(true)}
        disabled={disabled}
      >
        {title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#0b3b5d", color: "white" }}
          className="text-white"
        >
          <Modal.Title>{title}</Modal.Title>

          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>{" "}
        <Modal.Body>
          <div>
            <iframe
              src={fileLink}
              title={title}
              width="100%"
              height="700px"
              frameBorder="0"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
