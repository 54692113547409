import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";

import { Form, Table, Pagination, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";
import { TruncatedTextArea } from "../../../components/basic/textcustomized";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ChatBox from "../../../components/chatComponents/ChatBox";

const ExportWing = () => {
  const [wingData, setWingData] = useState([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [reqStatus, setReqStatus] = useState("");

  useEffect(() => {
    const fetchAllWingData = async () => {
      try {
        const res = await axios.get(
          getURLbyEndPointV2("getWingData") + "Export"
        );
        if (res.status === 200) {
          const data = res.data.data;
          setWingData(data);
        } else {
          alert("Failed to fetch co data.");
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };
    fetchAllWingData();
  }, []);

  const handleAppRejQuery = async (userId, status) => {
    console.log(userId, status);
    try {
      const res = await axios.put(getURLbyEndPointV2("appRejQuery") + userId, {
        status,
      });

      if (res.data.status) {
        alert("Query updated successfully");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while approving or rejecting:", error);
    }
  };

  const filteredData = wingData.filter((item) => {
    // Filter based on account status
    if (reqStatus !== "" && item.isApproved !== reqStatus) {
      return false;
    }
  
    // Check for undefined values before calling toLowerCase
    const companyName = item?.client?.companyName ? item.client.companyName.toLowerCase() : "";
    const tokenNo = item?.tokenNo ? item.tokenNo.toLowerCase() : "";
    console.log(companyName);
    console.log(wingData.companyName);
    
  
    // Filter based on appNo, forComRegion, or email
    if (
      searchTerm !== "" &&
      !companyName.includes(searchTerm.toLowerCase()) &&
      !tokenNo.includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
  
    if (accountStatus && item.status !== accountStatus) {
      return false;
    }
  
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="The Wings/ Export Wing"
            backUrl="/master-wings"
          />
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex gap-3">
              <Form.Check
                inline
                label="All"
                name="group1"
                type="radio"
                checked={reqStatus === ""}
                onChange={() => setReqStatus("")}
              />
              <Form.Check
                inline
                label="Processing"
                name="group1"
                type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                defaultChecked
              />

              <Form.Check
                inline
                label="Approved"
                name="group1"
                type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
              />
              <Form.Check
                inline
                label="Disapproved"
                name="group1"
                type="radio"
                  checked={reqStatus === "Disapproved"}
                  onChange={() => setReqStatus("Disapproved")}
              />
            </div>
            <input
              className="form-control w-50"
              type="search"
              placeholder="Search By Company Name / Token No."
              aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="align-middle no-wrap">Sr No.</th>
                <th className="align-middle no-wrap">Company details</th>
                <th className="align-middle no-wrap">Type of Query</th>
                <th className="align-middle no-wrap">Type of Topic</th>
                <th className="align-middle no-wrap">Summary</th>
                <th className="align-middle no-wrap">Status</th>
                <th className="align-middle no-wrap">Conversation</th>
              </tr>
            </thead>
            {currentFiltered.length > 0 ? (
              <>
                {currentFiltered.map((data, index) => (
                  <tbody>
                    <tr key={index}>
                      <th className="text-start">
                        {filteredData.length - indexOfFirstItem - index}.
                        <div className="max-content">
                          Created at:
                          <br />
                          <span className="text-success">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(data.createdAt))}
                          </span>
                        </div>
                      </th>
                      <td>
                        <div className="text-start max-content">
                          <h6>
                            Company Name :{" "}
                            <span className="text-secondary">
                              {" "}
                              {data.client.companyName}
                            </span>
                          </h6>
                          <h6>
                            Membership No. :{" "}
                            <span className="text-secondary">
                              {data.client.memberShipNo}
                            </span>
                          </h6>
                          <h6>
                            Email :{" "}
                            <span className="text-secondary">
                              {data.client.email}
                            </span>
                          </h6>
                        </div>{" "}
                      </td>
                      <td>{data.query}</td>
                      <td>{data.topic}</td>
                      <td>
                        <TruncatedTextArea text={data.summary} maxChars={100} />

                        {data.refDoc && (
                          <div>
                            <a
                              href={data.refDoc}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Refrence Doc
                            </a>
                          </div>
                        )}
                      </td>
                      <td>
                        <Col>
                          {data.isApproved === "Processing" && (
                            <Row>
                              <Col md={4}>
                                <AiFillCheckCircle
                                  className="icon-size green-color cursor-pointer me-3"
                                  onClick={() =>
                                    handleAppRejQuery(data._id, true)
                                  }
                                />
                              </Col>
                              <Col md={4}>
                                <AiFillCloseCircle
                                  className="icon-size text-danger cursor-pointer"
                                  onClick={() =>
                                    handleAppRejQuery(data._id, false)
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                          <Col>
                            {data.isApproved == "Approved" && data?.tokenNo && (
                              <pre>Token No. :{data?.tokenNo}</pre>
                            )}
                          </Col>
                          <Col>
                            {data.isApproved == "Approved" && (
                              <div className="max-content fw-bold align-top">
                                Approved at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.updatedAt))}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col>
                            {data.isApproved == "Disapproved" && (
                              <div className="max-content fw-bold">
                                Disapproved at:
                                <br />
                                <span className="text-danger">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.updatedAt))}
                                </span>
                              </div>
                            )}
                          </Col>
                        </Col>
                      </td>
                      <td>
                        <div>
                          <ChatBox
                            disabled={!(data.isApproved === "Approved")}
                            tokenNo={data.tokenNo}
                            wingId={data._id}
                            client={data.client}
                            summary={data.summary}
                            isPaused={data.isPaused}
                            isClosed={data.isClosed}
                            rating={data.rating}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}{" "}
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={12} className="text-center">
                    No Matching Data
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ExportWing;
