import React, { useState, useEffect } from "react";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { Table, Pagination, Button } from "react-bootstrap";

const DumpFiles = () => {
  const [dumpFileData, setDumpFileData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // //filtering data------------------------------------------------------
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  const filteredData = dumpFileData.filter((data) => {
    console.log(searchTerm);
    // Filter based on app NO or forComRegion
    if (
      searchTerm !== "" &&
      !data.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const fetchDumpFiles = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getDumpFiles"));
      if (response.status === 200) {
        const data = response.data.data;
        setDumpFileData(data);
      } else {
        throw new Error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      alert("Failed to fetch data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDumpFiles();
  }, []);

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="Dump Files" backUrl="/special-controls" />
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search By description"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Sr No.
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Description
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    File
                  </th>

                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Action
                  </th>
                </tr>
              </thead>

              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((data, index) => (
                    <tbody className="text-center">
                      <tr key="index">
                        <td>
                          {filteredData.length - indexOfFirstItem - index}.
                        </td>
                        <td className="text-start">{data.description}</td>
                        <td>
                          <a href={data.fileLink}>View</a>
                        </td>
                        <td>
                          <Button variant="danger">Delete</Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={12} className="text-center">
                      No Matching Data
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default DumpFiles;
