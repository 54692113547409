import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterServicesLayout from "../../../components/Layouts/Layouts/MasterServicesLayout";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";

const ChangePassword = () => {
  const [changePasswordData, setChangePasswordData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const fetchChangePassword = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getChangePassword"));
      if (response.status === 200) {
        const data = response.data;
        const latestData = data.data.reverse();
        setChangePasswordData([...latestData]);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChangePassword();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentChangePasswordData = changePasswordData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(changePasswordData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Profile/ Change Password"
            backUrl="/master-profile"
          />
          <hr />
          <div className="mb-3">
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search Company Name"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Table striped bordered hover className="text-center">
            <thead>
              <tr>
                <th className="align-middle">SR NO.</th>
                <th className="align-middle">Company Name</th>
                <th className="align-middle">Membership No.</th>
                <th className="align-middle">Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {currentChangePasswordData
                .filter((data) =>
                  data.companyName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((data, index) => (
                  <tr key={index}>
                    <td className="fw-semibold">
                      {index + 1 + indexOfFirstItem}.
                    </td>
                    <td className="fw-semibold">{data.companyName}</td>
                    <td className="fw-semibold">{data.memberShipNo}</td>
                    <td className="fw-semibold text-success">
                      {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(data.updatedAt))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>{" "}
      </div>
    </Layout>
  );
};

export default ChangePassword;
