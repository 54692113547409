import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  Row,
  FormControl,
  InputGroup,
  Card,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Message from "./Message";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";
import { TruncatedTextArea } from "../basic/textcustomized";
import { io } from "socket.io-client";

const socket = io("http://localhost:3001", {
  withCredentials: true,
});

const NonDisputeArbitrationChatBox = ({ disabled, refNo, arbitrationId, summary }) => {
  const [show, setShow] = useState(false);
  const [msgList, setMsgList] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    socket.emit("fetchMessages", arbitrationId);
  };
  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 72)}px`; // Adjust height dynamically, with a max height of 72px (approx. 3 rows)
    textarea.style.overflow = textarea.scrollHeight > 72 ? "auto" : "hidden";
  };

  // Handle incoming messages from Socket.IO
  useEffect(() => {
    socket.emit("joinRoom", arbitrationId);

    socket.on("allMessages", (messages) => {
      setMsgList(messages);
    });

    return () => {
      socket.off("allMessages");
      socket.emit("leaveRoom", arbitrationId);
    };
  }, [arbitrationId]);

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const messageData = {
    arbitrationId,
      message: newMessage,
      receiver: "user",
      sender: "admin",
      createdAt: new Date().toISOString(),
    };

    socket.emit("sendMessage", messageData);
    setNewMessage("");
  };

  return (
    <>
      <Button
        variant="success"
        className="me-2"
        onClick={handleShow}
        disabled={disabled}
      >
        {" "}
        Chat
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Modal.Title className="h5">Refrence No: {refNo}</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body className="h-100">
            <Row className="h-100 mx-1">
              <Col
                md={8}
                className="d-flex flex-column align-items-end h-100 w-100 bg-light "
              >
                <div
                  className="d-flex flex-column mt-auto w-100 overflow-auto"
                  style={{ maxHeight: "550px" }}
                >
                  {msgList.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg.type === "aecciMsg" ? (
                        <AecciMessage text={msg.message} />
                      ) : msg.type === "msg" ? (
                        msg.sender === "admin" ? (
                          <Message
                            align="sent"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        ) : (
                          <Message
                            align="Received"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        )
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>
                <hr className="w-100" />
                <InputGroup className="w-100 mb-2">
                  <OverlayTrigger
                    trigger="click"
                    key="top"
                    placement="top"
                    overlay={
                      <Popover id="popover-positioned-top">
                        <Popover.Body>Content to Add</Popover.Body>
                      </Popover>
                    }
                  >
                    <InputGroup.Text
                      id="btnGroupAddon"
                      className="text-secondary bg-white cursor-pointer fs-5 border-right-0"
                    >
                      <MdAttachFile />
                    </InputGroup.Text>
                  </OverlayTrigger>
                  <FormControl
                    as="textarea"
                    rows={1}
                    placeholder="Type your message"
                    aria-label="Type your message"
                    className="border-right-0 border-left-0"
                    style={{
                      resize: "none",
                      overflow: "hidden",
                      height: "auto",
                      minHeight: "24px", // Approx. height for 1 row
                      maxHeight: "72px", // Approx. height for 3 rows
                      boxSizing: "border-box",
                    }}
                    ref={textareaRef}
                    onInput={handleInput}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />

                  <InputGroup.Text
                    id="btnGroupAddon"
                    className="text-secondary bg-white cursor-pointer fs-5 border-left-0 border-right-0"
                  >
                    <FaIndianRupeeSign />
                  </InputGroup.Text>
                  <InputGroup.Text
                    id="btnGroupAddon"
                    className="text-info bg-white cursor-pointer fs-5 border-left-0 border-right-0"
                  >
                    <FaFileAlt />
                  </InputGroup.Text>
                  <InputGroup.Text
                    id="btnGroupAddon"
                    className="text-success bg-white cursor-pointer border-left-0"
                    onClick={handleSendMessage}
                  >
                    <IoSend className="fs-5" />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={4} className="pe-0">
                <Col className="mb-3">
                  <Button variant="info">Seize Chat</Button>
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="h5">Query Details</Card.Header>
                    <Card.Body
                      style={{ maxHeight: "430px", overflowY: "auto" }}
                    >
                      <Card.Text>
                        <TruncatedTextArea text={summary} maxChars={100} />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default NonDisputeArbitrationChatBox;

const AecciMessage = ({ text }) => {
  return (
    <Card className="mb-2 border-none">
      <Card.Body
        style={{ backgroundColor: "#E8DAF5" }}
        className="text-info py-1 rounded-1"
      >
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};
