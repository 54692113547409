import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import EPlatformHeader from "../../components/basic/EPlatformHeader";
import Logo from "../../assets/chief.png";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const MasterLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(getURLbyEndPoint("loginMaster"), {
        email,
        password,
      });
      if (res && res.data) {
        if (res && res.data && res.data.status) {
          const { token, userId, result } = res.data.data;
          const userDetails = JSON.parse(result);

          // Convert userDetails object to string
          const userDetailsString = JSON.stringify(userDetails);

          // Set cookies instead of sessionStorage
          Cookies.set("authToken", token);
          Cookies.set("userId", userId);
          Cookies.set("userDetails", encodeURIComponent(userDetailsString));

          toast.success(res.data.message);
          setTimeout(() => {
            if (userDetails.role === "master") {
              navigate(location.state || "/master-dashboard");
            } else if (userDetails.role === "employee") {
              navigate(location.state || "/employee-dashboard");
            } else {
              alert("Unauthorised access attempt.");
            }
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("Email or password are Incorrect");
    }
  };

  return (
    <>
      <Layout>
        <EPlatformHeader />
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-between align-items-stretch my-4 main-width">
            <div
              className="shadow d-flex justify-content-between rounded-2"
              style={{ width: "70%" }}
              onSubmit={handleLogin}
            >
              <form
                className="ps-4 py-4 d-flex justify-content-between align-content-center flex-column"
                style={{ width: "50%" }}
              >
                <div>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      required
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="rounded-1 bg-color-2 py-2"
                    style={{ width: "30%" }}
                  >
                    Log In
                  </button>
                </div>
              </form>
              <div
                className=" my-4 d-flex justify-content-center align-items-center border-start"
                style={{ width: "40%" }}
              >
                Advertise
              </div>
            </div>
            <div className=" w-25 shadow text-center rounded-2">
              <img className="py-4 cover-fill" src="..." alt="" srcset="" />
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center align-items-center h-100">
          <div className="d-flex justify-content-between align-items-stretch my-4 main-width h-100">
            <div className="d-flex flex-column w-25 justify-content-center">
              <form onSubmit={handleLogin}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
                <div className="text-end">
                  <Button className="mt-3 w-50" variant="primary" type="submit">
                    LOGIN
                  </Button>
                </div>
              </form>
            </div>
            <div
              className="text-center bg-info d-flex h-100"
              style={{ width: "70%" }}
            >
              <img src={Logo} alt="" />
            </div>
          </div>{" "}
        </div> */}
      </Layout>
    </>
  );
};

export default MasterLogin;
