export function formatDateTimeStandard(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    return `${formattedDate} ${formattedTime}`;
  }
  
  function padZero(num) {
    return num.toString().padStart(2, '0');
  }
  



  export function numberToWords(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
  
    if (num === 0) return "zero";
  
    // Function to convert a three-digit number to words
    function threeDigitToWords(num) {
      let result = "";
      const hundredsDigit = Math.floor(num / 100);
      const remainder = num % 100;
  
      if (hundredsDigit > 0) {
        result += units[hundredsDigit] + " Hundred";
        if (remainder > 0) result += " And ";
      }
  
      if (remainder === 0) return result;
  
      if (remainder <= 10) {
        result += units[remainder];
      } else if (remainder < 20) {
        result += teens[remainder - 10];
      } else {
        const tensDigit = Math.floor(remainder / 10);
        const unitsDigit = remainder % 10;
        result += tens[tensDigit];
        if (unitsDigit > 0) result += "-" + units[unitsDigit];
      }
  
      return result;
    }
  
    // Convert the number to words
    let result = "";
    if (num < 0) {
      result += "minus ";
      num = Math.abs(num);
    }
  
    const billions = Math.floor(num / 1000000000);
    const millions = Math.floor((num % 1000000000) / 1000000);
    const thousands = Math.floor((num % 1000000) / 1000);
    const hundreds = num % 1000;
  
    if (billions > 0) {
      result += threeDigitToWords(billions) + " billion ";
    }
    if (millions > 0) {
      result += threeDigitToWords(millions) + " million ";
    }
    if (thousands > 0) {
      result += threeDigitToWords(thousands) + " thousand ";
    }
    if (hundreds > 0) {
      result += threeDigitToWords(hundreds);
    }
  
    return result.trim();
  }