import React from "react";
import EPlatformHeaderimg from "../../assets/EplatformHeaderimg.avif";

const EPlatformHeader = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center text-light"
        style={{
          backgroundImage: `url(${EPlatformHeaderimg})`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="main-width"
          style={{ marginBottom: "10vh", marginTop: "10vh" }}
        >
          <h1 className="font1">e-Platform-Master</h1>
          <span>
            AECCI has crafted a digital mosaic of services through its
            e-platform. Whether seeking certificate attestation, visa
            recommendation letters, or expert consultation, simply log in and
            discover a seamless realm of services under one roof.
          </span>
        </div>
      </div>
    </>
  );
};

export default EPlatformHeader;
