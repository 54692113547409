import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/MasterLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import {
  Form,
  Button,
  Nav,
  Table,
  NavLink,
  NavItem,
  Pagination,
} from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";

const ClientVerification = () => {
  const [tabVal, setTabVal] = useState("Certificate of Origin (NP)"); // Initial value

  const handleSelect = (eventKey) => {
    setTabVal(eventKey);
  };

  const [clientVerDetails, setClientVerDetails] = useState([]);
  const fetchClientVerDetails = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getClientVerDetails"));
      if (response.status === 200) {
        const data = response.data.data;
        setClientVerDetails(data);
      } else {
        alert("Failed to fetch users data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchClientVerDetails();
  }, []);

  // Filtered coResource based on selected tab
  const filteredData = clientVerDetails.filter(
    (data) => data.verMode === tabVal
  );

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentfilteredData = filteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 10);

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 10) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  return (
    <Layout>
      <div className="d-flex justify-content-center align-items-start">
        <div className="py-1 align-items-center justify-content-center main-width">
          <hr />
          <DashboardHeader
            heading="Services/ Client Verification"
            backUrl="/master-services"
          />
          <hr />

          <div className="mb-4 ">
            <Nav
              variant="pills"
              defaultActiveKey="Certificate of Origin (NP)"
              onSelect={handleSelect}
              className="primary-color fw-semibold d-flex justify-content-between gap-3 verification-tabs"
              style={{ color: "#0b3b5d" }}
            >
              <NavItem>
                <NavLink eventKey="Certificate of Origin (NP)">
                  Certificate of Origin (NP)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="Certificate of Membership">
                  Certificate of Membership
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="Certificate of Affiliation">
                  Certificate of Affiliation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="Visa Recommendation Letter">
                  Visa Recommendation Letter
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Email</th>

                {tabVal === "Certificate of Origin (NP)" && (
                  <>
                    <th>Authority</th>
                    <th>Mode of Submission</th>
                  </>
                )}
                {tabVal === "Certificate of Membership" ? (
                  <th>Membership No.</th>
                ) : (
                  <th>Reference No.</th>
                )}

                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                <>
                  {currentfilteredData.map((data, index) => (
                    <tr key={index}>
                      <td> {filteredData.length - indexOfFirstItem - index}.</td>
                      <td>{data.email}</td>

                      {tabVal === "Certificate of Origin (NP)" && (
                        <>
                          <td>{data.authority}</td>
                          <td>{data.modeOfSub}</td>
                        </>
                      )}
                      {(tabVal === "Visa Recommendation Letter" ||
                        tabVal === "Certificate of Origin (NP)") && (
                        <td>{data.refNo}</td>
                      )}
                      {tabVal === "Certificate of Membership" && (
                        <td>{data.membNo}</td>
                      )}
                      <td>
                        {new Date(data.updatedAt).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}

          {/* {tabVal === "certificate-of-affiliation" && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Company Details</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
              </tbody>
            </Table>
          )}

          {tabVal === "visa-recommendation-letter" && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Company Details</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
              </tbody>
            </Table>
          )} */}
        </div>
      </div>
    </Layout>
  );
};

export default ClientVerification;
