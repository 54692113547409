import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  Table,
  Modal,
  Form,
  Spinner,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getURLbyEndPointV2 } from "../../../store/api";
import { FaCheckSquare, FaCreditCard, FaEdit, FaImage, FaPaperclip } from "react-icons/fa";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { PopUpModalButton } from "../../../components/basic/fileViewModal";
import { PopUpModalButtonReceipt } from "../publicationPages/adBooking";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { GrDocumentPdf } from "react-icons/gr";
import { IoMdRemoveCircleOutline, IoMdSend } from "react-icons/io";
import { RiMessage2Line } from "react-icons/ri";
import { CiStar } from "react-icons/ci";
import axios from "axios";
import NonDisputeArbitrationChatBox from "../../../components/chatComponents/NonDisputeArbitrationChatBox";

const RaiseYourDispute = () => {
  const [raiseDisputeData, setRaiseDisputeData] = useState([]);
  // const [reqStatus, setReqStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");
  const [fileSend, setFileSend] = useState("")
  const [chatShow, setChatShow] = useState(false);


  const updatedValue = async (id, newValue) => {
    try {
      const response = await axios.put(
        getURLbyEndPointV2("updateAdminFee") + id,
        {
          administrationFee: newValue,
        }
      );
      if (response.status === 200) {
        fetchRaiseYourDisputeData();
      } else {
        console.error("Failed to update Administration fee.");
      }
    } catch (error) {
      console.error("Error updating Admin fee value:", error);
    }
  };

  const handleEditClick = (id) => {
    setRaiseDisputeData((prevData) =>
      prevData.map((item) =>
        item._id === id ? { ...item, isEditing: !item.isEditing } : item
      )
    );
  };

  const handleUpdateClick = (id, newValue) => {
    updatedValue(id, newValue);
    handleEditClick(id);
  };

  const handleInputChange = (itemId, name, value) => {
    setRaiseDisputeData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleFileChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      setRaiseDisputeData((prevData) =>
        prevData.map((item) =>
          item._id === id
            ? {
              ...item,
              files: [
                ...item.files || [],
                {
                  file,
                  caption: "",
                  isUpload: true,
                  apiFlag:false,
                },
              ],
            }
            : item
        )
      );
    }
  };

  const handleInputFileName = (e, dataId, fileIndex) => {
    const { value } = e.target;
    setRaiseDisputeData((prevData) =>
      prevData.map((item) =>
        item._id === dataId
          ? {
            ...item,
            files: item.files.map((file, index) =>
              index === fileIndex ? { ...file, caption: value } : file
            ),
          }
          : item
      )
    );
  };

  const handleRemoveFile = (dataId, fileIndex) => {
    setRaiseDisputeData((prevData) =>
      prevData.map((item) =>
        item._id === dataId
          ? {
            ...item,
            files: item.files.filter((_, index) => index !== fileIndex),
          }
          : item
      )
    );
  };

  const handleViewPdf = (dataId, fileIndex) => {
    const fileData = raiseDisputeData.find((item) => item._id === dataId);
    if (fileData) {
      const fileObj = fileData.files[fileIndex];
      if (fileObj) {
        const fileURL = URL.createObjectURL(fileObj.file);
        window.open(fileURL);
      }
    }
  };

  const handleSendFile = async (dataId, fileIndex) => {
    const fileData = raiseDisputeData.find((item) => item._id === dataId);
    if (fileData && fileData.files[fileIndex]) {
      const formData = new FormData();
      const fileObj = fileData.files[fileIndex];

      formData.append("file", fileObj.file);
      formData.append("caption", fileObj.caption);

      setRaiseDisputeData((prevData) =>
        prevData.map((item) =>
          item._id === dataId
            ? {
                ...item,
                files: item.files.map((file, index) =>
                  index === fileIndex
                    ? { ...file, apiFlag: true }
                    : file
                ),
              }
            : item
        )
      );

      try {
        const response = await axios.post(
          getURLbyEndPointV2("uploadArbitrationFiles") + dataId,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          alert("File uploaded successfully");
          // Update the state to set isUpload to true
          setRaiseDisputeData((prevData) =>
            prevData.map((item) =>
              item._id === dataId
                ? {
                    ...item,
                    files: item.files.map((file, index) =>
                      index === fileIndex
                        ? { ...file, apiFlag: false }
                        : file
                    ),
                  }
                : item
            )
          );
          window.location.reload()
        } else {
          alert("Failed to upload file.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("something went wrong!!")
      }finally{
        setRaiseDisputeData((prevData) =>
          prevData.map((item) =>
            item._id === dataId
              ? {
                  ...item,
                  files: item.files.map((file, index) =>
                    index === fileIndex
                      ? { ...file, apiFlag: false }
                      : file
                  ),
                }
              : item
          )
        );
      }
    }
  };


  const handlePaymentReq = async (id, value) => {
    try {
      const response = await axios.put(
        getURLbyEndPointV2("sendArbitrationPaymentReq") + id,
        {
          refNo: raiseDisputeData[0].refNo,
          isPaymentReqSubmit: value,
        }
      );
      if (response.status === 200) {
        alert(response.data.message);
        window.location.reload();
      } else {
        console.error("Failed to send payment request.");
      }
    } catch (error) {
      console.error("Error while send a req:", error);
    }
  };

  const fetchRaiseYourDisputeData = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPointV2("getAllRaiseYourDisputesData")
      );
      if (response.status === 200) {
        const data = response.data.data;
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Approved") {
            const uploadedDocs = item.raiseDisputeDocs.map((doc) => {
              return {
                ...doc,
                isUpload: false,
                isFileSend: false,
              };
            });
            return {
              ...item,
              files: uploadedDocs,
            };
          } else if (item.isApproved === "Processing") {
            return {
              ...item,
              appDisApi: false,
              isEditing: false,
            };
          }
          return item;
        });
        setRaiseDisputeData([...modifiedData]);
        console.log(modifiedData);
      } else {
        console.error("Failed to fetch ads data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const approveRejectDocs = async (userId, status) => {
    apiLoading(true, userId);

    const clientData = raiseDisputeData.find((user) => user._id === userId);

    // Validate remark
    if (!clientData.remark || clientData.remark.trim() === "") {
      alert("Remark is required.");
      apiLoading(false, userId);
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectDocs") + userId,
        { status, remark: clientData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        apiLoading(true, userId);
        window.location.reload();
      } else {
        alert(res.data.message);
        apiLoading(false, userId);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
      apiLoading(false, userId);
    } finally {
      apiLoading(false, userId);
    }
  };


  const approveRejectNonDispute = async (userId, status) => {
    apiLoading(true, userId);

    const clientData = raiseDisputeData.find((user) => user._id === userId);

    // Validate remark
    if (!clientData.remark || clientData.remark.trim() === "") {
      alert("Remark is required.");
      apiLoading(false, userId);
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectNonDispute") + userId,
        { status, remark: clientData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        apiLoading(true, userId);
        window.location.reload();
      } else {
        alert(res.data.message);
        apiLoading(false, userId);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
      apiLoading(false, userId);
    } finally {
      apiLoading(false, userId);
    }
  };


  const apiLoading = async (flag, userId) => {
    setRaiseDisputeData((prevData) => {
      const updatedData = prevData.map((user) => {
        if (user._id === userId) {
          return { ...user, appDisApi: flag };
        }
        return user;
      });
      return updatedData;
    });
  };

  const filteredData = raiseDisputeData.filter((data) => {
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Paginatio logic----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Reset to the first page whenever the search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchRaiseYourDisputeData();
  }, []);

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Arbitration Center / Raise Your Dispute"
              backUrl="/master-arbitration"
            />
            <hr />
            <div className="mb-4 d-flex justify-content-between">
              <Nav
                variant="pills"
                defaultActiveKey="NON BINDING DISPUTE"
                // onSelect={handleSelect}
                className="primary-color fw-semibold gap-3 verification-tabs"
                style={{ color: "#0b3b5d" }}
              >
                <NavItem style={{ "min-width": "15%" }} className="text-center">
                  <NavLink eventKey="BiNDING DISPUTE">BINDING DISPUTE</NavLink>
                </NavItem>

                <NavItem style={{ "min-width": "15%" }} className="text-center">
                  <NavLink eventKey="NON BINDING DISPUTE">
                    NON BINDING DISPUTE
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      User Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                     Other info
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Docs
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Application No.
                      <br />& Date Time
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Process
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Action
                    </th>
                    {/* <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Proceeding
                    </th> */}
                  </tr>
                </thead>
                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, dataIndex) => (
                      <tbody className="text-center" key={dataIndex}>
                        <tr>
                          <td>
                            {filteredData.length - indexOfFirstItem - dataIndex}
                            .
                          </td>
                          <td>
                            <div>
                              <div className="fw-bold">{data.email}</div>
                              <div className="text-nowrap">{data.companyName}</div>
                              <div>{data.membershipNo}</div>
                            </div>
                          </td>
                          <td>
                              <div className="text-nowrap">Name of parties: {data.consignorName} V/S {data.consigneeName}</div>
                            <div>Year of dispute: {data.disputeYear}</div>
                            <div>Amount in Dispute:{data.amountInDisputeCurrency}{" "}
                            {data.amountInDispute}/-</div>
                            <div>Shipment Form: {data.shipmentFrom} To {data.shipmentTo}</div>
                            <div>Product: {data.productName}</div>
                          </td>
                          <td>
                            <div className="text-center d-flex flex-column gap-2 justify-content-center align-items-center">
                              <PopUpModalButton
                                variant={data.isApproved === "docApproved" ? "success" : "primary"}
                                title="Application form"
                                fileLink={data.applicationForm}
                                disabled={false}
                              />
                              <PopUpModalButton
                                title="Request Letter"
                                variant={data.isApproved === "docApproved" ? "success" : "primary"}
                                fileLink={data.requestLetter}
                                disabled={false}
                              />
                              <PopUpModalButton
                                title="Other Document"
                                variant={data.isApproved === "docApproved" ? "success" : "primary"}
                                fileLink={data.otherDoc}
                                disabled={false}
                              />
                            </div>
                            <div className="mt-3">
                              {data.isApproved !== "docApproved" ?(
                                <>
                                <Form.Group>
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      required
                                      rows={2}
                                      maxLength={50}
                                      onChange={(e) =>
                                        handleInputChange(
                                          data._id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      value={data.remark}
                                      name="remark"
                                    />
                                  </Form.Group>
                                  <div className="d-flex gap-3 justify-content-center">
                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                              onClick={() =>
                                                approveRejectDocs(
                                                  data._id,
                                                  true
                                                )
                                              }
                                            >
                                              <AiFillCheckCircle className="w-100 icon-size green-color" />
                                            </Button>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                              onClick={() =>
                                                approveRejectDocs(
                                                  data._id,
                                                  false
                                                )
                                              }
                                            >
                                              <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                            </Button>
                                            </div>
                                            </>
                              ) : (
                                <>{" "}</>
                              )}
                            
                            </div>
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {data.isApproved === "docApproved" ? (
                                <>
                                 {data.appNo}{" "}
                              <h6 className="text-success">
                                {formatDateTimeStandard(data.createdAt)}
                              </h6>
                                </>
                              ) : (
                                <div> -- </div>
                              )}
                               <Read data={data} />
                            </div>
                          </td>
                         
                          <td style={{ width: "max-content" }}>
                            <div
                              style={{
                                width: "350px",
                                maxWidth: "max-content",
                              }}
                            >
                              <div className="text-nowrap">
                                {!data.isEditing ? (
                                  <>
                                    <div className="d-flex justify-content-center gap-2 align-items-center">
                                      <div>
                                        Fee: Rs {data.administrationFee}/- per
                                        consignee.
                                      </div>
                                      {!data.receipt && (
                                        <FaEdit
                                          variant="link"
                                          onClick={() =>
                                            handleEditClick(data._id)
                                          }
                                        />
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Form.Control
                                      type="string"
                                      value={data.administrationFee}
                                      onInput={(e) => {
                                        const value = e.target.value;
                                        if (value === "0") {
                                          e.target.value = "";
                                        } else if (!isNaN(value)) {
                                          e.target.value = Math.max(
                                            0,
                                            parseInt(value) || 0
                                          ).toString();
                                        } else {
                                          e.target.value = "";
                                        }
                                      }}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setRaiseDisputeData((prevData) =>
                                          prevData.map((item) =>
                                            item._id === data._id
                                              ? {
                                                ...item,
                                                administrationFee: value,
                                              }
                                              : item
                                          )
                                        );
                                      }}
                                      style={{
                                        width: "200px",
                                        display: "inline-block",
                                      }}
                                    />

                                    <FaCheckSquare
                                      variant="link"
                                      className="text-success fs-4"
                                      onClick={() =>
                                        handleUpdateClick(
                                          data._id,
                                          data.administrationFee
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </div>

                              <div className="my-2 d-flex flex-column justify-content-center align-items-center gap-2">
                                {!data.isPaymentReqSubmit ? (
                                  <>
                                    {/* <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter Ref No"
                                      rows={2}
                                      style={{ width: "150px" }}
                                      maxLength={50}
                                      name="refNo"
                                      onChange={(e) => {
                                        handleInputChange(
                                          data._id,
                                          "refNo",
                                          e.target.value
                                        );
                                      }}
                                    /> */}
                                    <Button
                                      size="sm"
                                      variant={
                                        data.isPaymentReqSubmit
                                          ? "secondary"
                                          : "success"
                                      }
                                      // disabled={data.isPaymentReqSubmit}
                                      onClick={() => {
                                        handlePaymentReq(data._id, true);
                                      }}
                                    >
                                      {!data.isPaymentReqSubmit
                                        ? "Payment Request"
                                        : "Request send"}
                                    </Button>
                                  </>
                                ) : (
                                  // <data>Ref No: {data.refNo}</data>
                                  <data>Ref No: LW-NBD/001/24-25</data>
                                )}
                              </div>
                              <PopUpModalButtonReceipt
                                title="Receipt"
                                fileLink={data.receipt}
                                disabled={data.receipt ? false : true}
                              />
                            </div>
                          </td>
                          <td>
                            {data.isApproved}
                            <>
                            <div className="d-flex justify-content-center align-items-center flex-column">

                             {/* <Button variant="primary" size="sm" className="px-4 mt-2" onClick={() => setChatShow(true)}>
        Chats
      </Button> */}

<NonDisputeArbitrationChatBox
                            disabled={false}
                            refNo={data.refNo}
                            ArbitrationId={data._id}
                            summary={data.appNo}
                          />

      <div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div class="star-rating d-flex fs-3">
        <span data-rating="1"><CiStar /></span>
        <span data-rating="2"><CiStar /></span>
        <span data-rating="3"><CiStar /></span>
        <span data-rating="4"><CiStar /></span>
        <span data-rating="5"><CiStar /></span>
        <input type="hidden" name="whatever1" class="rating-value" value="2.56"/>
      </div>
    </div>
  </div>
</div>


</div>
      <Chatbox
        show={chatShow}
        onHide={() => setChatShow(false)}
        />
      </>
                            {data.receipt &&
                              data.isPaymentReqSubmit &&
                              data.isApproved === "Processing" ? (
                              <>
                                <div className="max-content">
                                  <Form.Group>
                                    <Form.Label className="mb-0">
                                      Remark
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      required
                                      rows={3}
                                      maxLength={50}
                                      onChange={(e) =>
                                        handleInputChange(
                                          data._id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      value={data.remark}
                                      name="remark"
                                    />
                                  </Form.Group>
                                  <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                    {data.remark && (
                                      <>
                                        {data.appDisApi ? (
                                          <Spinner animation="border text-success" />
                                        ) : (
                                          <>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                              onClick={() =>
                                                approveRejectNonDispute(
                                                  data._id,
                                                  true
                                                )
                                              }
                                            >
                                              <AiFillCheckCircle className="w-100 icon-size green-color" />
                                            </Button>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                              onClick={() =>
                                                approveRejectNonDispute(
                                                  data._id,
                                                  false
                                                )
                                              }
                                            >
                                              <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}{" "}
                            <div></div>
                          </td>
                          {/* <td>
                            <div>
                              {data.isApproved === "Approved" ? (
                                <div>
                                  <div className="d-flex justify-content-center gap-2">
                                    {data.files && data.files.length < 4 && (
                                      <label className="btn btn-sm btn-success px-3 py-2">
                                        UPLOAD
                                        <input
                                          type="file"
                                          accept=".pdf"
                                          style={{ display: "none" }}
                                          onChange={(e) =>
                                            handleFileChange(data._id, e)
                                          }
                                          disabled={
                                            data.files && data.files.length >= 4
                                          }
                                        />
                                      </label>
                                    )}
                                  </div>

                                  <ul className="list-group mt-2">
                                    {data.files && data.files.length > 0 ? (
                                      data.files.map((fileObj, fileIndex) => (
                                        <li
                                          key={fileIndex}
                                          className="list-group-item d-flex gap-2 justify-content-between align-items-center"
                                        >
                                          {!fileObj.isUpload ? (
                                            <div className="">
                                              <div style={{ width: "230px" }}>
                                                {fileObj.caption}
                                              </div>
                                            </div>
                                          ) : (
                                            <Form.Control
                                              type="text"
                                              placeholder=""
                                              // style={{ width: "150px" }}
                                              maxLength={80}
                                              onChange={(e) =>
                                                handleInputFileName(
                                                  e,
                                                  data._id,
                                                  fileIndex
                                                )
                                              }
                                              value={fileObj.caption}
                                            />
                                          )}

                                          <div className="d-flex gap-2">

                                            {!fileObj.fileUrl ? (
                                              <>
                                                <button
                                                  className="btn btn-primary btn-sm"
                                                  onClick={() =>
                                                    handleViewPdf(
                                                      data._id,
                                                      fileIndex
                                                    )
                                                  }
                                                >
                                                  <GrDocumentPdf />
                                                </button>
                                                <button
                                                  className="btn btn-danger btn-sm"
                                                  onClick={() =>
                                                    handleRemoveFile(
                                                      data._id,
                                                      fileIndex
                                                    )
                                                  }
                                                >
                                                  <IoMdRemoveCircleOutline />
                                                </button>
                                              </>
                                            ) : (
                                              <a
                                                className="btn btn-primary btn-sm"
                                                href={fileObj.fileUrl}
                                                target="_blank"
                                              >
                                                <GrDocumentPdf />
                                              </a>
                                            )}

                                            {fileObj.message &&
                                              <OverlayTrigger
                                                trigger="click"
                                                placement="bottom"
                                                rootClose
                                                overlay={
                                                  <Popover id="popover-basic">
                                                    <Popover.Header as="h3">
                                                      Approved By user
                                                    </Popover.Header>
                                                    <Popover.Body>
                                                      {fileObj.message}
                                                    </Popover.Body>
                                                  </Popover>
                                                }
                                              >
                                                <button className="btn btn-info btn-sm">
                                                  <RiMessage2Line />
                                                </button>
                                              </OverlayTrigger>
                                            }


                                            {fileObj.apiFlag ? (
                                              <Spinner animation="border" variant="success" />
                                            ) : (
                                              <>
                                                <button
                                                  className="btn btn-success btn-sm"
                                                  onClick={() => handleSendFile(data._id, fileIndex)}
                                                  disabled={data.files[fileIndex]?.fileUrl ? true : false}
                                                >
                                                  {!data.files[fileIndex]?.fileUrl ? "send" : "sent"}
                                                </button>
                                              </>
                                            )}


                                          </div>
                                        </li>
                                      ))
                                    ) : (
                                      <li className="list-group-item text-nowrap">
                                        No files uploaded yet.
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                <div> ---- </div>
                              )}
                            </div>
                          </td> */}


                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={13} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RaiseYourDispute;

function Read({ data }) {
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .replace(" ", "");
  };

  return (
    <>
      <Button
        variant="info"
        className="small-font my-1 fw-semibold"
        onClick={handleShow}
      >
        Read
      </Button>

      <Modal
        show={modalShow}
        size="lg"
        onHide={() => setModalShow(false)}
        centered
      >
        {" "}
        <Modal.Body>
          <div className="border px-4 py-2">
            <div className="text-center">
              <h2 className="fw-semibold">Case Summary</h2>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-4">
              <div className="fs-6">
                <h6 className="fw-semibold">Application No: {data.appNo}</h6>
                <h6 className="fw-semibold">Status: {data.isApproved}</h6>
              </div>
              <div className="fs-6">
                <h6 className="fw-semibold">Date: {data.date}</h6>
                <h6 className="fw-semibold">
                  Time: {formatTime(data.createdAt)}
                </h6>
              </div>
            </div>
            <hr />
            <div className="mt-3">
              <p style={{ lineHeight: "35px" }}>
                The Case involves a dispute between{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.consignorName}
                </span>{" "}
                and{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.consigneeName}
                </span>{" "}
                it started in the year
                <span className="px-2 fw-semibold text-uppercase">
                  {data.disputeYear}
                </span>{" "}
                over a Product Called{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.productName}
                </span>{" "}
                in the{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.industry}
                </span>
                . The Said Product was shipped form{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.shipmentFrom}
                </span>{" "}
                to{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.shipmentTo}
                </span>{" "}
                and the amount in dispute is{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.amountInDisputeCurrency} {data.amountInDispute}/-
                </span>
                .{" "}
              </p>
              <p style={{ lineHeight: "35px" }}>
                The Amount as in Commercial invoice is{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.sumOfCommercialInvCurrency} {data.sumOfCommercialInv}/-
                </span>{" "}
                and the details of the dispute are as follows:
              </p>
              <h5>Description:</h5>
              <p style={{ lineHeight: "35px" }}>{data.description}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


function Chatbox(props) {
  return (

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // show={chatShow}
    // onHide={handleClose}
    style={{ display: "flex", alignItems: "center" }}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Modal.Title className="h5">
            APP NO: AECCI/LW-S1/003
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="d-flex flex-column h-100 w-100">
            {/* <div>
              <AecciMessage text="Your Ticket has been processed and our expert will connect you shortly." />
            </div> */}
            <div className="d-flex flex-column mt-auto">
              <Message
                align="Received"
                text="Hello, How i can help you"
                time="18/06/2023 4.00pm"
              />
              <Message
                align="sent"
                text="Hello,"
                time="18/06/2023 4.00pm"
              />
              <Message
                align="Received"
                text="Please share me your xyz documents"
                time="18/06/2023 4.00pm"
              />
              <hr />

              <InputGroup>
      <FormControl
        placeholder="Type your message"
        aria-label="Type your message"
      />
      <Button variant="outline-secondary">
        <FaPaperclip />
      </Button>
      <Button variant="outline-secondary">
        <FaImage />
      </Button>
      <Button variant="outline-secondary">
        <FaCreditCard />
      </Button>
      <InputGroup.Text variant="outline-secondary" id="btnGroupAddon">
      <IoMdSend />
      </InputGroup.Text>
    </InputGroup>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close Chat</Button>
      </Modal.Footer>
    </Modal>
  );
}






const Message = ({ align, text, time }) => {
  // Determine the message alignment based on the 'align' prop
  const messageStyle = {
    backgroundColor: align === "sent" ? "#ececec" : "#63b190",
    color: align === "sent" ? "black" : "white",

    borderRadius:
      align === "sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "75%",
    alignSelf: align === "sent" ? "flex-end" : "flex-start", // Align self based on prop
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "sent" ? "black" : "white",
    textAlign: "right", // Adjust textAlign based on alignment
    marginTop: "auto", // Pushes the time to the bottom
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <span>{text}</span>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};