import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";
import { useNavigate } from "react-router-dom";

const MasterPublication = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="PUBLICATIONS & AD's" backUrl="/master-dashboard" />
          <hr />
          <div className="d-flex justify-content-around mb-2">
            

<button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/publication");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("Publications")
              }
            >
              PUBLICATIONS
            </button>
            
          <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/ad-booking");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("AdBooking")
              }
            >
              AD BOOKING
            </button>
          </div>
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterPublication;
