import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterLayout from "../../../components/Layouts/Layouts/MasterLayout";
import { HiBackward } from "react-icons/hi2";
import employees from "../../../assets/masterImgs/employees.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Button, Form } from "react-bootstrap";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { getURLbyEndPoint } from "../../../store/api";

const CreateEmpId = () => {
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState("");

  const [formData, setFormData] = useState({
    employeeName: "",
    employeeId: "",
    designation: "",
    email: "",
    reportingTo: "",
    shiftStart: "",
    shiftEnd: "",
    password: "",
    confirmPassword: "",
    profilePic: null,
    signature: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProfilePicChange = (e) => {
    const picFile = e.target.files[0];
    if (picFile && picFile.size <= 3 * 1024 * 1024) {
      // Check if the file size is 3MB or less
      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePic: picFile,
      }));
    } else {
      alert("Profile picture size must be 3MB or less.");
    }
  };

  const handleSignatureChange = (e) => {
    const signFile = e.target.files[0];
    if (signFile && signFile.size <= 3 * 1024 * 1024) {
      // Check if the file size is 3MB or less
      setFormData((prevFormData) => ({
        ...prevFormData,
        signature: signFile,
      }));
    } else {
      alert("Signature size must be 3MB or less.");
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Month is zero-based
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const handleCreateEmployee = async (e) => {
    e.preventDefault();

    console.log(formData);
    try {
      // Validation rules for each field
      const validationRulesCreateEmployee = {
        employeeName: {
          message: "Please enter employee name.",
          isValid: () => formData.employeeName.trim() !== "",
        },
        employeeId: {
          message: "Please enter employee ID.",
          isValid: () => formData.employeeId.trim() !== "",
        },
        designation: {
          message: "Please enter designation.",
          isValid: () => formData.designation.trim() !== "",
        },
        email: {
          message: "Please enter email.",
          isValid: () => formData.email.trim() !== "",
        },

        reportingTo: {
          message: "Please enter reporting.",
          isValid: () => formData.reportingTo.trim() !== "",
        },
        shiftStart: {
          message: "Please enter shift start.",
          isValid: () => formData.shiftStart !== "",
        },
        shiftEnd: {
          message: "Please enter shift end.",
          isValid: () => formData.shiftEnd !== "",
        },
        password: {
          message: "Please enter password.",
          isValid: () => formData.password.trim() !== "",
        },
        confirmPassword: {
          message: "Passwords do not match.",
          isValid: () => formData.confirmPassword === formData.password,
        },
        profilePic: {
          message: "Please upload profile picture (below 3 MB).",
          isValid: () =>
            formData.profilePic !== null &&
            formData.profilePic.size <= 3 * 1024 * 1024, // Check if file is not null and size is below 3 MB
        },
        signature: {
          message: "Please upload signature (below 3 MB).",
          isValid: () =>
            formData.signature !== null &&
            formData.signature.size <= 3 * 1024 * 1024, // Check if file is not null and size is below 3 MB
        },
      };

      // Loop through each field and validate
      for (const field in validationRulesCreateEmployee) {
        const { message, isValid } = validationRulesCreateEmployee[field];
        if (!isValid()) {
          alert(message);
          return;
        }
      }

      // If all fields are valid, create FormData object and make API call
      const apiFormData = new FormData();
      apiFormData.append("employeeName", formData.employeeName);
      apiFormData.append("employeeId", formData.employeeId);
      apiFormData.append("designation", formData.designation);
      apiFormData.append("email", formData.email);
      apiFormData.append("reportingTo", formData.reportingTo);
      apiFormData.append("shiftStart", formData.shiftStart);
      apiFormData.append("shiftEnd", formData.shiftEnd);
      apiFormData.append("password", formData.password);
      apiFormData.append("confirmPassword", formData.confirmPassword);
      apiFormData.append("profilePic", formData.profilePic);
      apiFormData.append("signature", formData.signature);

      const res = await axios.post(getURLbyEndPoint("createEmp"), apiFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.status) {
        setFormData({
          employeeName: "",
          employeeId: "",
          designation: "",
          email: "",
          reportingTo: "",
          shiftStart: "",
          shiftEnd: "",
          password: "",
          confirmPassword: "",
          profilePic: null,
          signature: null,
        });
      }

      alert(res.data.message);
    } catch (error) {
      alert("Something went wrong!!");
      console.log(error);
    }
  };

  const handleEmpStatus = async (status, userId) => {
    try {
      if (!userId) {
        alert("User Id is not present");
        return;
      }

      const res = await axios.put(
        getURLbyEndPoint("ChangeEmpStatus") + userId,
        {
          status,
        }
      );

      if (res.status) {
        fetchEmpList();
      }
    } catch (error) {
      alert("Something went wrong!!");
      console.log(error);
    }
  };

  const [empList, setEmpList] = useState([]);
  const fetchEmpList = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getEmpList"));
      if (response.status === 200) {
        const data = response.data;

        setEmpList([...data.data]);
      } else {
        console.error("Failed to employee lsit data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmpList();
  }, []);

  return (
    <>
      <Layout>
        <MasterLayout>
          <Form
            className="d-flex flex-column mx-3 mb-5"
            onSubmit={handleCreateEmployee}
          >
            <DashboardHeader
              heading="CREATE EMPLOYEE ID"
              backUrl="/master-administration"
            />
            <hr />
            <div className="d-flex gap-5 mb-3">
              <div className="w-50">
                <span>Employee Name *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.employeeName}
                  name="employeeName"
                  maxLength={20}
                  required
                />
              </div>
              <div className="w-50">
                <span>Employee ID *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.employeeId}
                  name="employeeId"
                  maxLength={20}
                  required
                />
              </div>
            </div>
            <div className="d-flex gap-5 mb-3">
              <div className="w-50">
                <span>Designation *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.designation}
                  name="designation"
                  maxLength={50}
                  required
                />
              </div>
              <div className="w-50">
                <span>Email *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="email"
                  onChange={handleInputChange}
                  value={formData.email}
                  name="email"
                  maxLength={40}
                  required
                />
              </div>
            </div>
            <div className="d-flex gap-5 mb-3">
              <div className="w-50">
                <span>Reporting To *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.reportingTo}
                  name="reportingTo"
                  maxLength={50}
                  required
                />
              </div>
              <div className="w-50">
                <span>Shift Time *</span>
                <div className="d-flex gap-3">
                  <Form.Control
                    className="form-control rounded-1"
                    type="time"
                    onChange={handleInputChange}
                    value={formData.shiftStart}
                    name="shiftStart"
                    maxLength={40}
                    placeholder="Enter Start Time"
                    required
                  />
                  <Form.Control
                    className="form-control rounded-1"
                    type="time"
                    onChange={handleInputChange}
                    value={formData.shiftEnd}
                    name="shiftEnd"
                    maxLength={40}
                    placeholder="Enter Closing Time"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="d-flex gap-5 mb-3">
              <div className="w-50">
                <span>Password *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.password}
                  name="password"
                  maxLength={20}
                  required
                />
              </div>
              <div className="w-50">
                <span>Confirm Password *</span>
                <Form.Control
                  className="form-control rounded-1"
                  type="text"
                  onChange={handleInputChange}
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  maxLength={20}
                  required
                />
              </div>
            </div>
            <div className="d-flex gap-5 mb-3">
              <div className="w-50">
                <span>Upload Profie Picture *</span>
                <div className="input-group">
                  <Form.Control
                    type="file"
                    className="form-control"
                    id="profilePicInput"
                    accept="image/*"
                    onChange={handleProfilePicChange}
                    required
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <span>Signature *</span>
                <div className="input-group mb-3">
                  <Form.Control
                    type="file"
                    className="form-control"
                    id="signatureInput"
                    accept="image/*"
                    onChange={handleSignatureChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div>
              <Button type="submit" className="btn btn-primary">
                Create
              </Button>
            </div>
          </Form>

          <div className="mx-3">
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th className="align-middle max-content">Sr No.</th>
                  <th className="align-middle max-content">Name</th>
                  <th className="align-middle max-content">EMPLOYEE ID</th>
                  <th className="align-middle max-content">Designation</th>
                  <th className="align-middle max-content">Email</th>
                  <th className="align-middle max-content">Password</th>
                  <th className="align-middle max-content">Issue Date</th>
                  <th className="align-middle max-content">Uploads</th>
                  <th className="align-middle max-content">Action</th>
                </tr>
              </thead>
              <tbody>
                {empList.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.employeeName}</td>
                    <td>{data.employeeId}</td>
                    <td>{data.designation}</td>
                    <td>{data.email}</td>
                    <td>{data.password}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {new Date(data.updatedAt).toISOString().split("T")[0]}
                    </td>

                    <td>
                      <div className="max-content">
                        <a
                          href={data.profilePic}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Profile Pic
                        </a>
                      </div>
                      <div>
                        <a
                          href={data.signature}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sign
                        </a>
                      </div>
                    </td>

                    <td>
                      {data.isApproved ? (
                        <Button
                          variant="danger"
                          onClick={() => handleEmpStatus(false, data._id)}
                        >
                          Disable
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() => handleEmpStatus(true, data._id)}
                        >
                          Active
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </MasterLayout>
      </Layout>
    </>
  );
};

export default CreateEmpId;
