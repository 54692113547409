import React, { useState, useEffect } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import { HiBackward } from "react-icons/hi2";
import employees from "../../../../assets/masterImgs/employees.jpg";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { getURLbyEndPoint } from "../../../../store/api";
import {
  handleSwitchChange,
  handleUpdateEmpAccess,
} from "../../../../components/tempFunction/accessFunc";
import DashboardHeader from "../../../../components/basic/DashboardHeader";

const EmployeeAccessAdmin = () => {
  const [empList, setEmpList] = useState([]);

  const fetchEmpList = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getActiveEmpList"));
      if (response.status === 200) {
        const data = response.data;

        setEmpList([...data.data]);
      } else {
        console.error("Failed to employee list data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmpList();
  }, []);
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Access List/ Create Employee"
              backUrl="/master-profile-view-emp"
            />{" "}
            <hr />
            <div style={{ overflowX: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Employee Code
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      EMPLOYEE EMAIL
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Employee Name
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Designation
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Create Employee Id
                    </th>
                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      View Employee List{" "}
                    </th>

                    <th
                      scope="col"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        backgroundColor: "rgb(239 237 255)",
                      }}
                    >
                      Update
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {empList.length === 0 ? (
                    <tr>
                      <td colSpan="10" style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    empList.map((data, index) => (
                      <tr key={index}>
                        <th
                          scope="row"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}.
                        </th>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <b>{data.employeeId}</b>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <b>{data.email}</b>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <b>{data.employeeName}</b>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <b>{data.designation}</b>
                        </td>

                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <Form.Check
                            type="switch"
                            id={`custom-switch-${index}`}
                            checked={data.accessList?.includes("CreateEmpId")}
                            onChange={(e) =>
                              handleSwitchChange(
                                empList,
                                setEmpList,
                                e,
                                "CreateEmpId",
                                data._id
                              )
                            }
                          />
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <Form.Check
                            type="switch"
                            id={`custom-switch-${index}`}
                            checked={data.accessList?.includes("ViewEmpList")}
                            onChange={(e) =>
                              handleSwitchChange(
                                empList,
                                setEmpList,
                                e,
                                "ViewEmpList",
                                data._id
                              )
                            }
                          />
                        </td>

                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="rounded-4 border-none"
                            type="button"
                            style={{
                              padding: "6px 20px",
                              backgroundColor: "#0b3b5d",
                              color: "white",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleUpdateEmpAccess(empList, data._id)
                            }
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EmployeeAccessAdmin;
