import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import {
  Form,
  Table,
  Button,
  Spinner,
  Pagination,
  Modal,
  CloseButton,
} from "react-bootstrap";
import { PopUpModalButton } from "../../../components/basic/fileViewModal";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { getAuthUserCookie } from "../../../store/services";

import normalAxis from "../../../assets/axisImg/normalAxis.png";
import rotateAxis from "../../../assets/axisImg/rotatedAxis.png";

const NonStandDoc = () => {
  const [nonStandData, setNonStandData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [reqStatus, setReqStatus] = useState("");

  //simple functions
  const handleInputChange = (e, itemId) => {
    const { name, value } = e.target;
    setNonStandData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };
  const handleApiVal = (userId, value) => {
    setNonStandData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === userId) {
          return {
            ...item,
            appDisApi: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  //approve reject non stand
  const handleApproveRejectNonStand = async (userId, status) => {
    //finding user
    const clientData = nonStandData.find((user) => user._id === userId);
    const userEmail = getAuthUserCookie().email;

    if (!userId || status === null || !clientData.remark) {
      alert("Please fill all fields");
      return;
    }

    if (status) {
      if (clientData.nonStandDocs.some((data) => !data.isStampped)) {
        alert("Please select position for all documents");
        return;
      }
    }

    handleApiVal(userId, true);

    try {
      const res = await axios.post(
        getURLbyEndPoint("approveRejectNonStand") + userId,
        { status, remark: clientData.remark, approvedEmail: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        handleApiVal(userId, false);
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling non stand Data.");
      console.error("Error:", error);
    } finally {
      handleApiVal(userId, false);
    }
  };

  // //filtering data------------------------------------------------------
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  const filteredData = nonStandData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on app NO or forComRegion
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const fetchNonStandDoc = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getNonStandDocMaster")
      );
      if (response.status === 200) {
        const data = response.data.data;
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            const updatedNonStandDocs = item.nonStandDocs.map((doc) => {
              // Create stampPos object for each document
              const stampPos = {};
              doc.stampPages.forEach((page) => {
                // Add stamp positions for each page
                stampPos[page] = { xVal: 65, yVal: 10 };
              });

              // Return document with stampPos
              return {
                ...doc,
                stampPos: stampPos,
                isRotate: false,
              };
            });

            return {
              ...item,
              remark: "",
              appDisApi: false,
              nonStandDocs: updatedNonStandDocs,
            };
          }
          return item;
        });

        setNonStandData([...modifiedData]);
      } else {
        throw new Error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      alert("Failed to fetch data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchNonStandDoc();
  }, []);

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Services/ Non Standard"
            backUrl="/master-services"
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex gap-3">
              <Form.Check
                inline
                label="All"
                name="group1"
                type="radio"
                checked={reqStatus === ""}
                onChange={() => setReqStatus("")}
              />
              <Form.Check
                inline
                label="Processing"
                name="group1"
                type="radio"
                checked={reqStatus === "Processing"}
                onChange={() => setReqStatus("Processing")}
                defaultChecked
              />
              <Form.Check
                inline
                label="Approved"
                name="group1"
                type="radio"
                checked={reqStatus === "Approved"}
                onChange={() => setReqStatus("Approved")}
              />
              <Form.Check
                inline
                label="Rejected"
                name="group1"
                type="radio"
                checked={reqStatus === "Rejected"}
                onChange={() => setReqStatus("Rejected")}
              />
            </div>
            <input
              className="form-control w-50"
              type="search"
              placeholder="Search By Application No."
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div>
            <h6 className="text-danger">
              *In Each Service Approve all Document in sequence(For Reference
              No.)
            </h6>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Sr No.
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    User
                    <br /> Name
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Application No.
                    <br />& Date Time
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Type Of Document & Country
                  </th>

                  <th
                    style={{
                      fontSize: "14px",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Documents List
                  </th>

                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Status
                  </th>
                  <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Action
                  </th>
                </tr>
              </thead>

              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((data, index) => (
                    <tbody className="text-center">
                      <tr key="index">
                        <td>
                          {filteredData.length - indexOfFirstItem - index}.
                        </td>
                        <td>
                          <div>{data.email}</div>
                        </td>
                        <td>
                          <div>
                            {data.appNo}{" "}
                            <h6 className="text-success">
                              {formatDateTimeStandard(data.createdAt)}
                            </h6>
                          </div>
                        </td>

                        <td>
                          <h6>Type Of Document: {data.typeOfDoc}</h6>
                          <h6>Country: {data.country}</h6>
                        </td>

                        <td style={{ width: "max-content" }}>
                          <div
                            className="d-flex justify-content-between flex-wrap"
                            style={{ width: "230px", maxWidth: "max-content" }}
                          >
                            {data.nonStandDocs.map((docsData, index) => (
                              <div
                                className="col-md-6 mb-2 ps-0 text-start"
                                key={index}
                              >
                                <StampDocModal
                                  key={index}
                                  fileId={docsData._id}
                                  title={docsData.docName}
                                  fileLink={docsData.docFile}
                                  approved={data.isApproved === "Processing"}
                                  stampPages={docsData.stampPages}
                                  stampPos={docsData.stampPos}
                                  noOfPages={docsData.noOfPages}
                                  isStampped={docsData.isStampped}
                                  docsData={docsData}
                                  setNonStandData={setNonStandData}
                                  isRotate={docsData.isRotate}
                                />
                              </div>
                            ))}
                          </div>

                          <div className="d-flex justify-content-start gap-1">
                            {data.reqLetterFlag && (
                              <PopUpModalButton
                                title="Request Letter"
                                fileLink={data.reqLetterFile}
                              />
                            )}
                            <PopUpModalButton
                              title="Receipt"
                              fileLink={data.receipt}
                            />
                          </div>
                        </td>

                        <td>
                          <h6
                            className={
                              data.isApproved === "Rejected"
                                ? "fw-semibold text-danger"
                                : "fw-semibold text-success"
                            }
                          >
                            {data.isApproved}
                          </h6>
                          {data.approvedEmail && (
                            <span style={{ fontSize: "0.7rem" }}>
                              by {data.approvedEmail}
                            </span>
                          )}
                        </td>

                        <td>
                          {data.isApproved === "Approved" ||
                          data.isApproved === "Rejected" ? (
                            <>
                              {data.isApproved === "Approved" && (
                                <h6>
                                  Reference No. :{" "}
                                  <span className="text-secondary">
                                    {data.refNo}
                                  </span>
                                </h6>
                              )}
                              <h6>
                                Remark :{" "}
                                <span className="text-secondary">
                                  {data.remark}
                                </span>
                              </h6>
                            </>
                          ) : (
                            <>
                              <div className="max-content">
                                <Form.Control
                                  as="textarea"
                                  type="text"
                                  placeholder="Enter a Remark"
                                  rows={3}
                                  maxLength={50}
                                  onChange={(e) =>
                                    handleInputChange(e, data._id)
                                  }
                                  value={data.remark}
                                  name="remark"
                                />

                                {data.remark && (
                                  <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                    {data.appDisApi ? (
                                      <Spinner animation="border text-success" />
                                    ) : (
                                      <>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <AiFillCheckCircle
                                            className="w-100 icon-size green-color"
                                            onClick={() =>
                                              handleApproveRejectNonStand(
                                                data._id,
                                                true
                                              )
                                            }
                                          />
                                        </Button>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <AiFillCloseCircle
                                            className="w-100 icon-size text-danger"
                                            onClick={() =>
                                              handleApproveRejectNonStand(
                                                data._id,
                                                false
                                              )
                                            }
                                          />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={12} className="text-center">
                      No Matching Data
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NonStandDoc;

//stamping modals

function StampDocModal({
  fileId,
  title,
  fileLink,
  approved,
  stampPages,
  stampPos,
  noOfPages,
  isStampped,
  docsData,
  setNonStandData,
  isRotate,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [updatedFileLink, setUpdatedFileLink] = useState(fileLink);
  const [checkStampApi, setCheckStampApi] = useState(false);
  const [addStampApi, setAddStampApi] = useState(false);
  const [changeToA4, setChangeToA4] = useState(false);

  useEffect(() => {
    setUpdatedFileLink(updatedFileLink);
  }, [updatedFileLink]);
  // //setting document index as per master enter-------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  const handleStampPosChange = (val, pageNo, field) => {
    setNonStandData((prevData) => {
      const updatedData = prevData.map((item) => {
        // Check if the item has the nonStandDocs property
        if (item.nonStandDocs) {
          // Find the document with fileId
          const updatedNonStandDocs = item.nonStandDocs.map((doc) => {
            if (doc._id === fileId && doc.stampPos && doc.stampPos[pageNo]) {
              // Update the specified field (xVal or yVal) for the specified page number
              return {
                ...doc,
                stampPos: {
                  ...doc.stampPos,
                  [pageNo]: {
                    ...doc.stampPos[pageNo],
                    [field]: val,
                  },
                },
              };
            }
            return doc;
          });
          // Return the item with updated nonStandDocs
          return {
            ...item,
            nonStandDocs: updatedNonStandDocs,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleRotateChange = () => {
    setNonStandData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.nonStandDocs) {
          const updatedNonStandDocs = item.nonStandDocs.map((doc) => {
            if (doc._id === fileId && doc.stampPos) {
              return {
                ...doc,
                isRotate: !doc.isRotate, // Toggle the isRotate property
              };
            }
            return doc;
          });
          return {
            ...item,
            nonStandDocs: updatedNonStandDocs,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  // for check and stamp file
  const handleCheckStampPos = async (userId) => {
    setCheckStampApi(true);
    try {
      const response = await axios.put(
        getURLbyEndPoint("checkStampNonStand") + userId,
        {
          stampData: docsData.stampPos,
          isRotate: docsData.isRotate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );

      const responseData = response.data;

      // Concatenate all chunks into a single Uint8Array
      const concatenatedChunks = responseData.chunks.reduce((acc, chunk) => {
        acc.push(
          ...atob(chunk)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        return acc;
      }, []);

      // Create a blob from the concatenated chunks
      const blob = new Blob([new Uint8Array(concatenatedChunks)], {
        type: "application/pdf",
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Set the blob URL as the source for the iframe
      setUpdatedFileLink(url);

      // Show success message
      alert(responseData.message);
      setCheckStampApi(false);
    } catch (error) {
      alert("Error while checking stamp on document.");
      console.error("Error:", error);
    } finally {
      setCheckStampApi(false);
    }
  };

  //for stampping indiviual file
  const handleDocStampAdded = async (userId) => {
    setAddStampApi(true);
    try {
      const res = await axios.put(
        getURLbyEndPoint("addStampNonStand") + userId,
        {
          stampData: docsData.stampPos,
          isRotate: docsData.isRotate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );
      setUpdatedFileLink(res.data.fileLink);

      // Show success message
      alert(res.data.message);
      setAddStampApi(false);

      setNonStandData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.nonStandDocs) {
            const updatedNonStandDocs = item.nonStandDocs.map((doc) => {
              if (doc._id === fileId) {
                return {
                  ...doc,
                  isStampped: true,
                };
              }
              return doc;
            });
            return {
              ...item,
              nonStandDocs: updatedNonStandDocs,
            };
          }
          return item;
        });
        setModalShow(false);
        return updatedData;
      });
    } catch (error) {
      alert("Error while stamping on document.");
      console.error("Error:", error);
    } finally {
      setAddStampApi(false);
    }
  };

  return (
    <>
      <Button
        className="m-0 text-nowrap text-white rounded small-font"
        onClick={() => setModalShow(true)}
        variant={isStampped ? "primary" : "secondary"}
      >
        {title.length > 10 ? title.slice(0, 10) + "..." : title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#0b3b5d", color: "white" }}
          className="text-white"
        >
          <Modal.Title>{title}</Modal.Title>

          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>{" "}
        <Modal.Body>
          <div className="d-flex gap-3">
            <div className={approved && !isStampped ? "w-75" : "w-100"}>
              <iframe
                src={updatedFileLink}
                title={title}
                width="100%"
                height="700px"
                frameBorder="0"
              />
            </div>
            {approved && !isStampped && (
              <div className="w-25">
                <h5>Stampping Process:</h5>
                {stampPages.map((data, index) => (
                  <div key={index}>
                    <h6>For Page {data}:</h6>
                    <div className="d-flex gap-3 align-items-center mb-2">
                      <span>X:</span>
                      <Form.Control
                        type="text"
                        inputmode="numeric"
                        value={stampPos[data] ? stampPos[data].xVal : ""}
                        style={{ minWidth: "50px" }}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value === "0") {
                            e.target.value = "";
                          } else if (!isNaN(value)) {
                            e.target.value = Math.max(0, parseInt(value) || 0)
                              .toString()
                              .slice(0, 3);
                          } else {
                            e.target.value = "";
                          }
                        }}
                        onChange={(e) => {
                          handleStampPosChange(
                            parseInt(e.target.value),
                            data,
                            "xVal"
                          );
                        }}
                      />{" "}
                      <span>Y:</span>
                      <Form.Control
                        type="text"
                        inputmode="numeric"
                        value={stampPos[data] ? stampPos[data].yVal : ""}
                        style={{ minWidth: "50px" }}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value === "0") {
                            e.target.value = "";
                          } else if (!isNaN(value)) {
                            e.target.value = Math.max(0, parseInt(value) || 0)
                              .toString()
                              .slice(0, 3);
                          } else {
                            e.target.value = "";
                          }
                        }}
                        onChange={(e) => {
                          handleStampPosChange(
                            parseInt(e.target.value),
                            data,
                            "yVal"
                          );
                        }}
                      />{" "}
                    </div>
                  </div>
                ))}

                <div className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    id="isRotate"
                    className="me-2"
                    onChange={handleRotateChange}
                    checked={isRotate}
                  />
                  <h6>Rotate</h6>
                </div>

                <div className="d-flex justify-content-between gap-3 my-3">
                  <Button
                    className="w-100"
                    onClick={() => handleCheckStampPos(fileId)}
                    disabled={checkStampApi}
                  >
                    {checkStampApi ? "Processing..." : "CHECK"}
                  </Button>
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={() => handleDocStampAdded(fileId)}
                    disabled={addStampApi}
                  >
                    {addStampApi ? "Processing..." : "STAMP"}
                  </Button>
                </div>
                <div>
                  <img
                    src={isRotate ? rotateAxis : normalAxis}
                    width="auto"
                    height="200px"
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column justify-content-start">
          <h6>
            Stamp Pages:{" "}
            {stampPages.map((page, index) =>
              index === 0 ? page : `, ${page}`
            )}
          </h6>
          <h6>No Of Pages Contain: {noOfPages}</h6>
        </Modal.Footer>
      </Modal>
    </>
  );
}
