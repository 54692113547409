import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterServicesLayout from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Pagination } from "react-bootstrap";

const CompanyDetails = () => {
  const [updateCompanyDetails, setUpdateCompanyDetails] = useState([]);
  const [requestStatus, setRequestStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchUpdateCompanyDetails = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getUpdateCompanyDetails")
      );
      if (response.status === 200) {
        const data = response.data;

        setUpdateCompanyDetails([...data.data]);
        console.log("Update Company Details:", data.data);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUpdateCompanyDetails();
  }, []);

  const handleUpdateReject = async (clientId, bodyId, status) => {
    try {
      const response = await axios.put(
        getURLbyEndPoint("updateRejectCompany"),
        {
          clientId: clientId,
          bodyId: bodyId,
          status: status,
        }
      );
      console.log("Response:", response.data.message);
      alert(response.data.message);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const filteredData = updateCompanyDetails.filter((data) => {
    // Filter based on account status
    if (requestStatus !== "" && data.status !== requestStatus) {
      return false;
    }
    // Filter based on company name
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredData = filteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Profile/ Company Details"
              backUrl="/master-profile"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={requestStatus === ""}
                  onChange={() => setRequestStatus("")}
                />
                <Form.Check
                  inline
                  label="NEW"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={requestStatus === "New"}
                  onChange={() => setRequestStatus("New")}
                />{" "}
                <Form.Check
                  inline
                  label="UPDATED"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={requestStatus === "Updated"}
                  onChange={() => setRequestStatus("Updated")}
                />{" "}
                <Form.Check
                  inline
                  label="REJECTED"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={requestStatus === "Rejected"}
                  onChange={() => setRequestStatus("Rejected")}
                />{" "}
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search Company Name"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {Array.isArray(updateCompanyDetails) &&
            updateCompanyDetails.length > 0 ? (
              <Table responsive striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th className="align-middle">SR NO.</th>
                    <th className="align-middle">User</th>
                    <th className="align-middle">Company Details</th>
                    <th className="align-middle">Company Address</th>
                    <th className="align-middle">Bank Details</th>
                    <th className="align-middle">Status</th>
                    <th className="align-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(updateCompanyDetails) &&
                  updateCompanyDetails.length > 0 ? (
                    (() => {
                      const filteredData = updateCompanyDetails.filter(
                        (data) => {
                          if (requestStatus === "") return true; // Show all data
                          return data.status === requestStatus; // Filter based on status
                        }
                      );

                      if (filteredData.length > 0) {
                        return filteredData.map((data, index) => (
                          <tr key={index}>
                            <th className="text-start">
                              {index + 1}.{" "}
                              <div className="max-content">
                                Created at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.createdAt))}
                                </span>
                              </div>
                            </th>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Company Name :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.companyName}
                                  </span>
                                </h6>
                                <h6>
                                  Membership No. :{" "}
                                  <span className="text-secondary">
                                    {data.memberShipNo}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Company Name:{" "}
                                  <span className="text-secondary">
                                    {data.companyName}
                                  </span>
                                </h6>
                                <h6>
                                  No. of Employee:{" "}
                                  <span className="text-secondary">
                                    {data.numberOfEmployees}
                                  </span>
                                </h6>
                                <h6>
                                  Buissness Catergory:{" "}
                                  <span className="text-secondary">
                                    {data.businessCategory}
                                  </span>
                                </h6>
                                <h6>
                                  Where did you hear about us? :{" "}
                                  <span className="text-secondary">
                                    {data.howDidYouKnowAboutUs}
                                  </span>
                                </h6>
                                <h6>
                                  Telephone No. :{" "}
                                  <span className="text-secondary">
                                    {data.telephoneNo}
                                  </span>
                                </h6>
                                <h6>
                                  Company E-mail:{" "}
                                  <span className="text-secondary">
                                    {data.email}
                                  </span>
                                </h6>
                                <h6>
                                  Website:{" "}
                                  <span className="text-secondary">
                                    {data.websiteAdd}
                                  </span>
                                </h6>
                              </div>
                            </td>

                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Address1 :{" "}
                                  <span className="text-secondary">
                                    {data.address1}
                                  </span>
                                </h6>
                                <h6>
                                  Address2 :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.address2}
                                  </span>
                                </h6>
                                <h6>
                                  City :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.city}
                                  </span>
                                </h6>
                                <h6>
                                  Country :{" "}
                                  <span className="text-secondary">
                                    {data.country}
                                  </span>
                                </h6>
                                <h6>
                                  State :{" "}
                                  <span className="text-secondary">
                                    {data.state}
                                  </span>
                                </h6>
                                <h6>
                                  Pin code :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.pinCode}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Bank Name :{" "}
                                  <span className="text-secondary">
                                    {data.registeredBank}
                                  </span>
                                </h6>
                                <h6>
                                  IFSC Code :{" "}
                                  <span className="text-secondary">
                                    {data.IFSCCode}
                                  </span>
                                </h6>

                                <h6>
                                  Account No. :{" "}
                                  <span className="text-secondary">
                                    {data.registeredAccountNo}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <h6
                                className={
                                  data.status === "Rejected"
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {data.status}
                              </h6>
                            </td>
                            <td>
                              <div className="d-flex w-100 mt-3 justify-content-evenly">
                                {data.status == "New" && (
                                  <>
                                    <AiFillCheckCircle
                                      className="icon-size green-color cursor-pointer"
                                      onClick={() =>
                                        handleUpdateReject(
                                          data.clientId,
                                          data._id,
                                          "Updated"
                                        )
                                      }
                                    />
                                    <AiFillCloseCircle
                                      className="icon-size text-danger cursor-pointer"
                                      onClick={() =>
                                        handleUpdateReject(
                                          data.clientId,
                                          data._id,
                                          "Rejected"
                                        )
                                      }
                                    />
                                  </>
                                )}
                                {data.status == "Updated" && (
                                  <div className="max-content fw-bold align-top">
                                    Updated at:
                                    <br />
                                    <span className="text-success">
                                      {new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                      }).format(new Date(data.updatedAt))}
                                    </span>
                                  </div>
                                )}
                                {data.status == "Rejected" && (
                                  <div className="max-content fw-bold">
                                    Rejected at:
                                    <br />
                                    <span className="text-danger">
                                      {new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                      }).format(new Date(data.updatedAt))}
                                    </span>
                                  </div>
                                )}
                              </div>

                              {/* <div className="max-content">
                          Updated at:
                          <br />
                          <span className="text-success">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(data.updatedAt))}
                          </span>
                        </div> */}
                            </td>
                          </tr>
                        ));
                      } else {
                        return (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No matching data
                            </td>
                          </tr>
                        );
                      }
                    })()
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <p>No data available</p>
            )}
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};


export default CompanyDetails;
