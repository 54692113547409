import React, { useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Button, Form } from "react-bootstrap";
import { getURLbyEndPoint, getURLbyEndPointV2 } from "../../../store/api";
import axios from "axios";

const ReportsDSR = () => {
  const [formData, setFormData] = useState({
    serType: "",
    FromDate: "",
    ToDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExportDSR = async () => {
    // Validation
    if (!formData.serType || !formData.FromDate || !formData.ToDate) {
      alert("Please fill out all the fields before submitting.");
      return;
    }

    if (formData.serType === "SEO" || formData.serType === "NSD") {
      alert("Didn't Implemented Yet.");
      return;
    }
    // Check if ToDate is same as or before FromDate
    const fromDate = new Date(formData.FromDate);
    const toDate = new Date(formData.ToDate);

    if (toDate <= fromDate) {
      alert("To Date must be after From Date.");
      return;
    }

    try {
      const response = await axios.post(
        getURLbyEndPointV2("exportDSR"),
        formData,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "ecoData.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Something went wrong when exporting DSR: ${error}`);
      alert("Please Check Date And Details");
    }
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="D.S.R. (Daily Sales Report)"
            backUrl="/master-report-analysis"
          />
          <hr />
          <div className="d-flex justify-content-start align-items-end gap-3 mb-3">
            <Form.Group className="w-25">
              <Form.Label className="fw-semibold">Service Type</Form.Label>
              <Form.Control
                as="select"
                name="serType"
                value={formData.serType}
                onChange={handleInputChange}
                custom
              >
                <option value="">Select a Type</option>
                <option value="ECO">Electronic-CO</option>
                <option value="SEO">Semi-Electronic-CO</option>
                <option value="NSD">Non-Standard Document</option>
              </Form.Control>
            </Form.Group>{" "}
            <Form.Group controlId="FromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                name="FromDate"
                value={formData.FromDate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="ToDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                name="ToDate"
                min={formData.FromDate}
                disabled={formData.FromDate === ""}
                value={formData.ToDate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className="d-flex align-items-end" onClick={handleExportDSR}>
              <Button>Export</Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportsDSR;
