import React from "react";
import MasterSideBar from "../../Sidebar/MasterSideBar";
import EPlatformHeader from "../../basic/EPlatformHeader";

const Layout2 = ({ children }) => {
  return (
    <>
      <EPlatformHeader />
      <div className="d-flex justify-content-center align-items-start">
        <div className="py-1 align-items-center justify-content-center main-width">
          <hr />
          <div className="d-flex justify-content-between align-items-start py-4">
            <div>
              <MasterSideBar />
            </div>
            <div>
              <main className="Layout2-width" style={{ minHeight: "70vh" }}>
                {children}
              </main>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout2;
