import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Col, Row, Table, Form, Button } from "react-bootstrap";
import { getAuthUserCookie } from "../../../store/services";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";

const HrDailyReport = () => {
  const [jdReportData, setJdReportData] = useState(null);
  const [currentTime, setCurrentTime] = useState(formatTime());
  const [submitJdApi, setSubmitJdApi] = useState(false);
  const [isVisibleSubmit, setIsVisibleSubmit] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("");

  const [formData, setFormData] = useState({
    date: formatDate(),
    shift: "",
    duration: "",
    startTime: formatTime(),
    jobRole: "",
    description: "",
  });

  const validateForm = () => {
    const { shift, duration, jobRole, description } = formData;
    if (!shift || !duration || !jobRole || !description) {
      alert("All fields must be filled out.");
      return false;
    }
    return true;
  };

  const handleStartTask = async () => {
    if (!validateForm()) return;
    try {
      const res = await axios.post(
        getURLbyEndPointV2("createJdReport") + getAuthUserCookie()._id,
        {
          ...formData,
        }
      );
      console.log("Jd report created successfully", res);
      alert("Jd report created successfully");
      window.location.reload();
    } catch (error) {
      console.log("Error in creating Jd report", error);
      alert("Error while creating Jd report");
    }
  };
  const handleEndTask = async (userId) => {
    try {
      const res = await axios.put(getURLbyEndPointV2("endTaskEmp") + userId);
      console.log("Task successfully Completed", res);
      alert("Task successfully Completed");
      window.location.reload();
    } catch (error) {
      console.log("Error in creating Jd report", error);
      alert("Error while creating Jd report");
    }
  };

  const handleSubmitJd = async () => {
    try {
      setSubmitJdApi(true);
      if (
        jdReportData?.jdDataList[jdReportData.jdDataList.length - 1]
          .isCompleted !== "Completed"
      ) {
        alert("Complete all tasks before submitting");
        return;
      }

      const res = await axios.put(
        getURLbyEndPointV2("submitJd") + jdReportData._id
      );
      console.log("JD submitted successfully", res);
      alert("JD submitted successfully");
      setSubmitJdApi(false);
      window.location.reload();
    } catch (error) {
      console.log("Error in Submitted Jd", error);
      alert("Error while Submitting Jd report");
    } finally {
      setSubmitJdApi(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = formatTime();
      setCurrentTime(newTime);
      setFormData((prevFormData) => ({
        ...prevFormData,
        startTime: newTime,
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleFetchJdReport = async () => {
      try {
        const userId = getAuthUserCookie()._id;
        const currentDate = formatDate();

        const res = await axios.get(
          `${getURLbyEndPointV2(
            "fetchEmpJdReport"
          )}${userId}?date=${currentDate}`
        );

        if (res.data.status) {
          setJdReportData(res.data.data);
        }
        console.log(res.data.data);
      } catch (error) {
        console.log("Error while getting jd report", error);
      }
    };

    handleFetchJdReport();
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      const startHour = 18;
      const startMinute = 30;
      const endHour = 20;
      const endMinute = 30;

      const startTime = new Date();
      startTime.setHours(startHour, startMinute, 0, 0);

      const endTime = new Date();
      endTime.setHours(endHour, endMinute, 0, 0);

      if (now >= startTime && now <= endTime) {
        setIsVisibleSubmit(true);
      } else {
        setIsVisibleSubmit(false);
      }
    };

    // Check time when component mounts
    checkTime();

    // Set interval to check time every minute
    const intervalId = setInterval(checkTime, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Hr/ Create Daily Report"
            backUrl="/employee-dashboard"
          />
          <hr />

          <Row>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col as="h5">
                  EMPLOYEE CODE:{" "}
                  <span className="text-secondary">
                    {getAuthUserCookie().employeeId}
                  </span>
                </Col>
                <Col as="h5">
                  EMPLOYEE NAME :{" "}
                  <span className="text-secondary">
                    {getAuthUserCookie().employeeName}{" "}
                  </span>
                </Col>
              </Row>
              <hr />
              <Row className="d-flex align-items-center">
                <Col as="h5">
                  DESIGNATION :{" "}
                  <span className="text-secondary">
                    {getAuthUserCookie().designation}{" "}
                  </span>
                </Col>
                <Col as="h5">
                  REPORTING TO :{" "}
                  <span className="text-secondary">
                    {getAuthUserCookie()?.reportingTo
                      ? getAuthUserCookie().reportingTo
                      : "---"}{" "}
                  </span>
                </Col>
              </Row>
              <hr />
              <h5>
                DATE : <span className="text-secondary">{formatDate()}</span>
              </h5>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <img src={getAuthUserCookie().profilePic} height="200" alt="" />
              </div>
            </Col>
          </Row>
          <hr />

          <Table striped bordered responsive>
            <thead>
              <tr>
                {" "}
                <th>SHIFT</th>
                <th>START TIME</th>
                <th>DURATION</th>
                <th>JOB ROLE</th>
                <th>DESCRIPTION</th>
                <th>END TIME</th>
                <th>END TASK</th>
              </tr>
            </thead>
            <tbody>
              {jdReportData?.jdDataList.map((data, index) => (
                <tr key={data._id}>
                  <td>{data.shift}</td>
                  <td>{data.startTime}</td>
                  <td>{data.duration} Mins</td>
                  <td>{data.jobRole}</td>
                  <td>{data.description}</td>
                  <td>{data.endTime}</td>
                  <td>
                    <div>
                      {data.isCompleted === "Processing" &&
                        (checkTimeStatus(renderTimeLeft(data.endTime)) ===
                        "Not Expired" ? (
                          <>
                            <pre>{renderTimeLeft(data.endTime)} Hours Left</pre>
                            <Button onClick={() => handleEndTask(data._id)}>
                              End Task
                            </Button>
                          </>
                        ) : (
                          <>
                            <pre>Time Expired</pre>
                            <pre>Contact HR to extend your time</pre>
                          </>
                        ))}

                      {data.isCompleted === "Completed" &&
                        `Task Completed At : ${data.endTime}`}
                    </div>
                  </td>
                </tr>
              ))}

              {(!jdReportData ||
                (jdReportData?.jdDataList.length > 0 &&
                  jdReportData?.jdDataList[jdReportData.jdDataList.length - 1]
                    .isCompleted === "Completed")) &&
                (jdReportData
                  ? jdReportData?.isCompleted === "Processing"
                  : true) && (
                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Control
                          as="select"
                          value={formData.shift}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              shift: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Shift</option>
                          <option value="FIRST">First Shift</option>
                          <option value="SECOND">Second Shift</option>
                          <option value="LUNCH">Lunch Break</option>
                          <option value="TEA">Tea Break</option>
                        </Form.Control>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Enter time"
                        value={formData.startTime}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Group>
                        <Form.Control
                          as="select"
                          value={formData.duration}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              duration: e.target.value,
                            })
                          }
                        >
                          <option value="">Select duration</option>
                          <option value="15">15 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="45">45 minutes</option>
                          <option value="60">60 minutes</option>
                        </Form.Control>

                        {(formData.duration === "45" ||
                          formData.duration === "60") && (
                          <Form.Text>
                            Choose an appropriate duration for your task.
                          </Form.Text>
                        )}
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Enter role"
                        maxLength={30}
                        value={formData.jobRole}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            jobRole: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter description"
                        maxLength={300}
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>---</td>
                    <td>
                      <Button onClick={handleStartTask}>Start Task</Button>
                    </td>
                  </tr>
                )}

              {isVisibleSubmit &&
                jdReportData?.isCompleted === "Processing" && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      <Button
                        variant="success"
                        onClick={handleSubmitJd}
                        disabled={submitJdApi}
                      >
                        {submitJdApi ? "Submitting Jd..." : "SUBMIT JD"}
                      </Button>
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default HrDailyReport;

const formatDate = () => {
  const dateObj = new Date();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatTime = () => {
  const dateObj = new Date();
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const calculateTimeLeft = (endTime) => {
  const [endHours, endMinutes] = endTime.split(":").map(Number);
  const now = new Date();
  const end = new Date();
  end.setHours(endHours, endMinutes, 0, 0);

  const difference = end - now;
  const timeLeft = {
    hours: Math.floor(difference / (1000 * 60 * 60)),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
  return timeLeft;
};

const renderTimeLeft = (endTime) => {
  const timeLeft = calculateTimeLeft(endTime);
  return `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`;
};

//CHECK FUNCTION FOR TIME EXPIRED OR NOT
function checkTimeStatus(timeString) {
  // Split the time string by colon
  const timeParts = timeString.split(":");

  // Convert parts to numbers
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const seconds = parseInt(timeParts[2], 10);

  // Check if any part is negative
  if (hours < 0 || minutes < 0 || seconds < 0) {
    return "Time Expired";
  } else {
    return `Not Expired`;
  }
}
