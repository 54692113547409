import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import {
  Table,
  Button,
  Form,
  Col,
  Row,
  Tab,
  Tabs,
  Modal,
  CloseButton,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {
  formatDateTimeStandard,
  numberToWords,
} from "../../../components/basic/simpleFunctions";
// import { PopUpModalButton } from "../../../components/basic/fileViewModal";
import Logo from "../../../assets/Logo.png";
import jsPDF from "jspdf";
import { PopUpModalButton } from "../../../components/basic/fileViewModal";

const Event = () => {
  const [selectedTab, setSelectedTab] = useState("createEvent");
  const [eventTable, setEventTable] = useState([]);
  const [reqStatus, setReqStatus] = useState("Processing");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [appDisApi, setAppDisApi] = useState(false);
  const [registeredData, setRegisteredData] = useState([]);
  const [pdfFileURL, setPdfFileURL] = useState({});

  const [formData, setFormData] = useState({
    title: "",
    eventType: "",
    eventDate: "",
    eventTime: "",
    fee: "",
    description: "",
    eventUrl: "",
    heading: "",
    location: "",
  });

  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReset = () => {
    setFormData({
      title: "",
      eventType: "",
      eventDate: "",
      eventTime: "",
      fee: "",
      description: "",
      eventUrl: "",
      heading: "",
      location: "",
    });
  };

  const handleInputChange = (e, itemId) => {
    const { name, value } = e.target;
    setRegisteredData((prevData) => {
      return prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
    });
  };

  const itemsPerPage = 20;
  const totalPages = Math.ceil(eventTable.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = eventTable.slice(indexOfFirstItem, indexOfLastItem);

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      const apiFormData = new FormData();

      apiFormData.append("title", formData.title);
      apiFormData.append("eventType", formData.eventType);
      apiFormData.append("eventDate", formData.eventDate);
      apiFormData.append("eventTime", formData.eventTime);
      apiFormData.append("fee", formData.fee);
      apiFormData.append("description", formData.description);
      apiFormData.append("eventUrl", formData.eventUrl);
      apiFormData.append("heading", formData.heading);
      apiFormData.append("location", formData.location);

      const response = await axios.post(
        getURLbyEndPoint("createEvent"),
        apiFormData
      );

      if (response.data.status) {
        setFormData({
          title: "",
          eventType: "",
          eventDate: "",
          eventTime: "",
          fee: "",
          description: "",
          eventUrl: "",
          heading: "",
          location: "",
        });
      }

      alert(response.data.message);
      getAllEvents();
    } catch (error) {
      alert("Something went wrong!!");
      console.log(error);
    }
  };

  const handleDeleteEvent = async (id) => {
    try {
      const response = await axios.delete(getURLbyEndPoint("deleteEvent") + id);
      alert(response.data.message);
      getAllEvents();
    } catch (error) {
      alert("Something went wrong!!");
      console.log(error);
    }
  };

  const handleApproveRejectEvent = async (userId, status) => {
    setAppDisApi(true);
    const event = registeredData.find((item) => item._id === userId);
    try {
      const res = await axios.post(
        getURLbyEndPoint("approveRejectEvent") + userId,
        { status, remark: event.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        setAppDisApi(false);
        window.location.reload();
      } else {
        alert(res.data.message);
        setAppDisApi(false);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      setAppDisApi(false);
    }
  };

  const getAllEvents = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getAllEvents"));
      const sortedEvents = response.data.sort(
        (a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)
      );
      setEventTable(sortedEvents);
    } catch (error) {
      console.error("Error fetching Events:", error);
    }
  };

  const fetchRegisteredData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getAllRegisterEvent"));
      const clientId = response.data.clientId;

      if (response.status) {
        const data = response.data;
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
            };
          }
          return item;
        });
        setRegisteredData(modifiedData);
      } else {
        alert("failed to fetch registered data");
      }
    } catch (error) {
      alert("Error while Fetching Registered Data", error);
    }
  };

  //filtering data
  const filteredData = registeredData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic
  const [eventCurrentPage, setEventCurrentPage] = useState(1);
  const [eventItemsPerPage] = useState(10); // Assuming items per page is fixed

  const eventIndexOfLastItem = eventCurrentPage * eventItemsPerPage;
  const eventIndexOfFirstItem = eventIndexOfLastItem - eventItemsPerPage;
  const currentFiltered = filteredData.slice(
    eventIndexOfFirstItem,
    eventIndexOfLastItem
  );

  const eventTotalPages = Math.ceil(filteredData.length / eventItemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (eventCurrentPage, eventTotalPages) => {
    let startPage = Math.max(1, eventCurrentPage - 5);
    let endPage = Math.min(eventTotalPages, eventCurrentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, eventTotalPages);
      } else if (endPage === eventTotalPages) {
        startPage = Math.max(eventTotalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, eventTotalPages);

  const handlePageChange = (pageNumber) => {
    setEventCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setEventCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setEventCurrentPage((prevPage) => Math.min(prevPage + 1, eventTotalPages));
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    // Options for formatting the date
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function formatTime(timeString) {
    // Splitting the time string into hours and minutes
    const [hours, minutes] = timeString.split(":");
    // Converting to 12-hour format and adding AM/PM
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  }

  useEffect(() => {
    fetchRegisteredData();
  }, []);

  useEffect(() => {
    getAllEvents();
  }, [selectedTab === "createEvent"]);

  return (
    <div>
      <Layout>
        <MasterNavigationBar>
          <div className="d-flex flex-column px-3">
            <DashboardHeader
              heading="EVENTS / CHAMBER EVENT BOOKING "
              backUrl="/master-events"
            />
            <hr />

            <Tabs
              defaultActiveKey="createEvent"
              activeKey={selectedTab}
              onSelect={handleTabSelect}
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="viewBooking"
                title={<h6 className="tab-title">View Booking</h6>}
              >
                <div className="mt-5" />

                <>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex gap-3">
                      <Form.Check
                        inline
                        label="All"
                        name="group1"
                        type="radio"
                        checked={reqStatus === ""}
                        onChange={() => setReqStatus("")}
                      />
                      <Form.Check
                        inline
                        label="Processing"
                        name="group1"
                        type="radio"
                        checked={reqStatus === "Processing"}
                        onChange={() => setReqStatus("Processing")}
                        defaultChecked
                      />

                      <Form.Check
                        inline
                        label="Approved"
                        name="group1"
                        type="radio"
                        checked={reqStatus === "Approved"}
                        onChange={() => setReqStatus("Approved")}
                      />
                      <Form.Check
                        inline
                        label="Rejected"
                        name="group1"
                        type="radio"
                        checked={reqStatus === "Rejected"}
                        onChange={() => setReqStatus("Rejected")}
                      />
                    </div>
                    {/* <input
                      className="form-control w-25"
                      type="search"
                      placeholder="Search By Email / Ref No."
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    /> */}
                  </div>
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr className="text-center">
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          Sr No.
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          User Name
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          Application No
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          Participate / Event Detail
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          Ref No.
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    {currentFiltered.length > 0 ? (
                      <>
                        {currentFiltered.map((data, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>
                                {filteredData.length - indexOfFirstItem - index}
                                .
                              </td>
                              <td>
                                {data.companyEmail} <br />
                                {data.companyName}
                                <br />
                                {data.memberShipNo}
                              </td>{" "}
                              {/* User Name */}
                              <td>
                                {data.appNo}
                                <br />
                                {formatDate(data.registeredDate)}
                                <br />
                                {formatTime(data.registeredTime)}
                              </td>
                              <td>
                                {data.name}
                                <br />
                                {data.email}
                                <br />
                                {data.title}
                                <br />({data.heading})
                                <br />({data.eventType})
                                <br />
                                {data.numberOfParticipant} Participate <br />
                                Amount : {data.totalAmt}/-
                                <br />
                                <div className="mt-2">
                                  <PopUpModalButton
                                    title="receipt"
                                    fileLink={data.receipt}
                                  />
                                </div>
                              </td>{" "}
                              <td>
                                {data.isApproved === "Approved" ? (
                                  <h6 className="text-primary">
                                    Ref No: {data.refNo}{" "}
                                  </h6>
                                ) : (
                                  <>
                                    <span>-----</span>
                                  </>
                                )}
                              </td>
                              <td>
                                {data.isApproved === "Processing" ? (
                                  <>
                                    <div className="max-content">
                                      <Form.Control
                                        as="textarea"
                                        type="text"
                                        placeholder="Enter a Remark"
                                        rows={3}
                                        maxLength={50}
                                        onChange={(e) =>
                                          handleInputChange(e, data._id)
                                        }
                                        value={data.remark}
                                        name="remark"
                                      />
                                      {data.remark && (
                                        <div className="d-flex justify-content-around mt-3">
                                          {appDisApi ? (
                                            <>
                                              <Spinner animation="border text-success" />
                                            </>
                                          ) : (
                                            <>
                                              <Button
                                                className="m-0 p-0 border-none"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  boxShadow: "none",
                                                }}
                                                onClick={() => {
                                                  handleApproveRejectEvent(
                                                    data._id,
                                                    data.isApproved,
                                                    data.remark
                                                  );
                                                }}
                                              >
                                                <AiFillCheckCircle className="w-100 icon-size green-color" />
                                              </Button>
                                              <Button
                                                className="m-0 p-0 border-none"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  boxShadow: "none",
                                                }}
                                                onClick={() => {
                                                  handleApproveRejectEvent(
                                                    data._id,
                                                    false
                                                  );
                                                }}
                                              >
                                                <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                              </Button>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>Remark: {data.remark}</>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ))}

                        <div className="d-flex justify-content-start">
                          <Pagination>
                            <Pagination.Prev
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                            />
                            {Array.from(
                              { length: endPage - startPage + 1 },
                              (_, index) => (
                                <Pagination.Item
                                  key={index + startPage}
                                  active={index + startPage === currentPage}
                                  onClick={() =>
                                    handlePageChange(index + startPage)
                                  }
                                >
                                  {index + startPage}
                                </Pagination.Item>
                              )
                            )}
                            <Pagination.Next
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            />
                          </Pagination>
                        </div>
                      </>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={11} className="text-center">
                            No Matching Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </>
              </Tab>
              <Tab
                eventKey="createEvent"
                title={<h6 className="tab-title">Create Events</h6>}
              >
                <div className="mt-5" />
                <h4>Create Event</h4>
                <hr />
                <div className="mb-4">
                  <Form onSubmit={handleCreateEvent} onReset={handleReset}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="heading">
                          <Form.Label>Heading</Form.Label>
                          <Form.Control
                            type="text"
                            name="heading"
                            placeholder="Enter heading"
                            value={formData.heading}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3" controlId="title">
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Enter title"
                                value={formData.title}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="eventType">
                          <Form.Label>Event Type</Form.Label>
                          <Form.Select
                            name="eventType"
                            value={formData.eventType}
                            onChange={handleChange}
                            aria-label="Event Type"
                          >
                            <option>Select Type</option>
                            <option value="webinar">Webinar</option>
                            <option value="workshop">Seminar/Workshop</option>
                            <option value="livesession">Live Sessions</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3" controlId="eventDate">
                              <Form.Label>Event Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="eventDate"
                                value={formData.eventDate}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="eventTime">
                              <Form.Label>Time</Form.Label>
                              <Form.Control
                                type="time"
                                name="eventTime"
                                value={formData.eventTime}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="fee">
                          <Form.Label>Per Participate Fee</Form.Label>
                          <Form.Control
                            type="number"
                            name="fee"
                            value={formData.fee}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="description"
                            rows={3}
                            placeholder="Enter Description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="location">
                          <Form.Label>Location / Method Of Conduct</Form.Label>
                          <Form.Control
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="eventUrl">
                          <Form.Label>Add URL</Form.Label>
                          <Form.Control
                            type="text"
                            name="eventUrl"
                            placeholder="Enter URL"
                            value={formData.eventUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                    <Button variant="secondary" type="reset" className="ml-3">
                      Reset
                    </Button>
                  </Form>

                  <div className="mt-5" />
                  <h4>Created Events</h4>
                  <hr />
                  <Table
                    responsive
                    className="text-center"
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Type</th>

                        <th>Title</th>
                        <th>Description</th>
                        <th className="text-nowrap">Date & Time</th>
                        <th>Per Participate Fee</th>
                        <th>URL</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.eventType}</td>
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>
                            {formatDateTimeStandard(item.eventDate)}{" "}
                            {item.eventTime}
                          </td>
                          <td>{item.fee}</td>
                          <td>
                            <a
                              href={item.eventUrl}
                              className="text-primary text-underline"
                            >
                              Event URl
                            </a>
                          </td>
                          <td>
                            {/* <FaEdit
                             onClick={() => handleEdit(item)}
                            /> */}
                            <div onClick={() => handleDeleteEvent(item._id)}>
                              <MdDelete />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </MasterNavigationBar>
      </Layout>
    </div>
  );
};

export default Event;
