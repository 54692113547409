import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Col, Row, Form, Table, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";

const EmpJdPage = () => {
  const location = useLocation();
  const [empData, setEmpData] = useState(null);
  const [empJdReport, setEmpJdReport] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [extendingTime, setExtendingTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});
  const [closeJdApi, setCloseJdApi] = useState(false);

  useEffect(() => {
    if (location.state && location.state.data) {
      setEmpData(location.state.data);
    } else {
      console.error("No employee data found in location state.");
    }
  }, [location.state]);

  const formatDate = (date) => {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const formatDateForApi = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (date) => {
    const selectedDateObj = new Date(date);
    const today = new Date();

    if (selectedDateObj > today) {
      setSelectedDate(today);
    } else {
      setSelectedDate(selectedDateObj);
    }
  };

  const handleExtendTime = async (userId) => {
    if (!extendingTime) {
      alert("Please Select Time");
      return;
    }

    try {
      const res = await axios.put(getURLbyEndPointV2("extendTime") + userId, {
        extendingTime,
      });
      console.log("Time Extended", res);
      alert("Time Extended");
      window.location.reload();
    } catch (error) {
      console.error("Error in extending time", error);
      alert("Something went wrong while extending time");
    }
  };

  const handleCloseJd = async () => {
    try {
      setCloseJdApi(true);
      if (
        empJdReport?.jdDataList[empJdReport?.jdDataList.length - 1]
          .isCompleted !== "Completed"
      ) {
        alert("Complete all tasks before submitting");
        return;
      }

      const res = await axios.put(
        getURLbyEndPointV2("submitJd") + empJdReport._id
      );
      console.log("JD closed successfully", res);
      alert("JD closed successfully");
      setCloseJdApi(false);
      window.location.reload();
    } catch (error) {
      console.log("Error in Submitted Jd", error);
      alert("Error while Submitting Jd report");
    } finally {
      setCloseJdApi(false);
    }
  };

  useEffect(() => {
    const handleFetchJdReport = async () => {
      if (empData && empData._id) {
        setLoading(true);
        try {
          const currentDate = formatDateForApi(selectedDate);
          const res = await axios.get(
            `${getURLbyEndPointV2("fetchEmpJdReport")}${
              empData._id
            }?date=${currentDate}`
          );

          if (res.data.status) {
            setEmpJdReport(res.data.data);
          } else {
            setEmpJdReport(null);
          }
        } catch (error) {
          console.log("Error while getting jd report", error);
          setEmpJdReport(null);
        } finally {
          setLoading(false);
        }
      }
    };

    handleFetchJdReport();
  }, [empData, selectedDate]);

  useEffect(() => {
    const calculateTimeLeftForAllTasks = () => {
      if (empJdReport && empJdReport.jdDataList) {
        const updatedJdReport = empJdReport.jdDataList.map((data) => {
          if (data.endTime) {
            return {
              ...data,
              timeLeft: renderTimeLeft(data.endTime),
            };
          }
          return data;
        });
        setEmpJdReport((prev) => ({ ...prev, jdDataList: updatedJdReport }));
      }
    };

    calculateTimeLeftForAllTasks();
    const interval = setInterval(calculateTimeLeftForAllTasks, 1000); // 10 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="HR/ EMPLOYEE JD LIST/ EMP JD"
            backUrl="/master-hr-jd-list"
          />
          <hr />

          <Row>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col as="h5">
                  EMPLOYEE CODE:{" "}
                  <span className="text-secondary">{empData?.employeeId}</span>
                </Col>
                <Col as="h5">
                  EMPLOYEE NAME :{" "}
                  <span className="text-secondary">
                    {empData?.employeeName}{" "}
                  </span>
                </Col>
              </Row>
              <hr />
              <Row className="d-flex align-items-center">
                <Col as="h5">
                  DESIGNATION :{" "}
                  <span className="text-secondary">
                    {empData?.designation}{" "}
                  </span>
                </Col>
                <Col as="h5">
                  REPORTING TO :{" "}
                  <span className="text-secondary">
                    {empData?.reportingTo ? empData?.reportingTo : "---"}{" "}
                  </span>
                </Col>
              </Row>
              <hr />
              <Form.Group
                controlId="formDate"
                className="d-flex align-items-center"
              >
                <Form.Label className="h5 me-3">Date:</Form.Label>
                <Form.Control
                  type="date"
                  className="w-25"
                  value={formatDate(selectedDate)}
                  max={formatDate(new Date())}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <img
                  src={empData?.profilePic ? empData.profilePic : null}
                  height="200"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <hr />

          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>SHIFT</th>
                <th>START TIME</th>
                <th>DURATION</th>
                <th>JOB ROLE</th>
                <th>DESCRIPTION</th>
                <th>END TIME</th>
                <th>EXTEND TIME</th>
              </tr>
            </thead>
            <tbody>
              {empJdReport?.jdDataList.map((data, index) => (
                <tr key={data._id}>
                  <td>{data.shift}</td>
                  <td>{data.startTime}</td>
                  <td>{data.duration} Mins</td>
                  <td>{data.jobRole}</td>
                  <td>{data.description}</td>
                  <td>{data.endTime}</td>
                  <td>
                    <div>
                      {data.isCompleted === "Processing" ? (
                        checkTimeStatus(data.endTime) === "Not Expired" ? (
                          <>
                            <pre>{renderTimeLeft(data.endTime)} Hours Left</pre>
                          </>
                        ) : (
                          <>
                            <pre>Time Expired</pre>
                            <Form.Group className="mb-2">
                              <Form.Control
                                as="select"
                                value={extendingTime}
                                onChange={(e) =>
                                  setExtendingTime(e.target.value)
                                }
                              >
                                <option value="">Select duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="45">45 minutes</option>
                                <option value="60">60 minutes</option>
                              </Form.Control>

                              {(extendingTime === "45" ||
                                extendingTime === "60") && (
                                <Form.Text>
                                  Choose an appropriate duration for your task.
                                </Form.Text>
                              )}
                            </Form.Group>
                            <Button onClick={() => handleExtendTime(data._id)}>
                              Extend Time
                            </Button>
                          </>
                        )
                      ) : (
                        "---"
                      )}
                    </div>
                  </td>
                </tr>
              ))}

              {empJdReport?.jdDataList.length > 0 &&
                empJdReport.isCompleted === "Processing" && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      <Button
                        variant="success"
                        onClick={handleCloseJd}
                        disabled={closeJdApi}
                      >
                        Close JD
                      </Button>
                    </td>
                  </tr>
                )}

              {(empJdReport?.jdDataList.length === 0 ||
                empJdReport === null) && (
                <tr>
                  <td colSpan={7} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default EmpJdPage;

const calculateTimeLeft = (endTime) => {
  const [endHours, endMinutes] = endTime?.split(":").map(Number);
  const now = new Date();
  const end = new Date();
  end.setHours(endHours, endMinutes, 0, 0);

  const difference = end - now;
  const timeLeft = {
    hours: Math.floor(difference / (1000 * 60 * 60)),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
  return timeLeft;
};

const renderTimeLeft = (endTime) => {
  const timeLeft = calculateTimeLeft(endTime);
  return `${String(timeLeft.hours).padStart(2, "0")}:${String(
    timeLeft.minutes
  ).padStart(2, "0")}:${String(timeLeft.seconds).padStart(2, "0")}`;
};

//CHECK FUNCTION FOR TIME EXPIRED OR NOT
function checkTimeStatus(endTime) {
  if (!endTime) {
    return "Time Expired"; // Handle case where endTime is undefined or null
  }

  const now = new Date();
  const [endHours, endMinutes] = endTime.split(":").map(Number);
  const end = new Date();
  end.setHours(endHours, endMinutes, 0, 0);

  if (end > now) {
    return "Not Expired";
  } else {
    return "Time Expired";
  }
}
