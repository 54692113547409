import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Form, Pagination, Spinner, Table } from "react-bootstrap";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { getURLbyEndPointV2 } from "../../../store/api";

const B2bCountrySlotControl = () => {
  const [countrySlots, setCountrySlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchCountrySlots();
  }, []);

  const fetchCountrySlots = async () => {
    setLoading(true);
    try {
      const response = await axios.get(getURLbyEndPointV2("getB2bCountrySlots"));
      const updatedCountries = response.data.map((country) => ({
        ...country,
        days: country.dateSlots.map(dateSlot => ({
          date: dateSlot.date,
          customSlots: dateSlot.slots.length > 0 ? dateSlot.slots : country.defaultSlots.map(slot => ({ ...slot, isActive: false })),
        })),
      }));
      setCountrySlots(updatedCountries);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching slots:", error);
      setLoading(false);
    }
  };

  const handleToggleSlot = (countryId, dayIndex, slotIndex) => {
    const updatedSlots = countrySlots.map((country) => {
      if (country._id === countryId) {
        const updatedDays = country.days.map((day, dIndex) => {
          if (dIndex === dayIndex) {
            const updatedCustomSlots = [...day.customSlots];
            if (updatedCustomSlots[slotIndex]) {
              updatedCustomSlots[slotIndex].isActive = !updatedCustomSlots[slotIndex].isActive;
            }
            return { ...day, customSlots: updatedCustomSlots };
          }
          return day;
        });
        return { ...country, days: updatedDays };
      }
      return country;
    });
    setCountrySlots(updatedSlots);
  };

  const saveSlots = async (countryId, days) => {
    const formattedDays = days.map(day => ({
      date: day.date,
      slots: day.customSlots,
    }));

    try {
      await axios.put(getURLbyEndPointV2("updateB2bCountrySlots") + countryId, { days: formattedDays });
      alert("Slots updated successfully");
      fetchCountrySlots();
    } catch (error) {
      console.error("Error saving slots:", error);
      alert("Error saving slots");
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = countrySlots.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(countrySlots.length / itemsPerPage);

  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4);
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }
    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="B2B Country Slot Control"
            backUrl="/b2b-virtual-forum"
          />
          <hr />
          <Table striped bordered hover className="mb-4">
            <thead>
              <tr>
                <th>Country</th>
                <th>Day 1 Slots</th>
                <th>Day 2 Slots</th>
                <th>Day 3 Slots</th>
              </tr>
            </thead>
            <tbody>
              {currentFiltered.map((country) => (
                <tr key={country._id}>
                  <td>{country.country}</td>
                  {country.days.map((day, dayIndex) => (
                    <td key={dayIndex}>
                      <Form>
                        {day.customSlots.map((slot, index) => (
                          <Form.Check
                            key={index}
                            type="checkbox"
                            label={`${slot.startTime} - ${slot.endTime}`}
                            checked={slot.isActive}
                            onChange={() => handleToggleSlot(country._id, dayIndex, index)}
                          />
                        ))}
                        <button
                          type="button"
                          className="btn btn-primary mt-2"
                          onClick={() => saveSlots(country._id, country.days)}
                        >
                          Save
                        </button>
                      </Form>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from(
                  { length: endPage - startPage + 1 },
                  (_, index) => (
                    <Pagination.Item
                      key={index + startPage}
                      active={index + startPage === currentPage}
                      onClick={() => handlePageChange(index + startPage)}
                    >
                      {index + startPage}
                    </Pagination.Item>
                  )
                )}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default B2bCountrySlotControl;
