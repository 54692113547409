import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  Row,
  FormControl,
  InputGroup,
  Card,
  OverlayTrigger,
  Popover,
  CloseButton,
} from "react-bootstrap";
import Message from "./Message";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";
import { TruncatedTextArea } from "../basic/textcustomized";
import { io } from "socket.io-client";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import axios from "axios";
import { domain, getURLbyEndPointV2 } from "../../store/api";
import { FaStar } from "react-icons/fa";

const socket = io(domain, {
  withCredentials: true,
});

const ChatBox = ({
  disabled,
  tokenNo,
  wingId,
  client,
  summary,
  isPaused,
  isClosed,
  rating,
}) => {
  const [show, setShow] = useState(false);
  const [msgList, setMsgList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  
 // Function to handle when the modal is opened
  const handleShow = () => {
    setShow(true);
    socket.emit("joinRoom", wingId); // Join the room when opening the modal
    socket.emit("fetchMessages", wingId);

    // Handle incoming messages
    socket.on("allMessages", (messages) => {
      setMsgList(messages);
    });
  };

  // Function to handle when the modal is closed
  const handleClose = () => {
    setShow(false);
    socket.emit("leaveRoom", wingId); // Leave the room when closing the modal
    socket.off("allMessages"); // Clean up the event listener
  };

  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 72)}px`; // Adjust height dynamically, with a max height of 72px (approx. 3 rows)
    textarea.style.overflow = textarea.scrollHeight > 72 ? "auto" : "hidden";
  };

  // Handle incoming messages from Socket.IO
  useEffect(() => {
    if (!socket) return; // Ensure socket is initialized

    socket.emit("joinRoom", wingId);

    const handleAllMessages = (messages) => {
      setMsgList(messages);
    };

    socket.on("allMessages", handleAllMessages);

    return () => {
      socket.off("allMessages", handleAllMessages);
      socket.emit("leaveRoom", wingId);
    };
  }, [wingId]);

  const handleSendMessage = (type, userId) => {
    if (newMessage.trim() === "") return;

    if (type === "payment") {
      if (!/^\d+$/.test(newMessage)) {
        alert("Enter only numbers.");
        return;
      }
    }

    const messageData = {
      client: client,
      wingId,
      message: newMessage,
      type: type,
      receiver: "user",
      sender: "admin",
      createdAt: new Date().toISOString(),
    };

    socket.emit("sendMessage", messageData);
    setNewMessage("");
  };

  const handleChatUpdate = async (isPaused, isClosed) => {
    try {
      const res = await axios.put(getURLbyEndPointV2("updateChat") + wingId, {
        isPaused,
        isClosed,
      });
      if (res.status === 200) {
        alert("Chat updated successfully");
        window.location.reload();
      } else {
        alert("Failed to update chat");
      }
    } catch (error) {
      alert("Error while updating chat");
      console.log("Error fetching data:", error);
    }
  };

  return (
    <>
      <Button
        variant="success"
        className="me-2"
        onClick={handleShow}
        disabled={disabled}
      >
        {" "}
        Chat
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Modal.Header style={{ backgroundColor: "#0b3b5d", color: "white" }}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Modal.Title className="h5">Token No: {tokenNo}</Modal.Title>
              <CloseButton variant="white" />
            </div>
          </Modal.Header>
          <Modal.Body className="h-100">
            <Row className="h-100 mx-1">
              <Col
                md={8}
                className="d-flex flex-column align-items-end h-100 w-100 bg-light "
              >
                <div
                  className="d-flex flex-column mt-auto w-100 pe-3 overflow-auto"
                  style={{ maxHeight: "550px" }}
                >
                  {msgList.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg.type === "aecciMsg" ? (
                        <AecciMessage text={msg.message} />
                      ) : msg.sender === "admin" ? (
                        msg.type === "msg" ? (
                          <Message
                            align="Sent"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        ) : msg.type === "file" ? (
                          <File
                            align="Sent"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                            msgId={msg._id}
                            fileUrl={msg.fileUrl ? msg.fileUrl : null}
                          />
                        ) : msg.type === "payment" ? (
                          <Payment
                            align="Sent"
                            text={msg.message}
                            status={msg.paymentStatus}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        ) : null
                      ) : msg.sender === "user" && msg.type === "msg" ? (
                        <Message
                          align="Received"
                          text={msg.message}
                          time={new Date(msg.createdAt).toLocaleString()}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>
                <hr className="w-100" />
                {!isClosed && (
                  <InputGroup className="w-100 mb-2">
                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={
                        <Popover id="popover-positioned-top">
                          <Popover.Body>Content to Add</Popover.Body>
                        </Popover>
                      }
                    >
                      <InputGroup.Text
                        id="btnGroupAddon"
                        className="text-secondary bg-white cursor-pointer fs-5 border-right-0"
                      >
                        <MdAttachFile />
                      </InputGroup.Text>
                    </OverlayTrigger>
                    <FormControl
                      as="textarea"
                      rows={1}
                      placeholder="Type your message"
                      aria-label="Type your message"
                      className="border-right-0 border-left-0"
                      style={{
                        resize: "none",
                        overflow: "hidden",
                        height: "auto",
                        minHeight: "24px", // Approx. height for 1 row
                        maxHeight: "72px", // Approx. height for 3 rows
                        boxSizing: "border-box",
                      }}
                      ref={textareaRef}
                      onInput={handleInput}
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />

                    <InputGroup.Text
                      id="btnGroupAddon"
                      className="text-secondary bg-white cursor-pointer fs-5 border-left-0 border-right-0"
                      onClick={() => handleSendMessage("payment")}
                    >
                      <FaIndianRupeeSign />
                    </InputGroup.Text>
                    <InputGroup.Text
                      id="btnGroupAddon"
                      className="text-info bg-white cursor-pointer fs-5 border-left-0 border-right-0"
                      onClick={() => handleSendMessage("file")}
                    >
                      <FaFileAlt />
                    </InputGroup.Text>
                    <InputGroup.Text
                      id="btnGroupAddon"
                      className="text-success bg-white cursor-pointer border-left-0"
                      onClick={() => handleSendMessage("msg")}
                    >
                      <IoSend className="fs-5" />
                    </InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
              <Col md={4} className="px-0">
                <Col>
                  <Card>
                    <Card.Header className="h5">Query Details</Card.Header>
                    <Card.Body
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      <Card.Text>
                        <TruncatedTextArea text={summary} maxChars={100} />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                {!isClosed && (
                  <Col className="mb-3">
                    <Button
                      variant="info"
                      className="w-100 my-2"
                      onClick={() => handleChatUpdate(!isPaused, isClosed)}
                    >
                      {isPaused ? "OPEN CHAT" : "PAUSE CHAT"}
                    </Button>
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={() => handleChatUpdate(isPaused, !isClosed)}
                    >
                      CLOSE TOKEN
                    </Button>
                  </Col>
                )}
                {isClosed && (
                  <Col className="mt-2">
                    {rating ? (
                      <div className="d-flex align-items-baseline">
                        <h6>RATING: {rating} </h6>{" "}
                        <FaStar style={{ color: "#ffc107" }} />
                      </div>
                    ) : (
                      <p>User hasn't rated Yet.</p>
                    )}
                  </Col>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ChatBox;

const AecciMessage = ({ text }) => {
  return (
    <Card className="mb-2 border-none">
      <Card.Body
        style={{ backgroundColor: "#E8DAF5" }}
        className="text-info py-1 rounded-1"
      >
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const File = ({ align, text, time, msgId, fileUrl }) => {
  const messageStyle = {
    backgroundColor: align === "Sent" ? "#ececec" : "#63b190",
    color: align === "Sent" ? "black" : "white",
    borderRadius:
      align === "Sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "50%",
    maxWidth: "75%",
    alignSelf: align === "Sent" ? "flex-end" : "flex-start",
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "Sent" ? "black" : "white",
    textAlign: "right",
    marginTop: "auto",
  };

  const handleViewClick = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "Sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <Col
          className="border-5 text-black"
          style={{
            backgroundColor: "#ececec",
            borderRadius: "15px",
            marginBottom: "10px",
            borderLeft: "5px solid #B16379",
            padding: "0px",
          }}
        >
          <h6 className="mx-3 mt-2">{text}</h6>
          <Button
            variant="secondary"
            className="pe-0 w-100"
            style={{ borderRadius: "0px 0px 11px 11px" }}
            disabled={!fileUrl}
            onClick={handleViewClick}
          >
            VIEW
          </Button>
        </Col>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};

const Payment = ({ align, text, time, status }) => {
  const messageStyle = {
    backgroundColor: align === "Sent" ? "#ececec" : "#63b190",
    color: align === "Sent" ? "black" : "white",
    borderRadius:
      align === "Sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "30%",
    maxWidth: "50%",
    alignSelf: align === "Sent" ? "flex-end" : "flex-start",
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "Sent" ? "black" : "white",
    textAlign: "right",
    marginTop: "auto",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "Sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <Col
          className="border-5 text-black py-3 text-center"
          style={{
            backgroundColor: "#39e75f",
            borderRadius: "15px",
            padding: "10px",
            borderLeft: "5px solid #B16379",
          }}
        >
          <h4>₹ {text}</h4>
        </Col>
        <Row className="d-flex align-items-center" style={{ color: "#B16379" }}>
          <Col xs="auto" className="ms-3 pe-1">
            {status === "Completed" ? (
              <FaCircleCheck className="fs-6" />
            ) : status === "Processing" ? (
              <MdOutlineAccessTimeFilled className="fs-6" />
            ) : null}
          </Col>
          <Col className="ms-0 ps-0">
            <h6 className="mb-0">{status}</h6>
          </Col>
        </Row>

        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};
