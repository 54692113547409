import React from "react";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import MasterLayout from "../../../../components/Layouts/Layouts/MasterLayout";
import { useNavigate } from "react-router-dom";


const ViewEmpList = () => {
  const navigate = useNavigate();

  return (
    <>
      <Layout>
        <MasterLayout>
        <div className="d-flex flex-column px-3">
        <DashboardHeader
              heading="Employee Access List"
              backUrl="/master-administration"
            />
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => {
                navigate("/master-employee-access-admin");
              }}
            >
              ADMIN DEPARTMENT{" "}
            </button>
          </div>
          <hr/>
          <div className="d-flex justify-content-around mb-2">
            <button
              onClick={() => {
                navigate("/master-profile-employee");
              }}
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
            >
              PROFILE{" "}
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-services");
              }}
            >
              SERVICES{" "}
            </button>
          </div>
          <hr/>
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-wings");
              }}
            >
              THE WINGS
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }} 
              onClick={() => {
                navigate("/employee-access-arbitration-center");
              }}
            >
              ARBITRATION CENTER
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-events");
              }}
            >
              EVENTS
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-publication");
              }}
            >
              PUBLICATIONS & AD's
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-special-control");
              }}
            >
              SPECIAL CONTROLS
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/employee-access-report-analysis");
              }}
            >
             REPORT & ANALYSIS
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => {
                navigate("/employee-access-hrdepartment");
              }}
            >
              HR DEPARTMENT
            </button>
          </div>
          <hr/>
        </div>
        </MasterLayout>
      </Layout>
    </>
  );
};

export default ViewEmpList;
