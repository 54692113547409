import Cookies from "js-cookie";

function storeAuthUserCookie(userData) {
  Cookies.set("userDetails", userData);
}

function getAuthUserCookie() {
  // Retrieve userDetails from cookies, decode it, and parse it back into an object

  return JSON.parse(decodeURIComponent(Cookies.get("userDetails")));
}

export { storeAuthUserCookie, getAuthUserCookie };
