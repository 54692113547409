import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { Badge } from "react-bootstrap";

const SideBar = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          getURLbyEndPoint("getNotificationStatus")
        );
        console.log(response);
        if (response.status === 200) {
          const data = response.data.data;
          setNotificationData(data);
        } else {
          alert("Failed to fetch notification data.");
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };

    fetchNotification();
  }, []);

  const links = [
    { text: "Adminsitration", path: "/master-administration" },
    { text: "Profile", path: "/master-profile" },
    { text: "Services", path: "/master-services" },
    { text: "HR Department", path: "/master-dashboard" },
    { text: "The Wings", path: "/master-dashboard" },
    { text: "Arbitration Center", path: "/master-dashboard" },
    { text: "Events", path: "/master-dashboard" },
    { text: "Special Controls", path: "/master-arbitration" },
    { text: "Report & Analysis", path: "/master-arbitration" },
    // { text: "News Alert", path: "/master-news" },

    { text: "Publications & AD's", path: "/master-publication" },
  ];
  return (
    <>
      <div className="cursor-pointer mobileViews">
        <div
          className="bg-grey p-4 my-0 rounded sidebar-width"
          style={{ minHeight: "70vh" }}
        >
          <>
            {" "}
            <h4>MENU</h4>
            <hr />
            {links.map((link, index) => (
              <>
                <h5
                  className={
                    window.location.pathname === link.path
                      ? "text-black"
                      : "text-secondary"
                  }
                  style={{
                    fontWeight:
                      window.location.pathname === link.path
                        ? "bold"
                        : "normal",
                    marginLeft:
                      window.location.pathname === link.path ? "1rem" : "0px",
                  }}
                  onClick={() => navigate(link.path)}
                >
                  {link.text}{" "}
                  <span
                    className="badge bg-primary"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData[link.text] !== 0
                      ? notificationData[link.text]
                      : null}
                  </span>
                </h5>
                {index < links.length - 1 && <hr />}{" "}
                {/* Add hr for all links except the last one */}
              </>
            ))}
          </>
        </div>
      </div>
    </>
  );
};

export default SideBar;
