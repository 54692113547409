import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";

const MasterNewsAlert = () => {
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="NEWS ALERT" backUrl="/master-dashboard" />
          <hr />
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterNewsAlert;
