import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { getAuthUserCookie } from "../../../store/services";

const SpecialControl = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="Special Controls"
            backUrl="/master-dashboard"
          />
          <hr />

          <div className="mb-4">
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/extra-access-controls")}
                disabled={!getAuthUserCookie().accessList.includes("ExtraAccessControl")}
              >
                EXTRA ACCESS CONTROL
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/master-price-control")}
                disabled={
                  !getAuthUserCookie().accessList.includes("PriceControl")
                }
              >
                PRICE CONTROL{" "}
              </button>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "85%" }}
                onClick={() => navigate("/master-dump-files")}
                // disabled={!getAuthUserCookie().accessList.includes("ECOAdmin")}
              >
                DUMP FILES{" "}
              </button>
            </div>
            <hr />
          </div>
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default SpecialControl;
