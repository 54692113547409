import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import * as XLSX from "xlsx";

import {
  Button,
  Form,
  Table,
  Row,
  Card,
  Col,
  Pagination,
} from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";
import { TbFileExport } from "react-icons/tb";

// Import Chart.js and register the components
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatDateAndTime } from "../../../components/basic/TimeAndDate";
ChartJS.register(ArcElement, Tooltip, Legend);

const serviceOptions = [
  "Electronic Certificate",
  "Semi-Electronic Certificate",
  "Non-Standard",
  "Recommendation Letter",
  "Membership Renewal",
  "Chamber Event Booking",
  "Deposit",
  "AECCI",
  "ADs",
];

const MasterReport = () => {
  const [reportData, setReportData] = useState([]);
  const [compList, setComList] = useState([]);

  const [formData, setFormData] = useState({
    serType: null,
    MembershipNo: null,
    FromDate: null,
    ToDate: null,
  });

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const res = await axios.post(
          getURLbyEndPointV2("getReportData"),
          formData
        );
        // console.log(res);
        if (res.status === 200) {
          const data = res.data.data;
          setReportData(data);
          calculateBalances(data);
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };

    const fetchCompListReport = async () => {
      try {
        const res = await axios.get(getURLbyEndPointV2("getCompList"));
        // console.log(res);
        if (res.status === 200) {
          const data = res.data.data;
          setComList(data);
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };

    fetchCompListReport();
    fetchReportData();
  }, [formData]);

  const calculateBalances = (data) => {
    if (data.length === 0) {
      // Set all balances to zero if the data array is empty
      setBalances({
        openingBalance: 0,
        closingBalance: 0,
        totalCredit: 0,
        totalDebit: 0,
      });
      return;
    }

    // Calculate Opening Balance (balance of the last element in the list)
    let openingBalance = 0;

    const lastTransaction = data[data.length - 1];

    if (lastTransaction.paymentType === "credit") {
      openingBalance = lastTransaction.balance - lastTransaction.amount;
    } else if (lastTransaction.paymentType === "debit") {
      openingBalance = lastTransaction.balance + lastTransaction.amount;
    }

    // Calculate Closing Balance (balance of the first element in the list)
    const closingBalance = data[0].balance;

    // Calculate Total Credit and Total Debit
    let totalCredit = 0;
    let totalDebit = 0;

    data.forEach((transaction) => {
      if (transaction.paymentType === "credit") {
        totalCredit += transaction.amount;
      } else if (transaction.paymentType === "debit") {
        totalDebit += transaction.amount;
      }
    });

    // Update the state with the calculated values
    setBalances({
      openingBalance,
      closingBalance,
      totalCredit,
      totalDebit,
    });
  };

  const [balances, setBalances] = useState({
    openingBalance: 0,
    closingBalance: 0,
    totalCredit: 0,
    totalDebit: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle input change for company name or membership number
  const handleChangeCompName = (e) => {
    const selectedMembershipNo = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      MembershipNo: selectedMembershipNo,
    }));
  };

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = reportData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(reportData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Reports"
            backUrl="/master-report-analysis"
          />
          <hr />

          <Card className="mb-3">
            <Card.Header>
              <Row className="d-flex justify-content-between align-items-end">
                <Col className="d-flex gap-4" md={8}>
                  <Form.Group controlId="ServiceType">
                    <Form.Label className="fw-semibold text-secondary">
                      Service Type
                    </Form.Label>
                    <Form.Select
                      className="fw-semibold"
                      name="serType"
                      value={formData.serType}
                      onChange={handleInputChange}
                    >
                      <option value="">All Services</option>
                      {serviceOptions.map((option) => (
                        <option key={option} value={option}>
                          {option.replace(/-/g, " ")}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="MembershipNo">
                    <Form.Label className="fw-semibold text-secondary">
                      Select Company
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="fw-semibold"
                      name="MembershipNo"
                      value={formData.MembershipNo || ""}
                      onChange={handleChangeCompName}
                    >
                      <option value="">Select Company...</option>
                      {compList.map((company) => (
                        <option
                          key={company.memberShipNo}
                          value={company.memberShipNo}
                        >
                          {company.companyName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="FromDate">
                    <Form.Label className="fw-semibold text-secondary">
                      From Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className="fw-semibold"
                      name="FromDate"
                      value={formData.FromDate}
                      onChange={handleInputChange}
                      max={
                        formData.ToDate
                          ? formData.ToDate
                          : new Date().toISOString().split("T")[0]
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="ToDate">
                    <Form.Label className="fw-semibold text-secondary">
                      To Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className="fw-semibold"
                      name="ToDate"
                      value={formData.ToDate}
                      onChange={handleInputChange}
                      min={formData.FromDate}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
                <Col className="d-flex justify-content-end" md={4}>
                  <Button
                    variant="info"
                    disabled={!formData?.MembershipNo}
                    onClick={() =>
                      exportToExcel(reportData, formData.MembershipNo, compList)
                    }
                  >
                    <TbFileExport /> Export
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card
                className="text-black mb-3"
                style={{
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Subtitle className="text-success">
                        Opening Balance
                      </Card.Subtitle>
                      <Card.Text className="fs-5 fw-semibold">
                        {" "}
                        {formData.MembershipNo === null ||
                        formData.MembershipNo === ""
                          ? "---"
                          : `₹ ${balances.openingBalance}/-`}
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Subtitle className="text-success">
                        Closing Balance
                      </Card.Subtitle>
                      <Card.Text className="fs-5 fw-semibold">
                        {formData.MembershipNo === null ||
                        formData.MembershipNo === ""
                          ? "---"
                          : `₹ ${balances.closingBalance}/-`}
                      </Card.Text>
                    </Col>

                    <Col>
                      <Card.Subtitle className="text-success">
                        Total Credit
                      </Card.Subtitle>
                      <Card.Text className="fs-5 fw-semibold">
                        ₹ {balances.totalCredit}/-
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Subtitle className="text-success">
                        Total Debit
                      </Card.Subtitle>
                      <Card.Text className="fs-5 fw-semibold">
                        ₹ {balances.totalDebit}/-
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Table striped bordered hover className="mb-0">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Description</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((item, index) => (
                      <tbody>
                        <tr key={item._id}>
                          <th scope="row">
                            <div style={{ minWidth: "max-content" }}>
                              {reportData.length - (indexOfFirstItem + index)}.{" "}
                              <div>
                                Transaction At:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(item.updatedAt))}
                                </span>
                              </div>
                            </div>
                          </th>
                          <td className="w-50">
                            {" "}
                            <h6>
                              {item.remark
                                ? `${item.Particular} (${item.remark})`
                                : item.Particular}
                            </h6>
                          </td>
                          <td>
                            {item.paymentType === "credit"
                              ? item.amount
                              : "---"}
                          </td>
                          <td>
                            {" "}
                            {item.paymentType === "debit" ? item.amount : "---"}
                          </td>
                          <td>{item.balance}</td>
                        </tr>
                      </tbody>
                    ))}{" "}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>

              {totalPages > 1 && (
                <div className="d-flex justify-content-start mt-2">
                  <Pagination>
                    <Pagination.Prev
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    />
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => (
                        <Pagination.Item
                          key={index + startPage}
                          active={index + startPage === currentPage}
                          onClick={() => handlePageChange(index + startPage)}
                        >
                          {index + startPage}
                        </Pagination.Item>
                      )
                    )}
                    <Pagination.Next
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default MasterReport;

// Exporting Wallet Data-----------------------------------
const exportToExcel = (data, memNo, compList) => {
  const companydata = compList.find((comp) => comp.memberShipNo === memNo);
  console.log("console is here", companydata.companyName);

  const dataForExcel = data.map((item, index) => ({
    "SR. NO": index + 1,
    Service: item.Particular,
    ref: item.remark,
    credit: item.paymentType === "credit" ? item.amount : "---",
    debit: item.paymentType === "debit" ? item.amount : "---",
    balance: item.balance,
    "Approved Date": formatDateAndTime(item.updatedAt),
  }));

  // Create worksheet from data
  const ws = XLSX.utils.json_to_sheet(dataForExcel);

  // Add header row at the top
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `Company Name: ${companydata.companyName} (${companydata.memberShipNo})`,
      ],
    ],
    {
      origin: "A1",
    }
  );

  // Merge cells for the header row
  ws["!merges"] = ws["!merges"] || [];
  ws["!merges"].push({
    s: { r: 0, c: 0 },
    e: { r: 0, c: 6 },
  });

  // Add column headers in the second row
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        "SR. NO",
        "Service",
        "ref",
        "credit",
        "debit",
        "balance",
        "Approved Date",
      ],
    ],
    {
      origin: "A2",
    }
  );

  // Adjust column widths
  const wscols = [
    { wch: 10 }, // "SR. NO"
    { wch: 30 }, // "Service"
    { wch: 30 }, // "ref"
    { wch: 15 }, // "credit"
    { wch: 15 }, // "debit"
    { wch: 15 }, // "balance"
    { wch: 20 }, // "Approved Date"
  ];
  ws["!cols"] = wscols;

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Wallet History");

  // Save the file
  XLSX.writeFile(wb, "report.xlsx");
};
