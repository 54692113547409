import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Form, Table, Button, Pagination, Spinner, Modal, Row, Col, CloseButton, Badge } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPoint, getURLbyEndPointV2 } from "../../../store/api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { formatDateAndTime } from "../../../components/basic/TimeAndDate";
import { TruncatedText } from "../../../components/basic/textcustomized";
import { IoMdDoneAll } from "react-icons/io";
import { GiCancel } from "react-icons/gi";
import { FaEdit, FaHistory } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";
import { MdCancel, MdSend } from "react-icons/md";
import { BsSkipForwardFill } from "react-icons/bs";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { GoHistory } from "react-icons/go";

const MarketingDemo = () => {
  const [trialData, setTrialData] = useState([]);
  const [appDisApi, setAppDisApi] = useState(false);
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [activeRowId, setActiveRowId] = useState(null);
  const [modalState, setModalState] = useState({
    activeModal: null,
  });

  const handleShow = (modalName, rowData) => {
    setSelectedRowData(rowData);
    setModalState({ activeModal: modalName });
  };

  const handleClose = () => {
    setModalState({ activeModal: null });
    setSelectedRowData(null);
  };

  //simple functions
  const handleInputChange = (itemId, name, value) => {
    setTrialData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };


  const handleTargetDemoComplete = async (id) => {
    try {
      const res = await axios.put(
        getURLbyEndPointV2("updateTargetDemoComplete") + id,
        { isTargetDemoComplete: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };


  const handleApproveRejectTrialUser = async (userId, status) => {
    setAppDisApi(true);
    const userData = trialData.find((item) => item._id === userId);
    try {
      const res = await axios.post(
        getURLbyEndPoint("approveRejectTrialUser") + userId,
        { status, remark: userData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        setAppDisApi(false);
        window.location.reload();
      } else {
        alert(res.data.message);
        setAppDisApi(false);
      }
    } catch (error) {
      alert("Error while handling Trial data.");
      console.error("Error:", error);
    } finally {
      setAppDisApi(false);
    }
  };


  const handleTargetMessageSend = async (id) => {
    // Find the data for the specific row
    const rowData = trialData.find(item => item._id === id);
    if (!rowData) {
      alert("Data not found for the specified row.");
      return;
    }
    const { targetType, action, targetRemark } = rowData;
    const clientId = rowData._id;
    // Make sure the necessary fields are present
    if (!targetType || !action || !targetRemark) {
      alert("Please fill out all fields before submitting.");
      return;
    }
    // Create a new FormData object and append the fields
    const formData = new FormData();
    formData.append("targetType", targetType);
    formData.append("action", action);
    formData.append("targetRemark", targetRemark);
    try {
      const res = await axios.post(
        getURLbyEndPointV2("createB2BTarget") + clientId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Target created successfully", res);
      alert("Target created successfully");
      window.location.reload();
    } catch (error) {
      console.log("Error in creating Target", error);
      alert("Error while creating Target");
    }
  };

  

  const fetchTrialData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getAllTrialClient"));
      if (response.status === 200) {
        const data = response.data.data;

        // Add properties to each object in the data.data array
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
            };
          } else if (item.isApproved === "Approved") {
            return {
              ...item,
            };
          }
          return item;
        });

        setTrialData([...modifiedData]);
        // console.log("result", modifiedData);
      } else {
        alert("Failed to fetch trial user data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  const handleRegisterClick = (data) => {
    const url = new URL("https://e-platform.aecci.org.in/register-3");
    // const url = new URL("http://localhost:3000/register-3");
    if (data.companyName) {
      url.searchParams.append("companyName", data.companyName);
    }
    if (data.email) {
      url.searchParams.append("email", data.email);
    }
    if (data.firstName) {
      url.searchParams.append("firstName", data.firstName);
    }
    if (data.lastName) {
      url.searchParams.append("lastName", data.lastName);
    }
    if (data.country) {
      url.searchParams.append("country", data.country);
    }
    if (data.pinCode) {
      url.searchParams.append("pinCode", data.pinCode);
    }
    if (data.phoneNo) {
      url.searchParams.append("phoneNo", data.phoneNo);
    }
    if (data.inputNumber) {
      url.searchParams.append("inputNumber", data.inputNumber);
    }
    if (data.reqPath === "B2BInterest") {
      url.searchParams.append("selectMembership", "Digital User");
    }
    if(data.reqPath){
      url.searchParams.append("reqType", data.reqPath)
    }
    window.open(url.toString(), "_blank");
  };

  //filtering data
  const filteredData = trialData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on company Name and company email
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchTrialData();
  }, []);
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Profile/ Marketing Demo Request"
              backUrl="/master-marketing-interest"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By company name/ company email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Date
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Company Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Contact Details
                    </th>

                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Country / Pincode
                    </th>

                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      How Can We Help You
                    </th>
                 
                    <th
                      style={{
                        fontSize: "14px",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => (
                      <tbody className="text-center">
                        <tr key="index">
                          <td>
                            {filteredData.length - indexOfFirstItem - index}.
                          </td>
                          <td>
                            <h6 className="text-success text-nowrap">
                              <div className="text-dark">
                                RequestOrigin : {data.reqPath}
                              </div>
                              Created At:{" "}
                              {formatDateTimeStandard(data.createdAt)} <br />
                              {data.registerTimeStamp && (
                                <div className="text-info">
                                  Registered At:{" "}
                                  {formatDateAndTime(data.registerTimeStamp)}
                                </div>
                              )}
                            </h6>
                          </td>
                          <td className="text-nowrap">
                            <div>{data.companyName}</div>
                            <div>{data.inputNumber}</div>
                          </td>
                          <td className="text-nowrap">
                            <div>
                              Name: {data.firstName} {data.lastName}
                            </div>
                            <div>email: {data.email}</div>
                            <div>Phone: {data.phoneNo}</div>
                          </td>
                          <td>
                            {data.b2bCountry && (
                              <div>{data.b2bCountry.join(", ")}</div>
                            )}
                            {data.country && <div>{data.country}</div>}
                            {data.pinCode && <div>{data.pinCode}</div>}
                          </td>
                          <td>
                          <div>
                                  <TruncatedText
                                    text={`${data.userMessage}`}
                                    maxChars={20}
                                  />
                                </div>
                          </td>
                          <td>
                            <>
                              {data.isApproved === "Approved" && (
                                <Button
                                  className="btn-sm"
                                  onClick={() => handleRegisterClick(data)}
                                >
                                  Register Now
                                </Button>
                              )}
                              <h6
                                className={
                                  data.isApproved === "Rejected"
                                    ? "fw-semibold text-danger"
                                    : "fw-semibold text-success"
                                }
                              >
                                {data.isApproved}
                              </h6>

                            

                              {data.isApproved === "Approved" ||
                              data.isApproved === "Rejected" ? (
                                <>
                                  <h6>
                                    Remark :{" "}
                                    <span className="text-secondary">
                                      {console.log(data)}
                                      {data.remark}
                                    </span>
                                  </h6>
                                </>
                                
                              ) : (
                                <>
                                  <div className="max-content">
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      rows={3}
                                      maxLength={50}
                                      onChange={(e) =>
                                        handleInputChange(
                                          data._id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      value={data.remark}
                                      name="remark"
                                    />

                                    {data.remark && (
                                      <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                        {appDisApi ? (
                                          <Spinner animation="border text-success" />
                                        ) : (
                                          <>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <AiFillCheckCircle
                                                className="w-100 icon-size green-color"
                                                onClick={() =>
                                                  handleApproveRejectTrialUser(
                                                    data._id,
                                                    true
                                                  )
                                                }
                                              />
                                            </Button>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <AiFillCloseCircle
                                                className="w-100 icon-size text-danger"
                                                onClick={() =>
                                                  handleApproveRejectTrialUser(
                                                    data._id,
                                                    false
                                                  )
                                                }
                                              />
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={11} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};




const TargetHistory = ({ showModal, handleClose, data }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedTarget, setEditedTarget] = useState(null);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedTarget({ ...data.targetOne[index] });
  };

  const handleInputChange = (e) => {
    setEditedTarget({
      ...editedTarget,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(
        getURLbyEndPointV2("updateB2bHistory") + `${data.targetOne[editingIndex]._id}`,
        {
          targetType: editedTarget.targetType,
          action: editedTarget.action,
          targetRemark: editedTarget.targetRemark,
        }
      );

      if (response.status === 200) {
        setEditingIndex(null);
        setEditedTarget(null);
        handleClose();
        alert("Target value changed!")
        window.location.reload()
      } else {
        console.error("Failed to update");
        window.location.reload()
      }
    } catch (error) {
      console.error("Error updating value:", error);
      window.location.reload()
    }
  };

  const containerStyle = {
    width: '100%',
  };

  const itemStyle = {
    position: 'relative',
    marginBottom: '1.5rem',
    padding: '1rem',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f8f9fa',
  };

  const badgeStyle = {
    backgroundColor: '#17a2b8',
    color: 'white',
    borderRadius: '4px',
    padding: '0.2rem 0.5rem',
    marginRight: '0.5rem',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  };

  const successTextStyle = {
    color: 'green',
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Marketing Target (MKT1)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={containerStyle}>
          {data.targetOne && data.targetOne.length > 0 ? (
            <div className="target-one-list text-left mt-2">
              {data.targetOne.map((target, idx) => (
                <div key={idx} style={itemStyle} className="target-item">
                  <div style={headerStyle}>
                    <div className="font-weight-bold">
                      {idx + 1}: {target.targetType}, {target.action}
                    </div>
                    <div>
                      <Button
                        size="sm"
                        disabled={data.isTargetOneComplete}
                        variant="secondary"
                        className="ml-2 rounded-circle"
                        onClick={() => handleEditClick(idx)}
                      >
                        <FaEdit />
                      </Button>
                    </div>
                  </div>
                  {editingIndex === idx ? (
                    <div>
                      <Row>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Target Type</Form.Label>
                            <Form.Control
                              as="select"
                              name="targetType"
                              value={editedTarget.targetType}
                              onChange={handleInputChange}
                            >
                              <option value="Cold Calling">Cold Calling</option>
                              <option value="Email confirmation">Email confirmation</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Action</Form.Label>
                            <Form.Control
                              as="select"
                              name="action"
                              value={editedTarget.action}
                              onChange={handleInputChange}
                            >
                              <option value="interest">Interest</option>
                              <option value="intro">Intro</option>
                              <option value="event info">Event Info</option>
                              <option value="charges">Charges</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group>
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          type="text"
                          name="targetRemark"
                          value={editedTarget.targetRemark}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <div className="d-flex gap-3">
                        <Button variant="primary" onClick={handleSaveChanges}>
                          Save
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setEditingIndex(null);
                            setEditedTarget(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2">
                      <span style={badgeStyle}>Remark</span>: {target.targetRemark}
                    </div>
                  )}
                  <div style={successTextStyle}>
                    {formatDateAndTime(target.updatedAt)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No history available.</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



function RejectConfirmationModal({
  show,
  handleClose,
  handleApproveRejectTrialUser,
  data,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="text-end">
        <CloseButton onClick={handleClose} className="m-3 mb-0 pb-0" />
      </div>

      <Modal.Body className="pt-0">
        <div className="text-center">
          <MdCancel style={{ color: "#0b3b5d", fontSize: "8rem" }} />

          <h5 className="text-info mb-4 mt-2">
            Are you sure you want to Reject?
          </h5>
          <Form.Group>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Enter a Remark"
              rows={3}
              maxLength={50}
              name="remark"
            />
          </Form.Group>
          <div className="d-flex justify-content-center gap-3 mt-2">
            <Button
              variant="success"
              className="px-5"
              onClick={() => handleApproveRejectTrialUser(data._id, false, "Reject")}
            >
              Reject
            </Button>
            <Button variant="danger" className="px-5" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function CompleteConfirmationModal({
  show,
  handleClose,
  handleComplete,
  message,
  data,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="text-end">
        <CloseButton onClick={handleClose} className="m-3 mb-0 pb-0" />
      </div>

      <Modal.Body className="pt-0">
        <div className="text-center">
          <IoCheckmarkDoneCircle
            style={{ color: "#0b3b5d", fontSize: "8rem" }}
          />

          <h5 className="text-info mb-4 mt-2">{message}</h5>

          <div className="d-flex justify-content-center gap-3">
            <Button
              variant="success"
              className="px-5"
              onClick={() => handleComplete(data._id, false)}
            >
              Yes
            </Button>
            <Button variant="danger" className="px-5" onClick={handleClose}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}



export default MarketingDemo;
