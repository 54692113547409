import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import {
  Form,
  Table,
  Button,
  Pagination,
  Spinner,
  Modal,
  CloseButton,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { getURLbyEndPointV2 } from "../../../store/api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { formatDateAndTime } from "../../../components/basic/TimeAndDate";
import { TruncatedText } from "../../../components/basic/textcustomized";
import { FaEdit, FaHistory, FaRegClock } from "react-icons/fa";
import { BsSkipForwardFill } from "react-icons/bs";
import { IoMdDoneAll } from "react-icons/io";
import { GiCancel } from "react-icons/gi";
import { FaCircleExclamation } from "react-icons/fa6";
import { GoHistory } from "react-icons/go";
import { MdCancel, MdSend } from "react-icons/md";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import axios from "axios";

export const B2BEvents = () => {
  const [b2bEventsData, setB2bEventsData] = useState([]);
  const [appDisApi, setAppDisApi] = useState(false);
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [activeRowId, setActiveRowId] = useState(null);
  const [modalState, setModalState] = useState({
    activeModal: null,
  });

  const handleShow = (modalName, rowData) => {
    setSelectedRowData(rowData);
    setModalState({ activeModal: modalName });
  };

  const handleClose = () => {
    setModalState({ activeModal: null });
    setSelectedRowData(null);
  };

  const handleShowTargetRemark = (id) => {
    setActiveRowId(activeRowId === id ? null : id);
  };

  const handleShowIndividualModal = (rowData) => {
    setSelectedRowData(rowData);
    setModalState({ ...modalState, showIndividualModal: true });
  };

  const handleCloseIndividualModal = () => {
    setModalState({ ...modalState, showIndividualModal: false });
    setSelectedRowData(null);
  };

  const handleShowSponsorShipModal = (rowData) => {
    setSelectedRowData(rowData);
    setModalState({ ...modalState, showSponsorshipModal: true });
  };

  const handleCloseSponsorModal = () => {
    setModalState({ ...modalState, showSponsorshipModal: false });
    setSelectedRowData(null);
  };

  const handleInputChange = (itemId, name, value) => {
    setB2bEventsData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });

    setSelectedRowData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTargetOneComplete = async (id) => {
    try {
      const res = await axios.put(
        getURLbyEndPointV2("updateTargetOneComplete") + id,
        { isTargetOneComplete: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleTargetOneSkip = async (id) => {
    try {
      const res = await axios.put(
        getURLbyEndPointV2("updateTargetOneComplete") + id,
        { isTargetOneComplete: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert("Target One Skipped!");
        window.location.reload();
      } else {
        console.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleTargetTwoSkip = async (id) => {
    try {
      const res = await axios.put(
        getURLbyEndPointV2("updateTargetTwoComplete") + id,
        { isTargetTwoComplete: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert("Target Two Skipped!");
        window.location.reload();
      } else {
        console.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleTargetTwoComplete = async (id) => {
    try {
      const res = await axios.put(
        getURLbyEndPointV2("updateTargetTwoComplete") + id,
        { isTargetTwoComplete: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleTargetMessageSend = async (id) => {
    const rowData = b2bEventsData.find((item) => item._id === id);
    if (!rowData) {
      alert("Data not found for the specified row.");
      return;
    }
    const { targetType, action, targetRemark } = rowData;
    const clientId = rowData._id;
    if (!targetType || !action || !targetRemark) {
      alert("Please fill out all fields before submitting.");
      return;
    }
    const formData = new FormData();
    formData.append("targetType", targetType);
    formData.append("action", action);
    formData.append("targetRemark", targetRemark);
    try {
      const res = await axios.post(
        getURLbyEndPointV2("createB2BTarget") + clientId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Target created successfully");
      window.location.reload();
    } catch (error) {
      console.log("Error in creating Target", error);
      alert("Error while creating Target");
    }
  };

  const handleTargetTwoMessageSend = async (id) => {
    const rowData = b2bEventsData.find((item) => item._id === id);
    if (!rowData) {
      alert("Data not found for the specified row.");
      return;
    }
    const { targetType, action, targetRemark } = rowData;
    if (!targetType || !action || !targetRemark) {
      alert("Please fill out all fields before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("targetType", targetType);
    formData.append("action", action);
    formData.append("targetRemark", targetRemark);
    try {
      const res = await axios.post(
        getURLbyEndPointV2("createB2BTargetTwo") + id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Target created successfully", res);
      alert("Target created successfully");
      window.location.reload();
    } catch (error) {
      console.log("Error in creating Target", error);
      alert("Error while creating Target");
      window.location.reload();
    }
  };

  const approveRejectB2b = async (userId, status, reqType) => {
    const clientData = b2bEventsData.find((user) => user._id === userId);
    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectB2b") + userId,
        { status, remark: "Marketing Request Sent", reqType },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert("Error while handling b2b data.");
      }
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const approveRejectIndividual = async (userId, status) => {
    const clientData = b2bEventsData.find((user) => user._id === userId);
    // Validate remark
    if (!clientData.remark || clientData.remark.trim() === "") {
      alert("Remark is required.");
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectB2bIndividual") + userId,
        { status, remark: clientData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };

  const approveRejectSponsorship = async (userId, status) => {
    const clientData = b2bEventsData.find((user) => user._id === userId);
    if (!clientData.remark || clientData.remark.trim() === "") {
      alert("Remark is required.");
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPointV2("approveRejectB2bSponsorship") + userId,
        { status, remark: clientData.remark },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  };



  const handleSubmitWelcomeMail = async (userId) =>{
    const rowData = b2bEventsData.find((item) => item._id === userId);
    try {
      const res = await axios.post(
        getURLbyEndPointV2("handleSendWelcome") + userId,
        { remark: "Thank you for your request. Our team will reach out to you shortly." },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling event data.");
      console.error("Error:", error);
    } finally {
      window.location.reload();
    }
  }


  const fetchB2bData = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPointV2("getAllB2bEventRequest")
      );
      if (response.status === 200) {
        const data = response.data.data;

        // Add properties to each object in the data.data array
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
            };
          } else if (item.isApproved === "Approved") {
            return {
              ...item,
            };
          }
          return item;
        });

        setB2bEventsData([...modifiedData]);
        // console.log("result", modifiedData);
      } else {
        alert("Failed to fetch trial user data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  //filtering data
  const filteredData = b2bEventsData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on company Name and company email
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchB2bData();
  }, []);

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="EVENT / AECCI B2B EVENT 2024"
              backUrl="/b2b-virtual-forum"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
                <Form.Check
                  inline
                  label="Registered"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Registered"}
                  onChange={() => setReqStatus("Registered")}
                />
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By company name/ company email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Date / Logs
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Company Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Selected Countries
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Contact Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Target 1 <span>( Marketing executive )</span>
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Target 2 <span>( Executive Director )</span>
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <>
                  {currentFiltered.length > 0 ? (
                    <>
                      {currentFiltered.map((data, index) => (
                        <tbody className="text-center" key={data._id}>
                          <tr>
                            <td>
                              {filteredData.length - indexOfFirstItem - index}.
                            </td>
                            <td>
                              <div className="pb-2">
                                <Badge
                                  pill
                                  className="p-2"
                                  bg={
                                    data.reqPath === "B2BePlatform"
                                      ? "success"
                                      : "info"
                                  }
                                >
                                  {data.reqPath === "B2BePlatform"
                                    ? "Existing Client"
                                    : "New Client"}
                                </Badge>
                              </div>
                              <div className="text-nowrap">
                                Request Origin :{" "}
                                <span className="text-info fw-bold">
                                  {data.reqPath === "B2BePlatform"
                                    ? "e-Platform"
                                    : "B2B interest form"}
                                </span>
                              </div>
                              <div className="text-nowrap pt-2">
                                Created At :{" "}
                                <span className="fw-semibold text-success">
                                  {formatDateTimeStandard(data.createdAt)}
                                </span>
                              </div>
                              {data.isApproved === "Registered" && (
                                <div className="text-nowrap pt-2">
                                  Registered At :{" "}
                                  <span className="fw-semibold text-success">
                                    {formatDateAndTime(data.registerTimeStamp)}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="text-nowrap fw-semibold">
                                Type: {data.entity}
                              </div>
                              <div className="text-nowrap">
                                {data.entity === "Business" ? (
                                  <>
                                    <div>Company : {data.companyName}</div>
                                    <div>CIN : {data.cinNumber}</div>
                                  </>
                                ) : (
                                  <div>Profession : {data.profession}</div>
                                )}
                                <div>Email : {data.email}</div>
                              </div>
                            </td>
                            <td>
                              <div className="text-nowrap text-primary fw-semibold cursor-pointer">
                                No of country: {data.country.length}
                              </div>
                              <div>{data.country.join(", ")}</div>
                            </td>
                            <td>
                              <div className="text-nowrap">
                                <div> Name: {data.name}</div>
                                <div> Phone: {data.phoneNo}</div>
                                <div>
                                  Contact Time: {data.contactTimeFrom} to{" "}
                                  {data.contactTimeTo}{" "}
                                </div>
                                <div>
                                  <TruncatedText
                                    text={`Purpose: ${data.userMessage}`}
                                    maxChars={30}
                                  />
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "max-content" }}>
                              <div
                                style={{
                                  width: "300px",
                                  maxWidth: "max-content",
                                }}
                              >
                                <div className="d-flex gap-2">
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <Button
                                      size="sm"
                                      style={{
                                        position: "relative",
                                        color: "#333333",
                                        background: "#dddddd",
                                      }}
                                      className="fw-semibold px-3"
                                      onClick={() =>
                                        handleShow("targetOne", data)
                                      }
                                    >
                                      <GoHistory /> Target history
                                      <Badge
                                        bg="info"
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          transform: "translate(50%, -50%)",
                                          padding: "0.25em 0.5em",
                                          fontSize: "0.8em",
                                          borderRadius: "10rem",
                                        }}
                                      >
                                        {data.targetOne.length}
                                      </Badge>
                                    </Button>
                                  </div>

                                  <div>
                                    <Button
                                      variant="secondary"
                                      size="sm"
                                      className="px-2"
                                      disabled={data.isTargetOneComplete}
                                      onClick={() =>
                                        handleShowTargetRemark(data._id)
                                      }
                                    >
                                      {" "}
                                      +{" "}
                                    </Button>
                                  </div>
                                </div>
                                <div>
                                  {!data.isTargetOneComplete &&
                                    activeRowId === data._id && (
                                      <div className="mt-2">
                                        <div className="d-flex gap-2 align-items-center">
                                          <div>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                as="select"
                                                name="targetType"
                                                value={data.targetType}
                                                style={{ width: "90px" }}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    data._id,
                                                    e.target.name,
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select...
                                                </option>
                                                <option value="Cold Calling">
                                                  Cold Calling
                                                </option>
                                                <option value="Email confirmation">
                                                  Email confirmation
                                                </option>
                                              </Form.Control>
                                            </Form.Group>
                                          </div>
                                          <div>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                as="select"
                                                name="action"
                                                style={{ width: "90px" }}
                                                value={data.action}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    data._id,
                                                    e.target.name,
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select...
                                                </option>
                                                <option value="interest">
                                                  Interest
                                                </option>
                                                <option value="intro">
                                                  Intro
                                                </option>
                                                <option value="event info">
                                                  Event Info
                                                </option>
                                                <option value="charges">
                                                  Charges
                                                </option>
                                              </Form.Control>
                                            </Form.Group>
                                          </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Remark"
                                            name="targetRemark"
                                            maxLength={80}
                                            style={{ width: "150px" }}
                                            value={data.targetRemark}
                                            onChange={(e) =>
                                              handleInputChange(
                                                data._id,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <Button
                                            className="m-0 p-0 border-none"
                                            disabled={!data.targetOne}
                                            style={{
                                              backgroundColor: "transparent",
                                              boxShadow: "none",
                                            }}
                                            onClick={() =>
                                              handleTargetMessageSend(data._id)
                                            }
                                          >
                                            <MdSend
                                              className={`fs-3 text-white bg-primary rounded p-2`}
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className="d-flex gap-2 my-2">
                                  <div>
                                    <Button
                                      size="sm"
                                      onClick={() => handleShow("reject", data)}
                                      disabled={
                                        data.isApproved === "Approved" ||
                                        data.isTargetOneComplete
                                      }
                                      variant="danger"
                                    >
                                      <GiCancel /> Reject
                                    </Button>
                                    <RejectConfirmationModal
                                      show={modalState.activeModal === "reject"}
                                      handleClose={handleClose}
                                      data={selectedRowData}
                                      approveRejectB2b={approveRejectB2b}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      size="sm"
                                      onClick={() =>
                                        handleShow("completeOne", data)
                                      }
                                      disabled={data.isTargetOneComplete}
                                      variant="primary"
                                    >
                                      <IoMdDoneAll />{" "}
                                      {!data.isTargetOneComplete
                                        ? "Complete"
                                        : "Completed"}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <CompleteConfirmationModal
                                show={modalState.activeModal === "completeOne"}
                                handleClose={handleClose}
                                handleComplete={handleTargetOneComplete}
                                message="Are you sure you want to Complete Target One?"
                                data={selectedRowData}
                              />

                              <CompleteConfirmationModal
                                show={modalState.activeModal === "completeTwo"}
                                handleClose={handleClose}
                                handleComplete={handleTargetTwoComplete}
                                message="Are you sure you want to Complete Target Two?"
                                data={selectedRowData}
                              />
                            </td>
                            <td style={{ width: "max-content" }}>
                              <div
                                style={{
                                  width: "300px",
                                  maxWidth: "max-content",
                                }}
                              >
                                {data.isTargetOneComplete ? (
                                  <>
                                    <div className="d-flex gap-2">
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <Button
                                          size="sm"
                                          style={{
                                            position: "relative",
                                            color: "#333333",
                                            background: "#dddddd",
                                          }}
                                          className="fw-semibold px-3"
                                          onClick={() =>
                                            handleShow("targetTwo", data)
                                          }
                                        >
                                          <GoHistory /> Target history
                                          <Badge
                                            bg="info"
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                              transform: "translate(50%, -50%)",
                                              padding: "0.25em 0.5em",
                                              fontSize: "0.8em",
                                              borderRadius: "10rem",
                                            }}
                                          >
                                            {data.targetTwo.length}
                                          </Badge>
                                        </Button>
                                      </div>

                                      <div>
                                        <Button
                                          variant="secondary"
                                          size="sm"
                                          className="px-2"
                                          disabled={data.isTargetTwoComplete}
                                          onClick={() =>
                                            handleShowTargetRemark(data._id)
                                          }
                                        >
                                          {" "}
                                          +{" "}
                                        </Button>
                                      </div>
                                    </div>
                                    <div>
                                      {activeRowId === data._id && (
                                        <div className="mt-2">
                                          <div className="d-flex gap-2 align-items-center">
                                            <div>
                                              <Form.Group className="mb-2">
                                                <Form.Control
                                                  as="select"
                                                  name="targetType"
                                                  value={data.targetType}
                                                  style={{ width: "90px" }}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      data._id,
                                                      e.target.name,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select...
                                                  </option>
                                                  <option value="Cold Calling">
                                                    Cold Calling
                                                  </option>
                                                  <option value="Email confirmation">
                                                    Email confirmation
                                                  </option>
                                                </Form.Control>
                                              </Form.Group>
                                            </div>
                                            <div>
                                              <Form.Group className="mb-2">
                                                <Form.Control
                                                  as="select"
                                                  name="action"
                                                  style={{ width: "90px" }}
                                                  value={data.action}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      data._id,
                                                      e.target.name,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select...
                                                  </option>
                                                  <option value="interest">
                                                    Interest
                                                  </option>
                                                  <option value="intro">
                                                    Intro
                                                  </option>
                                                  <option value="event info">
                                                    Event Info
                                                  </option>
                                                  <option value="charges">
                                                    Charges
                                                  </option>
                                                </Form.Control>
                                              </Form.Group>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-2">
                                            <Form.Control
                                              type="text"
                                              placeholder="Enter Remark"
                                              name="targetRemark"
                                              maxLength={80}
                                              style={{ width: "150px" }}
                                              value={data.targetRemark}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  data._id,
                                                  e.target.name,
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <Button
                                              className="m-0 p-0 border-none"
                                              disabled={
                                                data.isTargetTwoComplete
                                              }
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                              onClick={() =>
                                                handleTargetTwoMessageSend(
                                                  data._id
                                                )
                                              }
                                            >
                                              <MdSend
                                                className={`fs-3 text-white bg-primary rounded p-2`}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex gap-2 my-2">
                                      <div>
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            handleShow("reject", data)
                                          }
                                          disabled={
                                            data.isApproved === "Approved" ||
                                            data.isTargetTwoComplete
                                          }
                                          variant="danger"
                                        >
                                          <GiCancel /> Reject
                                        </Button>
                                      </div>
                                      <div>
                                        <Button
                                          size="sm"
                                          onClick={() =>
                                            handleShow("completeTwo", data)
                                          }
                                          disabled={data.isTargetTwoComplete}
                                          variant="primary"
                                        >
                                          <IoMdDoneAll />{" "}
                                          {!data.isTargetTwoComplete
                                            ? "Complete"
                                            : "Completed"}
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <h6>Target One is not complete</h6>
                                )}
                              </div>
                            </td>

                            <td>
                              <div
                                style={{
                                  width: "300px",
                                  maxWidth: "max-content",
                                }}
                              >
                                <h6
                                  className={
                                    data.isApproved === "Rejected"
                                      ? "fw-semibold text-danger"
                                      : "fw-semibold text-success"
                                  }
                                >
                                  {data.isApproved}
                                </h6>

                                <>
                                  {data.remark && (
                                    <h6>
                                      Remark :{" "}
                                      <span className="text-secondary">
                                        {data.remark}
                                      </span>
                                    </h6>
                                  )}
                                </>
                                {data.isApproved === "Registered" && (
                                  <div>
                                       <Button
                                          className="mt-2"
                                          size="sm"
                                          // onClick={() =>
                                          //   handleShowIndividualModal(data)
                                          // }
                                        >
                                          payment Request
                                        </Button>
                                  </div>
                                )}
                                {data.isTargetOneComplete &&
                                  data.isTargetTwoComplete &&
                                  data.isApproved !== "Rejected" && (
                                    <div>
                                      <>
                                        <Button
                                          className="mt-2"
                                          size="sm"
                                          // onClick={() =>
                                          //   handleShowIndividualModal(data)
                                          // }
                                        >
                                          Send Membership Invitation
                                        </Button>

                                        <Modal
                                          show={modalState.showIndividualModal}
                                          onHide={handleCloseIndividualModal}
                                        >
                                          <div className="text-end">
                                            <CloseButton
                                              onClick={
                                                handleCloseIndividualModal
                                              }
                                              className="m-3 mb-0 pb-0"
                                            />
                                          </div>
                                          <Modal.Body className="pt-0">
                                            <div className="text-center">
                                              <IoCheckmarkDoneCircle
                                                style={{
                                                  color: "#0b3b5d",
                                                  fontSize: "8rem",
                                                }}
                                              />
                                              <h5 className="text-info mb-4 mt-2">
                                                Are you sure?
                                              </h5>
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  type="text"
                                                  placeholder="Enter a Remark"
                                                  rows={3}
                                                  maxLength={50}
                                                  name="remark"
                                                  value={
                                                    selectedRowData?.remark ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      selectedRowData._id,
                                                      e.target.name,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              <div className="d-flex mt-2 justify-content-center gap-3">
                                                <Button
                                                  variant="success"
                                                  className="px-5"
                                                  onClick={() =>
                                                    approveRejectIndividual(
                                                      selectedRowData._id,
                                                      true
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  className="px-5"
                                                  onClick={() =>
                                                    approveRejectIndividual(
                                                      selectedRowData._id,
                                                      false
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </Button>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </>

                                      <>
                                        <Button
                                          className="mt-2"
                                          size="sm"
                                          // onClick={() =>
                                          //   handleShowSponsorShipModal(data)
                                          // }
                                        >
                                          Send Sponsorship Proposal
                                        </Button>
                                        <Modal
                                          show={modalState.showSponsorshipModal}
                                          onHide={handleCloseSponsorModal}
                                        >
                                          <div className="text-end">
                                            <CloseButton
                                              onClick={handleCloseSponsorModal}
                                              className="m-3 mb-0 pb-0"
                                            />
                                          </div>
                                          <Modal.Body className="pt-0">
                                            <div className="text-center">
                                              <IoCheckmarkDoneCircle
                                                style={{
                                                  color: "#0b3b5d",
                                                  fontSize: "8rem",
                                                }}
                                              />
                                              <h5 className="text-info mb-4 mt-2">
                                                Are you sure?
                                              </h5>
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  type="text"
                                                  placeholder="Enter a Remark"
                                                  rows={3}
                                                  maxLength={50}
                                                  name="remark"
                                                  value={
                                                    selectedRowData?.remark ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      selectedRowData._id,
                                                      e.target.name,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              <div className="d-flex mt-2 justify-content-center gap-3">
                                                <Button
                                                  variant="success"
                                                  className="px-5"
                                                  onClick={() =>
                                                    approveRejectSponsorship(
                                                      selectedRowData._id,
                                                      true
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  className="px-5"
                                                  onClick={() =>
                                                    approveRejectSponsorship(
                                                      selectedRowData._id,
                                                      false
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </Button>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </>
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={11} className="text-center">
                          No Matching Data
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {selectedRowData && (
                    <>
                      <TargetHistoryModal
                        show={modalState.activeModal === "targetOne"}
                        handleClose={handleClose}
                        data={selectedRowData}
                        formatDateAndTime={formatDateAndTime}
                        targetType="targetOne"
                        approveRejectB2b={approveRejectB2b}
                        handleSubmitWelcomeMail={handleSubmitWelcomeMail}
                      />

                      {selectedRowData.targetTwo &&
                        selectedRowData.targetTwo.length > 0 && (
                          <TargetHistoryModal
                            show={modalState.activeModal === "targetTwo"}
                            handleClose={handleClose}
                            data={selectedRowData}
                            formatDateAndTime={formatDateAndTime}
                            targetType="targetTwo"
                          />
                        )}
                    </>
                  )}
                </>
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

const TargetHistoryModal = ({
  show,
  handleClose,
  data,
  targetType,
  approveRejectB2b,
  handleSubmitWelcomeMail,
}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedTarget, setEditedTarget] = useState(null);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedTarget({ ...data[targetType][index] });
  };

  const handleInputChange = (e) => {
    setEditedTarget({
      ...editedTarget,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(
        getURLbyEndPointV2("updateB2bHistory") +
          `${data[targetType][editingIndex]._id}`,
        {
          targetType: editedTarget.targetType,
          action: editedTarget.action,
          targetRemark: editedTarget.targetRemark,
        }
      );

      if (response.status === 200) {
        setEditingIndex(null);
        setEditedTarget(null);
        handleClose();
        alert("Target value changed!");
        window.location.reload();
      } else {
        console.error("Failed to update");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating value:", error);
      window.location.reload();
    }
  };

  const containerStyle = {
    width: "100%",
  };

  const itemStyle = {
    position: "relative",
    marginBottom: "1.5rem",
    padding: "1rem",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f8f9fa",
  };

  const badgeStyle = {
    backgroundColor: "#17a2b8",
    color: "white",
    borderRadius: "4px",
    padding: "0.2rem 0.5rem",
    marginRight: "0.5rem",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
  };

  const successTextStyle = {
    color: "green",
  };

  const titleMap = {
    targetOne: "Marketing Target (MKT1)",
    targetTwo: "Executive Director(ED)",
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h5>{titleMap[targetType]}</h5>
            {targetType === "targetOne" && (
              <div className="d-flex gap-2 mt-3">
                {data.reqPath === "B2BInterest" ? (
                  <Button
                    variant="primary"
                    size="sm"
                    disabled={
                      data.isApproved !== "Processing" ||
                      data.isTargetOneComplete
                    }
                    onClick={() =>
                      approveRejectB2b(data._id, true, "Marketing")
                    }
                  >
                    Send Marketing Request
                  </Button>
                ) : (
                  <Button variant="primary" size="sm" onClick={()=>handleSubmitWelcomeMail(data._id)}>
                    Send Welcome mail
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="text-right text-sm">
            <div>
              <strong>{data.name}</strong>
            </div>
            <div>{data.companyName ? data.companyName : data.profession}</div>
            <div>{data.email}</div>
            <div>+91 {data.phoneNo}</div>
            <div className="d-flex align-items-center text-right justify-content-end text-info">
              <FaRegClock /> {data.contactTimeFrom} - {data.contactTimeTo}{" "}
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={containerStyle}>
          {data[targetType] && data[targetType].length > 0 ? (
            <div className="target-one-list text-left mt-2">
              {data[targetType].map((target, idx) => (
                <div key={idx} style={itemStyle} className="target-item">
                  <div style={headerStyle}>
                    <div className="font-weight-bold">
                      {idx + 1}: {target.targetType}, {target.action}
                    </div>
                    <div>
                      <Button
                        size="sm"
                        disabled={
                          data[
                            `is${
                              targetType.charAt(0).toUpperCase() +
                              targetType.slice(1)
                            }Complete`
                          ]
                        }
                        variant="secondary"
                        className="ml-2 rounded-circle"
                        onClick={() => handleEditClick(idx)}
                      >
                        <FaEdit />
                      </Button>
                    </div>
                  </div>
                  {editingIndex === idx ? (
                    <div>
                      <Row>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Target Type</Form.Label>
                            <Form.Control
                              as="select"
                              name="targetType"
                              value={editedTarget.targetType}
                              onChange={handleInputChange}
                            >
                              <option value="Cold Calling">Cold Calling</option>
                              <option value="Email confirmation">
                                Email confirmation
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Action</Form.Label>
                            <Form.Control
                              as="select"
                              name="action"
                              value={editedTarget.action}
                              onChange={handleInputChange}
                            >
                              <option value="interest">Interest</option>
                              <option value="intro">Intro</option>
                              <option value="event info">Event Info</option>
                              <option value="charges">Charges</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group>
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          type="text"
                          name="targetRemark"
                          value={editedTarget.targetRemark}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <div className="d-flex gap-3">
                        <Button variant="primary" onClick={handleSaveChanges}>
                          Save
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setEditingIndex(null);
                            setEditedTarget(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2">
                      <span style={badgeStyle}>Remark</span>:{" "}
                      {target.targetRemark}
                    </div>
                  )}
                  <div style={successTextStyle}>
                    {formatDateAndTime(target.updatedAt)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No history available.</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function RejectConfirmationModal({
  show,
  handleClose,
  approveRejectB2b,
  data,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="text-end">
        <CloseButton onClick={handleClose} className="m-3 mb-0 pb-0" />
      </div>

      <Modal.Body className="pt-0">
        <div className="text-center">
          <MdCancel style={{ color: "#0b3b5d", fontSize: "8rem" }} />

          <h5 className="text-info mb-4 mt-2">
            Are you sure you want to Reject?
          </h5>
          <Form.Group>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Enter a Remark"
              rows={3}
              maxLength={50}
              name="remark"
            />
          </Form.Group>
          <div className="d-flex justify-content-center gap-3 mt-2">
            <Button
              variant="success"
              className="px-5"
              onClick={() => approveRejectB2b(data._id, false, "Reject")}
            >
              Reject
            </Button>
            <Button variant="danger" className="px-5" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function CompleteConfirmationModal({
  show,
  handleClose,
  handleComplete,
  message,
  data,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="text-end">
        <CloseButton onClick={handleClose} className="m-3 mb-0 pb-0" />
      </div>

      <Modal.Body className="pt-0">
        <div className="text-center">
          <IoCheckmarkDoneCircle
            style={{ color: "#0b3b5d", fontSize: "8rem" }}
          />

          <h5 className="text-info mb-4 mt-2">{message}</h5>

          <div className="d-flex justify-content-center gap-3">
            <Button
              variant="success"
              className="px-5"
              onClick={() => handleComplete(data._id, false)}
            >
              Yes
            </Button>
            <Button variant="danger" className="px-5" onClick={handleClose}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
