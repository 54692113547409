import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Form,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { FaAngleDown, FaAngleRight, FaPen } from "react-icons/fa";
import invitationLetter from "../../../assets/invitationLetter.jpg";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { getURLbyEndPoint } from "../../../store/api";

import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import ArbitrationCertificate from "../../../assets/membershipCertTemplate/ArbitrationCertificate.png";
import collaborationCert from "../../../assets/membershipCertTemplate/CollaborationCertificate.jpg";
import membCertCorpplus from "../../../assets/membershipCertTemplate/membershipCertCoporate+.jpg";
import membCertCorp from "../../../assets/membershipCertTemplate/membershipCertCoporate.jpg";
import membCertSmall from "../../../assets/membershipCertTemplate/membershipCertSmallBuissness.jpg";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { bankNames } from "../../../store/AllListData";

import {
  AiFillCheckCircle,
  AiFillCheckSquare,
  AiFillCloseCircle,
  AiFillCloseSquare,
} from "react-icons/ai";
import FileViewModal from "../../../components/basic/fileViewModal";
import { breakAddressLinesForTextArea } from "../../../components/basic/textcustomized";
import { getAuthUserCookie } from "../../../store/services";

const ClientAdmin = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [isApproved, setIsApproved] = useState("");
  const [userTypeExisting, setUserTypeExisting] = useState(false);
  const [userTypeTrial, setUserTypeTrial] = useState(false);
  const [userTypeCollaboration, setUserTypeCollaboration] = useState(getAuthUserCookie().accessList.includes("CollaborationAccessControl"));
  const [userTypeArbitrator, setUserTypeArbitrator] = useState(false);
  // Function to open the modal and set certificate URL
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [file, setFile] = useState(null);

  const openModal = (title, file) => {
    setModalTitle(title);
    setFile(file);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  // //for valid upto setup-----------------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  const options = [
    { label: "7 days", value: 7 },
    { label: "14 days", value: 14 },
    { label: "1 month", value: 30 },
    { label: "2 months", value: 60 },
    { label: "3 months", value: 90 },
  ];

  const [expandedCategories, setExpandedCategories] = useState({
    Services: false,
    Wings: false,
  });
  const [approveUserData, setApproveUserData] = useState({
    Profile: false,
    Services: {
      "e-CO": false,
      "Membership Services": false,
      "Recommendation Letters": false,
      "Collaboration Portal": false,
      "Commercial Directory": false,
      Wallet: false,
    },

    "The Wings": {
      "Export Wing": false,
      "Legal Wing": false,
      "HR Support Wings": false,
      "Business Advice Wing": false,
      "Professional Wing": false,
      "Event & Seminar Wing": false,
    },
    "Arbitration Center": false,
    Events: false,
    Publications: false,
  });

  const toggleCategory = (userId, category) => {
    setClientData((prevClientData) => {
      const newClientData = [...prevClientData];
      const userIndex = newClientData.findIndex(
        (userData) => userData._id === userId
      );

      if (userIndex !== -1) {
        newClientData[userIndex].expandedCategories[category] =
          !newClientData[userIndex].expandedCategories[category];
      }

      return newClientData;
    });
  };

  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    companyName: "",
    inputNumber: "",
    websiteAdd: "",
    address1: "",
    email: "",
    password: "",
    registeredAccountNo: "",
    registeredBank: "",
    IFSCCode: "",
    // Add more fields as needed
    isEditingCompany: false,
    isEditingYou: false,
    isEditingBank: false,
  });

  //all editing function from superadmin is here-------------
  // ------------------------------------------------------------
  // -------------------------------------------------------------
  // ------------------------------------------------------------
  const handleEditClick = (id, field) => {
    setClientData((prevClients) =>
      prevClients.map((user) =>
        user._id === id ? { ...user, [field]: !user[field] } : user
      )
    );
  };

  // field change function for
  const handleInputChange = (id, field, e) => {
    setClientData((prevClients) =>
      prevClients.map((user) =>
        user._id === id ? { ...user, [field]: e.target.value } : user
      )
    );
  };


  const handleClickCollaboration = () => {
    // Check if "CollaborationAccessControl" is in the access list
    if (getAuthUserCookie().accessList.includes("CollaborationAccessControl")) {
      return; // Exit early if the condition is true
    }
    // Toggle the state if the condition is not met
    setUserTypeCollaboration(!userTypeCollaboration);
  };



  const handleClickArbitration = () => {
    // Check if "CollaborationAccessControl" is in the access list
    if (getAuthUserCookie().accessList.includes("ArbitrationAccessControl")) {
      return; // Exit early if the condition is true
    }
    // Toggle the state if the condition is not met
    setUserTypeArbitrator(!userTypeArbitrator);
  };

  const handleChangeClientDetails = async (userId) => {
    const selectedClient = clientData.find((client) => client._id === userId);

    if (!selectedClient) {
      alert("No user found");
      return;
    }

    const ReqBody = {
      companyName: selectedClient.companyName,
      inputNumber: selectedClient.inputNumber,
      gstNo: selectedClient.gstNo,
      websiteAdd: selectedClient.websiteAdd,
      address1: selectedClient.address1,

      registeredBank: selectedClient.registeredBank,
      registeredAccountNo: selectedClient.registeredAccountNo,
      IFSCCode: selectedClient.IFSCCode,

      validUpto: selectedClient.validUpto,
    };

    try {
      const response = await axios.put(
        getURLbyEndPoint("changeClientDetails") + userId,
        {
          ...ReqBody,
        }
      );
      alert(response.data.message);
      window.location.reload();
    } catch (error) {
      alert("something went wrong");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  const handleEditAddClick = () => {
    console.log(clientData);
  };

  //setting approved logic

  const [membershipNumber, setMembershipNumber] = useState("");
  const [validUpto, setValidUpto] = useState("");
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingApproveInv, setIsLoadingApproveInv] = useState(false);

  const handleApproveUser = async (userId, approveUserData) => {
    const userToUpdate = clientData.find((user) => user._id === userId);
    const empId = getAuthUserCookie()._id;

    if (userToUpdate.selectMembership !== "Digital User") {
      if (!userToUpdate.membershipCertificate) {
        alert("Please Upload Membership Certificate");
        return;
      }
    }

    // For trial user
    if (userToUpdate.isTrial) {
      if (userToUpdate.password.trim() === "") {
        alert("Please Enter Password.");
        return;
      }
    }

    if (
      userToUpdate.membershipNumber.trim() === "" ||
      userToUpdate.validUpto.trim() === ""
    ) {
      alert("Please fill in both Membership Number, Valid Upto fields.");
    } else {
      try {
        setIsLoadingApprove(true);

        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("membershipNumber", userToUpdate.membershipNumber);
        formData.append("validUpto", userToUpdate.validUpto);
        formData.append("approveUserData", JSON.stringify(approveUserData));
        formData.append("approveRemark", userToUpdate.approveRemark);
        formData.append("password", userToUpdate.password);
        formData.append(
          "membershipCertificate",
          userToUpdate.membershipCertificate
        );

        // Make a POST request with axios
        const response = await axios.post(
          getURLbyEndPoint("approveUser") + empId,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          alert(response.data.message);
          window.location.reload();
        } else {
          alert("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error(error);
        if (error.response) {
          console.error("Response error:", error.response.data);
          console.error("Status code:", error.response.status);
          alert("Error: " + error.response.data.message);
        } else if (error.request) {
          console.error("No response received:", error.request);
          alert("No response received from the server.");
        } else {
          console.error("Request setup error:", error.message);
          alert("Request setup error. Please try again.");
        }
      } finally {
        setIsLoadingApprove(false);
      }
    }
  };


  const handleApproveInvitation = async (userId, approveUserData) => {
    const userToUpdate = clientData.find((user) => user._id === userId);
    const empId = getAuthUserCookie()._id;



    if (
      userToUpdate.embassyAddress.trim() === "" ||
      userToUpdate.embassyType.trim() === ""
    ) {
      alert("Please fill in both Embassy Address, Types fields.");
    } else {
      try {
        setIsLoadingApproveInv(true);

        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("approveRemark", 'your invitation letter has been approved');
        formData.append(
          "invitationLetter",
          userToUpdate.invitationLetter
        );

        // Make a POST request with axios
        const response = await axios.post(
          getURLbyEndPoint("approveInvitation") + empId,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          alert(response.data.message);
          window.location.reload();
        } else {
          alert("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error(error);
        if (error.response) {
          console.error("Response error:", error.response.data);
          console.error("Status code:", error.response.status);
          alert("Error: " + error.response.data.message);
        } else if (error.request) {
          console.error("No response received:", error.request);
          alert("No response received from the server.");
        } else {
          console.error("Request setup error:", error.message);
          alert("Request setup error. Please try again.");
        }
      } finally {
        setIsLoadingApproveInv(false);
      }
    }
  };

  //handling dynaic dta update approving user
  const updatePropertyById = (userId, propertyKey, updatedValue) => {
    return clientData.map((item) =>
      item._id === userId ? { ...item, [propertyKey]: updatedValue } : item
    );
  };

  //MEMBERSHIP BLOB SETTING----------------------------------------
  // const createObjectURLSafe = (file) => {
  //   try {
  //     if (file instanceof Blob || file instanceof File) {
  //       return URL.createObjectURL(file);
  //     } else {
  //       console.error("Invalid file format");
  //       return "#";
  //     }
  //   } catch (error) {
  //     console.error("Failed to create object URL:", error);
  //     return "#";
  //   }
  // };

  //upgrade memberhsip functions
  const [upgradeMembershipModal, setUpgradeMembershipModal] = useState(false);

  const handleUpgradeMembershipOpen = () => setUpgradeMembershipModal(true);
  const handleUpgradeMembershipClose = () => setUpgradeMembershipModal(false);

  const handleUpgradeRejectMembership = async (userId, status) => {
    try {
      const res = await axios.post(
        getURLbyEndPoint("upgradeRejectMembership"),
        {
          clientId: userId,
          status,
        }
      );

      if (res.data.message) {
        alert(res.data.message);

        window.location.reload();
      } else {
        alert("something went wrong!!");
        console.error("response is :", res);
      }
      console.error("Response:", res.data);
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
        alert("Error: " + error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response received from the server.");
      } else {
        console.error("Request setup error:", error.message);
        alert("Request setup error. Please try again.");
      }
    }
  };

  //logic for user edit access
  // const [isEditAccess, setIsEditAccess] = useState(false);

  const handleEditAccessClick = (userId) => {
    const updatedClientData = clientData.map((user) =>
      user._id === userId ? { ...user, isEditAccess: true } : user
    );
    setClientData(updatedClientData);
  };

  const handleSaveAccessClick = async (userId, approveUserData) => {
    try {
      const res = await axios.post(getURLbyEndPoint("ChangeAccessList"), {
        userId,
        approveUserData,
      });

      if (res.data.status) {
        alert(res.data.message);
        const updatedClientData = clientData.map((user) =>
          user._id === userId ? { ...user, isEditAccess: false } : user
        );
      } else {
        alert("something went wrong!!");
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  //generating form for giving to exporters

  const handleForm = (data) => {
    const dynamicContent = `
<h4>Membership Type</h4>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Selected Required Membership :</h5>
<h6 style="flex: 1;">${data.selectMembership}</h6>
</div>
<h4 style="margin-top: 16px;">About Your Company</h4>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Company Name :</h5>
<h6 style="flex: 1;">${data.companyName}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">GST NO./IEC Code/CIN  :</h5>
<h6 style="flex: 1;">${data.inputNumber}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Website Address  :</h5>
<h6 style="flex: 1;">${data.websiteAdd}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Number of Employees :</h5>
<h6 style="flex: 1;">${data.numberOfEmployees}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Address 1 :</h5>
<h6 style="flex: 1;">${data.address1}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Address 2 :</h5>
<h6 style="flex: 1;">${data.address2}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">City :</h5>
<h6 style="flex: 1;">${data.city}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">State :</h5>
<h6 style="flex: 1;">${data.state}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Country :</h5>
<h6 style="flex: 1;">${data.country}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Pincode :</h5>
<h6 style="flex: 1;">${data.pinCode}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Business Category :</h5>
<h6 style="flex: 1;">${data.businessCategory}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">How Did You Know About Us :</h5>
<h6 style="flex: 1;">${data.howDidYouKnowAboutUs}</h6>
</div>
<h4 style="margin-top: 16px;">About You</h4>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Title :</h5>
<h6 style="flex: 1;">${data.title}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">First Name :</h5>
<h6 style="flex: 1;">${data.firstName}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Surname :</h5>
<h6 style="flex: 1;">${data.surName}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Role :</h5>
<h6 style="flex: 1;">${data.role}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Email :</h5>
<h6 style="flex: 1;">${data.email}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Mobile No. :</h5>
<h6 style="flex: 1;">${data.mobileNo}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Company Tel No. :</h5>
<h6 style="flex: 1;">${data.telephoneNo}</h6>
</div>
<h4 style="margin-top: 16px;">Bank Details</h4>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Registerd Bank :</h5>
<h6 style="flex: 1;">${data.registeredBank}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Account No. :</h5>
<h6 style="flex: 1;">${data.registeredAccountNo}</h6>
</div>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">IFSC Code :</h5>
<h6 style="flex: 1;">${data.IFSCCode}</h6>
</div>
<h4 style="margin-top: 16px;">Communication Preference</h4>
<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Through emails :</h5>
<h6 style="flex: 1;">${data.throughEmail}</h6>
</div>

<div style="display: flex; align-items: center;">
<h5 style="flex: 1; margin-right: 1rem;">Through calls :</h5>
<h6 style="flex: 1;">${data.throughCalls}</h6>
</div>
    `;

    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Use html2pdf to convert HTML to PDF
    html2pdf(dynamicContent, { margin: 10, filename: "document.pdf" })
      .from(dynamicContent)
      .outputPdf()
      .then((pdfBlob) => {
        // Create a data URI from the PDF blob
        const pdfDataUri = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new window
        const printWindow = window.open(
          pdfDataUri,
          "_blank",
          "width=600,height=800"
        );
      });
  };

  //logic for generating certificate-------------------------------------------
  // ------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------
  const handleGenerateMembCert = (userId) => {
    const updatedClientData = clientData.find((user) => user._id === userId);

    console.log("handleGenerateMembCert", handleGenerateMembCert);

    // --------------------------------adding validation for fields--------------------------------------------------------------
    if (!updatedClientData) {
      alert("User not found");
      return;
    }
    if (!updatedClientData.membershipNumber) {
      alert("Please enter membership number");
      return;
    }
    if (!updatedClientData.validUpto) {
      alert("Please valid upto");
      return;
    }

    const membershipCertImage = {
      "Small Business (Associate)": membCertSmall,
      Corporate: membCertCorp,
      "Corporate +": membCertCorpplus,
      Collaboration: collaborationCert,
      Arbitration: ArbitrationCertificate,
    };

    const selectedMembership = updatedClientData.selectMembership;
    const imgSource = membershipCertImage[selectedMembership];

    if (!imgSource) {
      console.error(`Invalid membership type: ${selectedMembership}`);
      alert("Invalid membership type");
      return;
    }

    const orientation =
      updatedClientData.selectMembership === "Collaboration"
        ? "landscape"
        : "portrait";
    const format =
      updatedClientData.selectMembership === "Collaboration"
        ? [508, 286.75]
        : "a4";

    const pdf = new jsPDF({
      unit: "mm",
      format: format,
      orientation: orientation,
    });

    // Set background image
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgSource, "JPEG", 0, 0, imgWidth, imgHeight);

    pdf.setTextColor("#5d3e35");
    const companyFontSize =
      updatedClientData.selectMembership === "Collaboration" ? 40 : 18;
    pdf.setFontSize(companyFontSize);
    pdf.setFont("Eczar", "bold");
    const text =
      updatedClientData.selectMembership === "Collaboration"
        ? `${updatedClientData.companyName} ( ${updatedClientData.country} )`
        : updatedClientData.companyName;

    const textWidth = pdf.getTextWidth(text);
    const documentWidth = pdf.internal.pageSize.getWidth();
    const centerX = (documentWidth - textWidth) / 2;
    const textY =
      updatedClientData.selectMembership === "Collaboration" ? 128 :
      updatedClientData.selectMembership === "Arbitration"? 140: 150; // Use the same Y position if not different for Collaboration

    // Set the text position to the calculated center
    pdf.text(centerX, textY, text);

    // Setting membership number width
    const membershipNoFontSize =
      updatedClientData.selectMembership === "Collaboration" ? 19 : 16;
    pdf.setFontSize(membershipNoFontSize);
    pdf.setFont("Roboto", "bold");
    const membNo = updatedClientData.membershipNumber;
    const membNoTextWidth = pdf.getTextWidth(membNo);
    const membNoCenterX =
      updatedClientData.selectMembership === "Collaboration"
        ? 174 - membNoTextWidth / 2
        :   updatedClientData.selectMembership === "Arbitration"
        ? 65 - membNoTextWidth / 2
        : 70 - membNoTextWidth / 2;
    const membNoY =
      updatedClientData.selectMembership === "Collaboration" ? 178 :
      updatedClientData.selectMembership === "Arbitration"? 199 : 200 ; // Assuming Y position remains same

    pdf.text(membNoCenterX, membNoY, membNo);

    // Valid Upto Date
    const validUpto = updatedClientData.validUpto;
    const formattedDate = formatDate(validUpto);
    const validUptoTextWidth = pdf.getTextWidth(formattedDate);
    const validUptoCenterX =
      updatedClientData.selectMembership === "Collaboration"
        ? 164 - validUptoTextWidth / 2
        :   updatedClientData.selectMembership === "Arbitration"
        ? 136 - membNoTextWidth / 2
        : 132 - validUptoTextWidth / 2;
    const validUptoY =
      updatedClientData.selectMembership === "Collaboration" ? 192 :
      updatedClientData.selectMembership === "Arbitration"? 199 :  200; // Assuming Y position remains same

    pdf.text(validUptoCenterX, validUptoY, formattedDate);

    // Generate PDF as Blob
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    //seeting like this so state will update
    setClientData((prevClientData) =>
      prevClientData.map((user) =>
        user._id === userId
          ? {
              ...user,
              membershipCertificate: new Blob([blobPdf], {
                type: "application/pdf",
              }),
            }
          : user
      )
    );
  };


const handleGenerateInvLetter = (userId) => {
  const updatedClientData = clientData.find((user) => user._id === userId);

  if (!updatedClientData) {
    alert("User not found");
    return;
  }

  if(!updatedClientData.embassyAddress){
    alert("please Enter embassyAddress");
    return;
  }
  if(!updatedClientData.embassyType){
    alert("please Enter Embassy / Consulate / High Commission");
    return;
  }

  const pdf = new jsPDF({
    unit: "mm",
    format: "a4",
    orientation: "portrait",
  });

  // Set background image
  const imgWidth = pdf.internal.pageSize.getWidth();
  const imgHeight = pdf.internal.pageSize.getHeight();
  pdf.addImage(invitationLetter, "JPEG", 0, 0, imgWidth, imgHeight);

  // Define some constants
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Initial text placement
  pdf.setFontSize(10);
  pdf.setFont("helvetica", "bold");
  pdf.text(21, 53, `Ref: ${updatedClientData.gstNo}`);
  const date = formatDate(updatedClientData.updatedAt);
  pdf.text(135, 53, `Date: ${date}`);

  // Address block
  pdf.setFont("helvetica", "normal");
  pdf.text(21, 65, "To,");
  pdf.text(21, 70, "His Excellency,");
  pdf.text(21, 75, "Embassy of India,");
  const addressWidth = 550; // Width in mm
  const addressLines = pdf.splitTextToSize(updatedClientData.embassyAddress, addressWidth, 'normal');
  let yPosAddress = 80; 
  addressLines.forEach((line) => {
    pdf.text(21, yPosAddress, line);
    yPosAddress += 5; 
  });

  // Title and underline
  pdf.setFont("helvetica", "bold");
  pdf.text(55, yPosAddress + 5, `Re: Invitation letter for Mega-Event “AECCI B2B Connect 2024”`);
  const textWidth = pdf.getTextWidth(`Re: Invitation letter for Mega-Event “AECCI B2B Connect 2024”`);
  pdf.line(55, yPosAddress + 7, 55 + textWidth, yPosAddress + 7);

  // Body text
  pdf.setFontSize(10);
  pdf.setFont("helvetica", "bold");
  pdf.text(21, yPosAddress + 14, "Dear Sir/Madam,");
  pdf.setFont("helvetica", "normal");
  const paraText = `We are pleased to extend an official invitation to two delegates from our Collaborative Partner firm ${updatedClientData.companyName}, to attend our Mega-Event “AECCI B2B Connect 2024”, scheduled to be held from 25.10.2024 to 27.10.2024 at The Courtyard, Navi-Mumbai, Maharashtra ,India.`;
  const paraWidth = 172; // Width in mm
  const paraLines = pdf.splitTextToSize(paraText, paraWidth);
  let yPosPara = yPosAddress + 22;
  paraLines.forEach((line) => {
    pdf.text(21, yPosPara, line);
    yPosPara += 6;
  });

  const text2 = `We kindly request the ${updatedClientData.embassyType} to process a VISA for the delegate mentioned below to facilitate their travel to India for the purpose of attending this Mega Event. We assure you that the delegate, will comply with all visa regulations and will return to their home country upon completion of the event.`;
  const text2Lines = pdf.splitTextToSize(text2, paraWidth);
  let yPosText2 = yPosPara + 2;
  text2Lines.forEach((line) => {
    pdf.text(21, yPosText2, line);
    yPosText2 += 6;
  });

  // Delegate Details
let yPosDelegateDetails = yPosText2 + 2; // Increase space before table
const passports = updatedClientData.documents.passports;
const numberOfDelegates = passports.length;
pdf.setFontSize(10);
pdf.setFont("helvetica", "bold");
pdf.text(21, yPosDelegateDetails, "Please find the details of the delegate(s) below:");

const tableHeaders = ["Delegations detail"];
if (numberOfDelegates >= 1) tableHeaders.push("Delegate 1");
if (numberOfDelegates === 2) tableHeaders.push("Delegate 2");

const tableData = [
  ["Full Name:", `${passports[0]?.firstName || ""} ${passports[0]?.lastName || ""}`, passports[1] ? `${passports[1]?.firstName || ""} ${passports[1]?.lastName || ""}` : ""],
  ["Passport Number:", passports[0]?.passportNo || "", passports[1]?.passportNo || ""],
  ["Date of issue:", new Date(passports[0]?.issueDate).toLocaleDateString(), passports[1] ? new Date(passports[1]?.issueDate).toLocaleDateString() : ""],
  ["Date of Expiry:", new Date(passports[0]?.expiryDate).toLocaleDateString(), passports[1] ? new Date(passports[1]?.expiryDate).toLocaleDateString() : ""],
  ["Designation:", passports[0]?.designation || "", passports[1]?.designation || ""],
];

const tableWidth = 170;
const col1Width = tableWidth / (numberOfDelegates + 1);
const tableStartY = yPosDelegateDetails + 10;
const rowHeight = 7; // Increased row height for more space
const tableEndY = tableStartY + (tableData.length * rowHeight);

// Draw table headers
pdf.setFontSize(10);
pdf.setFont("helvetica", "bold");
pdf.text(tableHeaders[0], 23, tableStartY);
if (numberOfDelegates >= 1) pdf.text(tableHeaders[1], 23 + col1Width, tableStartY);
if (numberOfDelegates === 2) pdf.text(tableHeaders[2], 23 + 2 * col1Width, tableStartY);

// Draw lines for table headers
pdf.line(21, tableStartY - rowHeight, 21 + tableWidth, tableStartY - rowHeight); // Top line for header row
pdf.line(21, tableStartY + 2, 21 + tableWidth, tableStartY + 2); // Bottom line for header row
pdf.line(21, tableStartY - rowHeight, 21, tableStartY + rowHeight); // Left vertical line of header
pdf.line(21 + col1Width, tableStartY - rowHeight, 21 + col1Width, tableStartY + rowHeight); // First vertical line of header
if (numberOfDelegates === 2) {
  pdf.line(21 + 2 * col1Width, tableStartY - rowHeight, 21 + 2 * col1Width, tableStartY + rowHeight); // Second vertical line of header
}
pdf.line(21 + tableWidth, tableStartY - rowHeight, 21 + tableWidth, tableStartY + rowHeight); // Right vertical line of header

// Render table rows dynamically
pdf.setFont("helvetica", "normal");
tableData.forEach((row, index) => {
  const y = tableStartY + (index + 1) * rowHeight;
  pdf.text(row[0], 23, y);
  pdf.text(row[1], 23 + col1Width, y);
  if (numberOfDelegates === 2) {
    pdf.text(row[2], 23 + 2 * col1Width, y);
  }
  // Draw row lines, but not for the last row
  if (index < tableData.length - 1) {
    pdf.line(21, y + 2, 21 + tableWidth, y + 2); // Row line for current row
  }
  pdf.line(21, y - rowHeight + 2, 21, y + 2); // Left vertical line for rows
  pdf.line(21 + col1Width, y - rowHeight + 2, 21 + col1Width, y + 2); // First vertical line for rows
  if (numberOfDelegates === 2) {
    pdf.line(21 + 2 * col1Width, y - rowHeight + 2, 21 + 2 * col1Width, y + 2); // Second vertical line for rows
  }
  pdf.line(21 + tableWidth, y - rowHeight + 2, 21 + tableWidth, y + 2); // Right vertical line for rows
});

// Draw final horizontal line
pdf.line(21, tableEndY+2, 21 + tableWidth, tableEndY+2); // Final horizontal line


  // Add text content below the table
  const text3 = `We appreciate your support and assistance in processing the visa application. We will be happy to serve with any further information or documentation. for any clarification you can scan and verify below QR Code.`;
  const text3Lines = pdf.splitTextToSize(text3, paraWidth);
  let yPosText3 = tableEndY + 10; // Adjusted space below the table
  text3Lines.forEach((line) => {
    pdf.text(21, yPosText3, line);
    yPosText3 += 6;
  });

  const text4 = `Thank you for your attention and cooperation.`;
  const text4Lines = pdf.splitTextToSize(text4, paraWidth);
  let yPosText4 = yPosText3 + 2;
  text4Lines.forEach((line) => {
    pdf.text(21, yPosText4, line);
    yPosText4 += 6;
  });

  // Add reference number at the bottom
  // pdf.setFontSize(6);
  // const refNumberY = pageHeight - 10;
  // pdf.text(9.5, refNumberY, `Ref: 11/234/AECCI`);

  // Generate PDF as Blob
  const blobPdf = pdf.output("blob", { encoding: "raw" });

  //seeting like this so state will update
  setClientData((prevClientData) =>
    prevClientData.map((user) =>
      user._id === userId
        ? {
            ...user,
            invitationLetter: new Blob([blobPdf], {
              type: "application/pdf",
            }),
          }
        : user
    )
  );
};






  const [apiAddDeduct, setApiAddDeduct] = useState(false);
  const handleAddDeductBalSuperAdmin = async (userId, paymentType) => {
    if (!userId) {
      alert("please provide UserID");
      return;
    }

    if (!paymentType) {
      alert("please Enter payement type");
      return;
    }

    const clientDetails = clientData.find((client) => client._id === userId);
    if (!clientDetails) {
      alert("No user found");
      return;
    }

    // Convert balToAdd to a number
    const balToAdd = parseInt(clientDetails.balToAdd);

    //validation
    const remark = clientDetails.walletRemark;
    if (!balToAdd || !remark) {
      alert("Please fill all fields.");
      return;
    }

    try {
      setApiAddDeduct(true);

      const response = await axios.put(
        getURLbyEndPoint("addDeductBalSuperAdmin") + userId,
        {
          balToAdd,
          remark,
          paymentType,
        }
      );

      // Update the client's balance
      if (response.data.status) {
        const updatedClientData = clientData.map((client) => {
          if (client._id === userId) {
            return {
              ...client,
              balance: client.balance + balToAdd,
              balToAdd: null,
            };
          }
          return client;
        });
        setClientData(updatedClientData);
        setApiAddDeduct(false);
      }

      alert(response.data.message);
    } catch (error) {
      alert("something went wrong");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setApiAddDeduct(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getURLbyEndPoint("getUserDataSuperAdmin")
        );

        const userDataArray = response.data;

        if (response.status === 200) {
          let newClientData = response.data.data.map((e) => {
            const commonProperties = {
              // Common properties for both cases
              expandedCategories: { ...expandedCategories },
              membershipNumber: membershipNumber,
              validUpto: validUpto,
              isEditingCompany: false,
              isEditingYou: false,
              isEditingBank: false,
              isEditAccess: false,
              isEditValidUpto: false,
              balToAdd: null,
              walletRemark: "",
              embassyAddress: "",
              embassyType: "",
            };

            if (!e.isApproved) {
              // Additional properties for isApproved = false
              return {
                ...e,
                ...commonProperties,
                approveRemark: "",
                ...(e.isTrial && { password: "" }),
              };
            } else {
              // Common properties only for isApproved = true
              return {
                ...e,
                ...commonProperties,
              };
            }
          });

          setClientData(newClientData);

          // Additional logic to set initial values for expandedCategories
          const initialExpandedCategories = {};
          Object.keys(approveUserData).forEach((category) => {
            if (typeof approveUserData[category] === "object") {
              initialExpandedCategories[category] = false;
            }
          });
          setExpandedCategories(initialExpandedCategories);

          // Set initial values for specific fields in formData based on the first client (adjust as needed)
          if (response.data.data.length > 0) {
            const initialClient = response.data.data[0];
            const initialFormData = {
              companyName: initialClient.companyName || "",
              inputNumber: initialClient.inputNumber || "",
              websiteAdd: initialClient.websiteAdd || "",
              address1: initialClient.address1 || "",
              email: initialClient.email || "",
              password: initialClient.password || "",
              registeredAccountNo: initialClient.registeredAccountNo || "",
              registeredBank: initialClient.registeredBank || "",
              IFSCCode: initialClient.IFSCCode || "",
            };

            setFormData((prevFormData) => ({
              ...prevFormData,
              ...initialFormData,
            }));
          }
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.error("Fetch Error:", error);
        setError("An error occurred while fetching data.");
      
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // //pagination setup -----------------------
  // ------------------------------------------
  // ------------------------------------------
  //filtering data
  const filteredData = clientData.filter((data) => {
    // Filter based on account status
    if (isApproved !== "" && data.isApproved !== isApproved) {
      return false;
    }

    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    // Filter based on isExisting
    if (userTypeExisting && data.isExisting !== true) {
      return false;
    }
    // Filter based on Trial account or not
    if (userTypeTrial && data.isTrial !== true) {
      return false;
    }

    // Filter based on collaboration account or not
    if (userTypeCollaboration && data.isCollaboration !== true) {
      return false;
    }

    // Filter based on Arbitration account or not
    if (userTypeArbitrator && data.isArbitration !== true) {
      return false;
    }

    return true;
  });

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  //loading setup
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  //

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />

            <DashboardHeader
              heading="Profile/ Client Super Admin"
              backUrl="/master-profile"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex gap-3 mb-4">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={isApproved === ""}
                  onChange={() => setIsApproved("")}
                />
                <Form.Check
                  inline
                  label="Pending"
                  name="group1"
                  type="radio"
                  checked={isApproved === false}
                  onChange={() => setIsApproved(false)}
                />{" "}
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={isApproved === true}
                  onChange={() => setIsApproved(true)}
                />{" "}
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search Company Name"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="mb-3">
            <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeExisting ? "success" : "outline-success"}
                onClick={() => setUserTypeExisting(!userTypeExisting)}
                disabled={
                  getAuthUserCookie().accessList.includes("CollaborationAccessControl")
                }
              >
                Existing
              </Button>
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeTrial ? "info" : "outline-info"}
                onClick={() => setUserTypeTrial(!userTypeTrial)}
                disabled={
                  getAuthUserCookie().accessList.includes("CollaborationAccessControl")
                }
              >
                Trial
              </Button>
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeCollaboration ? "danger" : "outline-danger"}
                onClick={handleClickCollaboration}
                active={getAuthUserCookie().accessList.includes("CollaborationAccessControl")}
              >
               Collaboration
              </Button>

              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeArbitrator ? "secondary" : "outline-secondary"}
                onClick={handleClickArbitration}
                active={getAuthUserCookie().accessList.includes("ArbitrationAccessControl")}
              >
                Arbitrator
              </Button>
            </div>

            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Sr No
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Membership Selection
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    About Company
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    About You
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Bank Details
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Documents and Payment
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Communication Preferences
                  </th>
                  <th
                    scope="col"
                    style={{
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    Membership Sevices
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Timestamp
                  </th>
                  <th
                    scope="col"
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                  >
                    Remark
                  </th>
                </tr>
              </thead>
              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((client, index) => (
                    <tbody>
                      <tr key={indexOfFirstItem + index}>
                        <th scope="row">
                          <div className="max-content">
                            {filteredData.length - indexOfFirstItem - index}.
                            {client.fullyReviewedAt && (
                              <div className="fw-bold text-center">
                                Fully Reviewed at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(client.fullyReviewedAt))}
                                </span>
                              </div>
                            )}
                            {client.upgradeReq &&
                              client.upgradeReq.isUpgradeMembership && (
                                <>
                                  <Button
                                    style={{ backgroundColor: "#" }}
                                    className="mt-2 primary-button fw-semibold"
                                    onClick={handleUpgradeMembershipOpen}
                                  >
                                    Upgrade
                                  </Button>
                                  <Modal
                                    show={upgradeMembershipModal}
                                    onHide={handleUpgradeMembershipClose}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Accept Request</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <h6>
                                        "{client.companyName}" wants to upgrade
                                        their membership to "
                                        <span className="text-info">
                                          {
                                            client.upgradeReq
                                              .upgradeMembershipTo
                                          }
                                        </span>
                                        ".
                                      </h6>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          handleUpgradeRejectMembership(
                                            client._id,
                                            false
                                          )
                                        }
                                      >
                                        REJECT
                                      </Button>
                                      <Button
                                        variant="success"
                                        onClick={() =>
                                          handleUpgradeRejectMembership(
                                            client._id,
                                            true
                                          )
                                        }
                                      >
                                        UPGRADE
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </>
                              )}
                            {client?.clientLogs?.length > 0 && (
                              <>
                                <h6 className="text-start fw-regular mt-2 mb-0 max-content">
                                  Client Logs:{" "}
                                </h6>
                                <span className="fw-normal">
                                  {client.clientLogs.map((data, index) => {
                                    const date = new Date(data);
                                    const formattedDate =
                                      date.toLocaleDateString();
                                    const formattedTime =
                                      date.toLocaleTimeString();
                                    return (
                                      <div className="d-flex" key={index}>
                                        <div className="no-wrap">
                                          {index + 1} :
                                        </div>
                                        <div>
                                          <div className="max-content text-success">
                                            {"  "}
                                            {formattedDate}
                                          </div>
                                          <div className="max-content text-secondary">
                                            {formattedTime}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </span>
                              </>
                            )}
                          </div>
                        </th>
                        <td>
                          <b>{client.selectMembership} </b>
                        </td>
                        <td>
                          <div className="max-content d-flex flex-column mb-2">
                            <b>
                            {client.reqType === "arbitrationReq" ? "Arbitrator Name:" : "Co. Name:"} 
                              <span className="text-secondary">
                                {client.isEditingCompany ? (
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: "max-content",
                                      width: "max-content",
                                    }}
                                    className="mb-1"
                                    value={client.companyName}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "companyName",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.companyName
                                )}
                              </span>
                            </b>

                            <b>
                              {client.reqType === "collaborationReq" ? (
                                <>Registration No: </>
                              ) : (
                                <> IEC/CIN NO. : </>
                              )}
                              <span className="text-secondary">
                                {client.isEditingCompany ? (
                                  <input
                                    type="text"
                                    className="mb-1"
                                    value={client.inputNumber}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "inputNumber",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.inputNumber
                                )}
                              </span>
                            </b>
                            <b>
                            {client.reqType === "collaborationReq" ? (
                                    <>Collaboration No: </>
                                  ) : client.reqType === "arbitrationReq" ? (
                                    <>Panel Member ID: </>
                                  ) 
                                  : (
                                    <>GST: </>
                                  )}
                              <span className="text-secondary">
                                {client.isEditingCompany ? (
                                  <input
                                    type="text"
                                    className="mb-1"
                                    value={client.gstNo}
                                    onChange={(e) =>
                                      handleInputChange(client._id, "gstNo", e)
                                    }
                                  />
                                ) : (
                                  client.gstNo
                                )}
                              </span>
                            </b>

                            <b>
                              Web Add:{" "}
                              <span className="text-secondary">
                                {client.isEditingCompany ? (
                                  <input
                                    type="text"
                                    className="mb-1"
                                    value={client.websiteAdd}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "websiteAdd",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.websiteAdd
                                )}
                              </span>
                            </b>
                            <b>
                              company add:{" "}
                              <span className="text-secondary">
                                {client.isEditingCompany ? (
                                  <input
                                    type="text"
                                    className="mb-1"
                                    value={formData.address1}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "address1",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.address1
                                )}
                              </span>
                            </b>

                            <b>
                                  {client.isCollaboration &&
                                    <span className="text-secondary">
                                      {client.country}
                                    </span>
                                  }
                                </b>
                            {/* Repeat the same pattern for other fields */}

                            {client.isEditingCompany ? (
                              <div className="d-flex w-100 gap-3">
                                <Button
                                  variant="primary"
                                  className="mt-2"
                                  onClick={() =>
                                    handleChangeClientDetails(client._id)
                                  }
                                >
                                  Change
                                </Button>
                                <Button
                                  variant="danger"
                                  className="mt-2"
                                  onClick={() =>
                                    handleEditClick(
                                      client._id,
                                      "isEditingCompany"
                                    )
                                  }
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-start align-items-center gap-2">
                                <h6 className="mt-3" style={{ color: "blue" }}>
                                  Reviewed
                                </h6>
                                <a
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleEditClick(
                                      client._id,
                                      "isEditingCompany"
                                    )
                                  }
                                >
                                  <FaPen />
                                </a>
                              </div>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="max-content d-flex flex-column mb-2">
                            <b>
                              Name:
                              <span className="text-secondary">
                                {client.title} {client.firstName}{" "}
                                {client.surName}
                              </span>
                            </b>
                            <b>
                              Role:{" "}
                              <span className="text-secondary">
                                {client.role}
                              </span>
                            </b>
                            <b>
                              email :
                              <span className="text-secondary">
                                {formData.isEditingYou ? (
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: "max-content",
                                      width: "max-content",
                                    }}
                                    className="mb-1"
                                    value={formData.email}
                                    onChange={(e) =>
                                      handleInputChange("email", e)
                                    }
                                  />
                                ) : (
                                  client.email
                                )}
                              </span>
                            </b>

                            <b>
                              password :
                              <span className="text-secondary">
                                {formData.isEditingYou ? (
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: "max-content",
                                      width: "max-content",
                                    }}
                                    className="mb-1"
                                    value={formData.password}
                                    onChange={(e) =>
                                      handleInputChange("password", e)
                                    }
                                  />
                                ) : (
                                  client.password
                                )}
                              </span>
                            </b>
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              {formData.isEditingYou ? (
                                <Button
                                  variant="primary"
                                  className="mt-2"
                                  onClick={() => handleEditAddClick("You")}
                                >
                                  Add Changes
                                </Button>
                              ) : (
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                  <h6
                                    className="mt-3"
                                    style={{ color: "blue" }}
                                  >
                                    Reviewed
                                  </h6>
                                  <a
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleEditClick(
                                        client._id,
                                        "isEditingYou"
                                      )
                                    }
                                  >
                                    <FaPen />
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="max-content d-flex flex-column mb-2">
                            <b>
                              Registered Ac. No:{" "}
                              <span className="text-secondary">
                                {client.isEditingBank ? (
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: "max-content",
                                      width: "max-content",
                                    }}
                                    className="mb-1"
                                    value={client.registeredAccountNo}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "registeredAccountNo",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.registeredAccountNo
                                )}
                              </span>
                            </b>

                            <b>
                              Bank Name:{" "}
                              <span className="text-secondary">
                                {client.isEditingBank ? (
                                  <select
                                    value={client.registeredBank}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "registeredBank",
                                        e
                                      )
                                    }
                                  >
                                    {bankNames.map((bankName) => (
                                      <option key={bankName} value={bankName}>
                                        {bankName}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  client.registeredBank
                                )}
                              </span>
                            </b>

                            <b>
                              IFSC Code:{" "}
                              <span className="text-secondary">
                                {client.isEditingBank ? (
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: "max-content",
                                      width: "max-content",
                                    }}
                                    className="mb-1"
                                    value={client.IFSCCode}
                                    onChange={(e) =>
                                      handleInputChange(
                                        client._id,
                                        "IFSCCode",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  client.IFSCCode
                                )}
                              </span>
                            </b>

                            {client.isEditingBank ? (
                              <div className="d-flex gap-3">
                                <Button
                                  variant="primary"
                                  className="mt-2"
                                  onClick={() =>
                                    handleChangeClientDetails(client._id)
                                  }
                                >
                                  Change
                                </Button>
                                <Button
                                  variant="danger"
                                  className="mt-2"
                                  onClick={() =>
                                    handleEditClick(client._id, "isEditingBank")
                                  }
                                >
                                  Cancel{" "}
                                </Button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-start align-items-center gap-2">
                                <h6 className="mt-3" style={{ color: "blue" }}>
                                  Reviewed
                                </h6>
                                <a
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleEditClick(client._id, "isEditingBank")
                                  }
                                >
                                  <FaPen />
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="max-content">
                            
  {client.documents ? (
    // client.accountStatus !== "FullyReviewed" || client.documents.invitationLetter ? (
      Object.keys(client.documents).length > 0 ? (
        Object.keys(client.documents).map((e) => (
          <>
            {["clientId", "_id", "email", "__v", "createdAt", "updatedAt", 'passports'].includes(e) ? (
              null
            ) : (
              <span className="d-block" key={e}>
                <a
                  className="cursor-pointer"
                  onClick={() =>
                    openModal(
                      (() => {
                        switch (e) {
                          case "membershipForm":
                            return "Membership Form";
                          case "nonMemberForm":
                            return "Non-Member Form";
                          case "coverLetter":
                            return "Cover Letter";
                          case "indemnityBond":
                            return "Indemnity Bond";
                          case "kyc":
                            return "KYC";
                          case "gstCertOrPanCopy":
                            return "Gst Certificate Or PanCopy";
                          case "iecCertificate":
                            return "IEC Certificate";
                          case "certificationOfRegistration":
                            return "Certificate Of Registration";
                          case "incorpration_AOA_MOA":
                            return "Certificate of Incorporation/AOA/MOA";
                          case "passportCopies":
                            return "Passport Photo Copies";
                          case "paymentProof":
                            return "Payment Proof";
                            case "registrationForm":
                              return "Registration Form";
                            case "companyProfile":
                              return "Company Profile";
                            case "collaborationAgreement":
                              return "Collaboration Agreement";
                            case "businessLicense":
                              return "Business License";
                            case "invitationLetter":
                              return "invitation Letter";
                          case "barCouncilCopy":
                            return "Bar Council Copy";
                          default:
                            return "Unknown file";
                        }
                      })(),
                      client.documents[e]
                    )
                  }
                >
                  {(() => {
                    switch (e) {
                      case "membershipForm":
                        return "Membership Form";
                      case "nonMemberForm":
                        return "Non-Member Form";
                      case "coverLetter":
                        return "Cover Letter";
                      case "indemnityBond":
                        return "Indemnity Bond";
                      case "kyc":
                        return "KYC";
                      case "gstCertOrPanCopy":
                        return "Gst Certificate Or PanCopy";
                      case "iecCertificate":
                        return "IEC Certificate";
                      case "certificationOfRegistration":
                        return "Certificate Of Registration";
                      case "incorpration_AOA_MOA":
                        return "Certificate of Incorporation/AOA/MOA";
                      case "passportCopies":
                        return "Passport Photo Copies";
                      case "paymentProof":
                        return "Payment Proof";
                        case "registrationForm":
                          return "Registration Form";
                        case "companyProfile":
                          return "Company Profile";
                        case "collaborationAgreement":
                          return "Collaboration Agreement";
                        case "businessLicense":
                          return "Business License";
                        case "invitationLetter":
                          return "invitation Letter";
                      case "barCouncilCopy":
                        return "Bar Council Copy";
                      default:
                        return "Unknown file";
                    }
                  })()}
                </a>
              </span>
            )}
          </>
        ))
      ) : (
        <>
          <h6>No data is available</h6>
          {client.isApproved && client.isTrial && (
            <Button
              variant="info"
              onClick={() => handleSendReqRegFiles(client._id)}
            >
              Send Request
            </Button>
          )}
        </>
      )
    // ) : (
//       client.selectMembership === "Collaboration" && !client.documents.invitationLetter &&  (
//         <>
//         <div>
//         <div>Embassy Address</div>
//         <input
//                                 type="text"
//                                 className="form-control mb-2 w-75"
//                                 id="exampleFormControlInput1"
//                                 value={client.embassyAddress}
//                                 maxLength={120}
//                                 onChange={(e) => {
//                                   const updatedClientData = updatePropertyById(
//                                     client._id,
//                                     "embassyAddress",
//                                     e.target.value
//                                   );
//                                   setClientData(updatedClientData);
//                                 }}
//                               />
//                               <div>Embassy / Consulate / High Commission</div>
//         <input
//                                 type="text"
//                                 className="form-control mb-2 w-75"
//                                 id="exampleFormControlInput1"
//                                 value={client.embassyType}
//                                 maxLength={30}
//                                 onChange={(e) => {
//                                   const updatedClientData = updatePropertyById(
//                                     client._id,
//                                     "embassyType",
//                                     e.target.value
//                                   );
//                                   setClientData(updatedClientData);
//                                 }}
//                               />
//                               <div>

//           <Button
//                                     className="mb-2"
//                                     variant={
//                                       client.invitationLetter
//                                         ? "success"
//                                         : "primary"
//                                     }
//                                     onClick={() =>
//                                       handleGenerateInvLetter(client._id)
//                                     }
//                                   >
//                                     Generate invitation letter
//                                   </Button>
//                                   {client.invitationLetter &&
//                                   (() => {
//                                     if (
//                                       client.invitationLetter instanceof
//                                       Blob
//                                     ) {
//                                       const objectURL = URL.createObjectURL(
//                                         client.invitationLetter
//                                       );
//                                       return (
//                                         <a
//                                           href={objectURL}
//                                           target="_blank"
//                                           rel="noopener noreferrer"
//                                           onClick={() => {
//                                             // Clean up the object URL after opening the link
//                                             setTimeout(
//                                               () =>
//                                                 URL.revokeObjectURL(objectURL),
//                                               5000
//                                             );
//                                           }}
//                                         >
//                                           <BsEye className="icon-size" />
//                                         </a>
//                                       );
//                                     } else if (
//                                       typeof client.invitationLetter ===
//                                       "string"
//                                     ) {
//                                       return (
//                                         <a
//                                           href={client.invitationLetter}
//                                           target="_blank"
//                                           rel="noopener noreferrer"
//                                         >
//                                           <BsEye className="icon-size" />
//                                         </a>
//                                       );
//                                     }
//                                   })()}
//                                     </div>
                                  
//                                     {client.invitationLetter && (

//                                       <div>


// <Button
//                                     variant="success"
//                                     disabled={
//                                       isLoadingApprove ||
//                                       !client.embassyAddress ||
//                                       !client.embassyType
//                                     }
//                                     onClick={() =>
//                                       handleApproveInvitation(
//                                         client._id,
//                                         client.approveUserData
//                                       )
//                                     }
//                                   >
//                                     {isLoadingApproveInv ? <Spinner /> : "Approve"}
//                                   </Button>


//           {/* <Button
//                                     className="mb-2"
//                                     variant={'primary'}
//                                   onClick={()=> alert("Invitation letter Approved!")}
//                                   > */}
//                                     {/* Approve
//                                   </Button> */}
//                                     </div>
//                                   )}
//         </div>
        // </>                      
      // )
    // )
  ) : (
    <>
    <h6>No data is available</h6>
    {client.isApproved && client.isTrial && (
      <Button
        variant="info"
        onClick={() =>
          handleSendReqRegFiles(client._id)
        }
      >
        Send Request
      </Button>
    )}
  </>
  )}
</div>

                        </td>
                        <td>
                          <div className="d-flex align-items-center mb-2">
                            {client.throughEmail ? (
                              <AiFillCheckSquare className="icon-size" />
                            ) : (
                              <AiFillCloseSquare className="icon-size" />
                            )}
                            <span className="w-75 ms-2">
                              <b>Emails?</b>
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-4">
                            {client.throughCalls ? (
                              <AiFillCheckSquare className="icon-size" />
                            ) : (
                              <AiFillCloseSquare className="icon-size" />
                            )}
                            <span className=" ms-2 w-75">
                              <b>Calls?</b>
                            </span>
                          </div>
                        </td>

                        {/* //Choosing services part is here */}
                        <td>
                          {!client.isApproved || client.isEditAccess ? (
                            <>
                              <div className="mt-0">
                                {Object.entries(client.approveUserData).map(
                                  ([category, options]) => {
                                    if (typeof options === "boolean") {
                                      return (
                                        <div
                                          key={category}
                                          className="max-content d-flex align-items-center "
                                        >
                                          <div className="me-2">
                                            <input
                                              style={{
                                                height: "14px",
                                                width: "14px",
                                              }}
                                              className="form-check-input align-middle m-0 p-0"
                                              type="checkbox"
                                              id={category}
                                              checked={options}
                                              onChange={() => {
                                                const userToUpdate =
                                                  clientData.find(
                                                    (userData) =>
                                                      userData._id ===
                                                      client._id
                                                  );

                                                if (userToUpdate) {
                                                  userToUpdate.approveUserData[
                                                    category
                                                  ] = !options;
                                                  setClientData([
                                                    ...clientData,
                                                  ]);
                                                }
                                              }}
                                              aria-label="..."
                                              required
                                            />
                                          </div>
                                          <span style={{ fontSize: ".8rem" }}>
                                            {category}
                                          </span>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={category}>
                                        <div className="d-flex align-items-center">
                                          <div className="me-2">
                                            <input
                                              style={{
                                                height: "14px",
                                                width: "14px",
                                              }}
                                              className="form-check-input align-middle m-0 p-0"
                                              type="checkbox"
                                              id={`${category}`}
                                              checked={Object.values(
                                                options
                                              ).every((value) => value)}
                                              onChange={() => {
                                                const userToUpdate =
                                                  clientData.find(
                                                    (userData) =>
                                                      userData._id ===
                                                      client._id
                                                  );

                                                if (userToUpdate) {
                                                  const updatedSubCategoryValues =
                                                    Object.fromEntries(
                                                      Object.entries(
                                                        options
                                                      ).map(
                                                        ([
                                                          subCategory,
                                                          isChecked,
                                                        ]) => [
                                                          subCategory,
                                                          !isChecked,
                                                        ]
                                                      )
                                                    );

                                                  userToUpdate.approveUserData[
                                                    category
                                                  ] = updatedSubCategoryValues;
                                                  setClientData([
                                                    ...clientData,
                                                  ]);
                                                }
                                              }}
                                              aria-label="..."
                                              required
                                            />
                                          </div>
                                          <span
                                            style={{
                                              fontSize: ".8rem",
                                              marginRight: "0.5rem",
                                            }}
                                          >
                                            {`${category}`}
                                          </span>
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                              marginRight: "0.5rem",
                                            }}
                                            onClick={() =>
                                              toggleCategory(
                                                client._id,
                                                category
                                              )
                                            }
                                          >
                                            {clientData.find(
                                              (userData) =>
                                                userData._id === client._id
                                            )?.expandedCategories[category] ? (
                                              <FaAngleDown />
                                            ) : (
                                              <FaAngleRight />
                                            )}
                                          </div>
                                        </div>

                                        {clientData.find(
                                          (userData) =>
                                            userData._id === client._id
                                        )?.expandedCategories[category] && (
                                          <>
                                            {Object.entries(options).map(
                                              ([subCategory, isChecked]) => (
                                                <div
                                                  key={subCategory}
                                                  className="d-flex"
                                                  style={{
                                                    minWidth: "max-content",
                                                  }}
                                                >
                                                  <div className="ms-4 me-2">
                                                    <input
                                                      style={{
                                                        height: "14px",
                                                        width: "14px",
                                                      }}
                                                      className="form-check-input align-middle m-0 p-0"
                                                      type="checkbox"
                                                      id={subCategory}
                                                      checked={isChecked}
                                                      onChange={() => {
                                                        const userDataIndex =
                                                          clientData.findIndex(
                                                            (userData) =>
                                                              userData._id ===
                                                              client._id
                                                          );
                                                        if (
                                                          userDataIndex !== -1
                                                        ) {
                                                          const newClientData =
                                                            [...clientData];
                                                          newClientData[
                                                            userDataIndex
                                                          ].approveUserData[
                                                            category
                                                          ][subCategory] =
                                                            !isChecked;
                                                          setClientData(
                                                            newClientData
                                                          );
                                                        }
                                                      }}
                                                      aria-label="..."
                                                      required
                                                    />
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: ".8rem",
                                                    }}
                                                  >
                                                    {client.selectMembership !==
                                                      "Digital User" &&
                                                    subCategory ===
                                                      "Membership Services"
                                                      ? "Certificate of Membership"
                                                      : subCategory === "e-CO"
                                                      ? "Trade Document"
                                                      : subCategory}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              {client.isApproved && (
                                <Button
                                  variant="primary"
                                  className="w-100 mt-3"
                                  onClick={() =>
                                    handleSaveAccessClick(
                                      client._id,
                                      client.approveUserData
                                    )
                                  }
                                >
                                  SAVE
                                </Button>
                              )}{" "}
                            </>
                          ) : (
                            <div>
                              <div className="mb-3">
                                <h6>Access to:</h6>
                                {Object.entries(client.approveUserData).map(
                                  ([category, options]) => {
                                    if (typeof options === "boolean") {
                                      return (
                                        <div
                                          key={category}
                                          className="max-content d-flex align-items-center"
                                        >
                                          <div className="me-2">
                                            {options ? (
                                              <AiFillCheckCircle className="text-success fs-5" />
                                            ) : (
                                              <AiFillCloseCircle className="text-danger fs-5" />
                                            )}
                                          </div>
                                          <span style={{ fontSize: ".8rem" }}>
                                            {category}
                                          </span>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={category}>
                                        <div className="d-flex align-items-center">
                                          <div className="me-2">
                                            {Object.values(options).every(
                                              (value) => value
                                            ) ? (
                                              <AiFillCheckCircle className="text-success fs-5" />
                                            ) : (
                                              <AiFillCloseCircle className="text-danger fs-5" />
                                            )}
                                          </div>
                                          <span
                                            style={{
                                              fontSize: ".8rem",
                                              marginRight: "0.5rem",
                                            }}
                                          >
                                            {`${category}`}
                                          </span>
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                              marginRight: "0.5rem",
                                            }}
                                            onClick={() =>
                                              toggleCategory(
                                                client._id,
                                                category
                                              )
                                            }
                                          >
                                            {clientData.find(
                                              (userData) =>
                                                userData._id === client._id
                                            )?.expandedCategories[category] ? (
                                              <FaAngleDown />
                                            ) : (
                                              <FaAngleRight />
                                            )}
                                          </div>
                                        </div>

                                        {clientData.find(
                                          (userData) =>
                                            userData._id === client._id
                                        )?.expandedCategories[category] && (
                                          <>
                                            {Object.entries(options).map(
                                              ([subCategory, isChecked]) => (
                                                <div
                                                  key={subCategory}
                                                  className="d-flex"
                                                  style={{
                                                    minWidth: "max-content",
                                                  }}
                                                >
                                                  <div className="ms-4 me-2">
                                                    {isChecked ? (
                                                      <AiFillCheckCircle className="text-success fs-5" />
                                                    ) : (
                                                      <AiFillCloseCircle className="text-danger fs-5" />
                                                    )}
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: ".8rem",
                                                    }}
                                                  >
                                                    {client.selectMembership !==
                                                      "Digital User" &&
                                                    subCategory ===
                                                      "Membership Services"
                                                      ? "Certificate of Membership"
                                                      : subCategory === "e-CO"
                                                      ? "Trade Document"
                                                      : subCategory}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <Button
                                variant="primary"
                                className="w-100"
                                onClick={() =>
                                  handleEditAccessClick(client._id)
                                }
                              >
                                EDIT
                              </Button>
                            </div>
                          )}
                        </td>
                        <td>
                          {/* 
                        //-------------------Action Column start here--------------------------------------------
                        -----------------------------------------------------------------------------------------
                        ----------------------------------------------------------------------------------------- */}
                          {/* //------------------------ADDING BADGE FOR TRIAL USER---------------------------------- 
                           -------------------------------------------------------------------------------------------
                           -----------------------------------------------------------------------------------------*/}

                          {client.isTrial && (
                            <Badge bg="info" className="mb-0 me-2 fw-bold">
                              Trial
                            </Badge>
                          )}
                          {client.isCollaboration && (
                            <Badge bg="danger" className="mb-0 me-2 fw-bold">
                              Collaboration
                            </Badge>
                          )}
                          {new Date(client.validUpTo).setHours(
                            23,
                            59,
                            59,
                            999
                          ) < new Date() && (
                            <Badge bg="danger" className="mb-0 fw-bold">
                              Expired
                            </Badge>
                          )}

                          {client.isApproved ? (
                            <div className="max-content">
                              <div className="fw-semibold mb-2">
                              {
  client.selectMembership === "Digital User"
    ? "Aecci Code No."
    : client.selectMembership === "Collaboration"
    ? "Collaboration No."
    : client.selectMembership === "Arbitration"
    ? "Panel Member No."
    : "Membership No."
}
                                <br />
                                <span className="text-success">
                                  {client.memberShipNo}
                                </span>
                              </div>

                              {/* //-----------------------Valid UPTO--------------------------------------------------
-------------------------------------------------------------------------------------
------------------------------------------------------------------------------------- */}
                              {client?.isEditValidUpto ? (
                                <div className="fw-semibold">
                                  Valid Upto. :
                                  {client.selectMembership ===
                                  "Digital User" ? (
                                    <select
                                      className="form-control mb-2 w-75"
                                      id="exampleFormControlSelect1"
                                      onChange={(e) => {
                                        const selectedDays = parseInt(
                                          e.target.value,
                                          10
                                        );

                                        const addDays = (days) => {
                                          const date = new Date();
                                          date.setDate(date.getDate() + days);
                                          return date
                                            .toISOString()
                                            .split("T")[0]; // Return in YYYY-MM-DD format
                                        };

                                        const newValidUpto =
                                          addDays(selectedDays);

                                        // Update client data with the new validUpto date
                                        const updatedClientData =
                                          updatePropertyById(
                                            client._id,
                                            "validUpto",
                                            newValidUpto
                                          );
                                        setClientData(updatedClientData);
                                      }}
                                    >
                                      <option value="">Select duration</option>
                                      {options.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <input
                                      type="date"
                                      className="form-control mb-2 w-75"
                                      id="exampleFormControlInput1"
                                      value={client.validUpto}
                                      onChange={(e) => {
                                        const selectedDate = e.target.value;

                                        // Ensure the selected date is not before the current date
                                        if (selectedDate >= currentDate) {
                                          const updatedClientData =
                                            updatePropertyById(
                                              client._id,
                                              "validUpto",
                                              selectedDate
                                            );
                                          setClientData(updatedClientData);
                                        }
                                      }}
                                      min={currentDate}
                                    />
                                  )}
                                  <div className="d-flex gap-3">
                                    <Button
                                      variant="primary"
                                      className="mt-2"
                                      onClick={() =>
                                        handleChangeClientDetails(client._id)
                                      }
                                    >
                                      Change
                                    </Button>
                                    <Button
                                      variant="danger"
                                      className="mt-2"
                                      onClick={() =>
                                        handleEditClick(
                                          client._id,
                                          "isEditValidUpto"
                                        )
                                      }
                                    >
                                      Cancel{" "}
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div className="fw-semibold">
                                  <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Valid Upto. :</h6>
                                    <a
                                      onClick={() =>
                                        handleEditClick(
                                          client._id,
                                          "isEditValidUpto"
                                        )
                                      }
                                      className="ms-2 cursor-pointer"
                                    >
                                      <FaPen />
                                    </a>
                                  </div>
                                  <span className="text-success">
                                    {client.validUpTo}
                                  </span>
                                </div>
                              )}
                              {client.membershipCertificate && (
                                <a
                                  className="cursor-pointer"
                                  onClick={() =>
                                    openModal(
                                      "Membership Certificate",
                                      client.membershipCertificate
                                    )
                                  }
                                >
                                  Membership Certificate
                                </a>
                              )}

                              {/* //special frature for adding balance in user side 
                              ----------------------------------------------------
                              -----------------------------------------------------*/}
                              <div className="mt-3">
                                <h6>Balance : {client.balance}</h6>
                                <Form.Control
                                  className="form-control mb-2 w-75"
                                  value={client.balToAdd}
                                  placeholder="Enter Amount"
                                  maxLength={6}
                                  pattern="[0-9]*"
                                  onChange={(e) => {
                                    const input = e.target.value;
                                    if (/^\d*$/.test(input)) {
                                      // Check if input contains only digits
                                      const updatedClientData =
                                        updatePropertyById(
                                          client._id,
                                          "balToAdd",
                                          input
                                        );
                                      setClientData(updatedClientData);
                                    }
                                  }}
                                />
                                {client.balToAdd && (
                                  <>
                                    <Form.Control
                                      className="form-control mb-2 w-75"
                                      value={client.walletRemark}
                                      placeholder="Enter Remark"
                                      maxLength={30}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const updatedClientData =
                                          updatePropertyById(
                                            client._id,
                                            "walletRemark",
                                            input
                                          );
                                        setClientData(updatedClientData);
                                      }}
                                    />
                                    <div className="d-flex gap-2">
                                      <Button
                                        variant="success"
                                        disabled={apiAddDeduct}
                                        onClick={() =>
                                          handleAddDeductBalSuperAdmin(
                                            client._id,
                                            "credit"
                                          )
                                        }
                                      >
                                        Add
                                      </Button>
                                      <Button
                                        variant="secondary"
                                        disabled={apiAddDeduct}
                                        onClick={() =>
                                          handleAddDeductBalSuperAdmin(
                                            client._id,
                                            "debit"
                                          )
                                        }
                                      >
                                        Deduct
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="max-content d-flex flex-column mb-2">
                            {
  client.selectMembership === "Digital User" ? (
    <h6>Aecci Code No.</h6>
  ) : client.selectMembership === "Collaboration" ? (
    <h6>Collaboration No.</h6>
  ) : client.selectMembership === "Arbitration" ? (
    <h6>Panel Member No.</h6>
  ) : (
    <h6>Membership No.</h6>
  )
}
                              <input
                                type="text"
                                className="form-control mb-2 w-75"
                                id="exampleFormControlInput1"
                                value={client.membershipNumber}
                                maxLength={20}
                                onChange={(e) => {
                                  const updatedClientData = updatePropertyById(
                                    client._id,
                                    "membershipNumber",
                                    e.target.value
                                  );
                                  setClientData(updatedClientData);
                                }}
                              />

                              {/* //-----------VALID UPTO---------------------------------------------------
                              --------------------------------------------------------------------------
                              -------------------------------------------------------------------------- */}
                              <h6>Valid Upto</h6>
                              {client.selectMembership === "Digital User" ? (
                                <select
                                  className="form-control mb-2 w-75"
                                  id="exampleFormControlSelect1"
                                  onChange={(e) => {
                                    const selectedDays = parseInt(
                                      e.target.value,
                                      10
                                    );

                                    const addDays = (days) => {
                                      const date = new Date();
                                      date.setDate(date.getDate() + days);
                                      return date.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
                                    };

                                    const newValidUpto = addDays(selectedDays);

                                    // Update client data with the new validUpto date
                                    const updatedClientData =
                                      updatePropertyById(
                                        client._id,
                                        "validUpto",
                                        newValidUpto
                                      );
                                    setClientData(updatedClientData);
                                  }}
                                >
                                  <option value="">Select duration</option>
                                  {options.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="date"
                                  className="form-control mb-2 w-75"
                                  id="exampleFormControlInput1"
                                  value={client.validUpto}
                                  onChange={(e) => {
                                    const selectedDate = e.target.value;

                                    // Ensure the selected date is not before the current date
                                    if (selectedDate >= currentDate) {
                                      const updatedClientData =
                                        updatePropertyById(
                                          client._id,
                                          "validUpto",
                                          selectedDate
                                        );
                                      setClientData(updatedClientData);
                                    }
                                  }}
                                  min={currentDate}
                                />
                              )}
                              {/* //-----------PASSWORD FOR EMAIL---------------------------------------
                              --------------------------------------------------------------------------
                              -------------------------------------------------------------------------- */}
                              {client.isTrial && (
                                <>
                                  <h6>Password</h6>
                                  <input
                                    type="text"
                                    className="form-control mb-2 w-75"
                                    id="trialPass"
                                    value={client.password}
                                    maxLength={20}
                                    onChange={(e) => {
                                      const updatedClientData =
                                        updatePropertyById(
                                          client._id,
                                          "password",
                                          e.target.value
                                        );
                                      setClientData(updatedClientData);
                                    }}
                                  />
                                </>
                              )}
                              {/* //-----------REMARK ON APPROVE---------------------------------------
                              --------------------------------------------------------------------------
                              -------------------------------------------------------------------------- */}
                              <h6>Remark </h6>
                              <textarea
                                className="form-control mb-2 w-100"
                                id="exampleFormControlTextarea1"
                                value={client.approveRemark}
                                maxLength={150}
                                rows={3}
                                onChange={(e) => {
                                  const updatedClientData = updatePropertyById(
                                    client._id,
                                    "approveRemark",
                                    e.target.value
                                  );
                                  setClientData(updatedClientData);
                                }}
                              ></textarea>

                              <div className="d-flex justify-content-between gap-3">
                                {client.selectMembership !== "Digital User" && (
                                  <Button
                                    className="mb-2"
                                    variant={
                                      client.membershipCertificate
                                        ? "success"
                                        : "primary"
                                    }
                                    onClick={() =>
                                      handleGenerateMembCert(client._id)
                                    }
                                  >
                                    Generate Certificate
                                  </Button>
                                )}

                                {client.membershipCertificate &&
                                  (() => {
                                    if (
                                      client.membershipCertificate instanceof
                                      Blob
                                    ) {
                                      const objectURL = URL.createObjectURL(
                                        client.membershipCertificate
                                      );
                                      return (
                                        <a
                                          href={objectURL}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() => {
                                            // Clean up the object URL after opening the link
                                            setTimeout(
                                              () =>
                                                URL.revokeObjectURL(objectURL),
                                              5000
                                            );
                                          }}
                                        >
                                          <BsEye className="icon-size" />
                                        </a>
                                      );
                                    } else if (
                                      typeof client.membershipCertificate ===
                                      "string"
                                    ) {
                                      return (
                                        <a
                                          href={client.membershipCertificate}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <BsEye className="icon-size" />
                                        </a>
                                      );
                                    }
                                  })()}
                              </div>

                              {client?.validUpto &&
                                client?.membershipNumber &&
                                client?.approveRemark && (
                                  <Button
                                    variant="success"
                                    disabled={
                                      isLoadingApprove ||
                                      !client.validUpto ||
                                      !client.membershipNumber
                                    }
                                    onClick={() =>
                                      handleApproveUser(
                                        client._id,
                                        client.approveUserData
                                      )
                                    }
                                  >
                                    {isLoadingApprove ? <Spinner /> : "Approve"}
                                  </Button>
                                )}
                            </div>
                          )}
                        </td>

                        <td className="text-center">
                          {" "}
                          {client.isApproved ? (
                            <>
                              <div className="max-content fw-bold text-center mb-0">
                                Approved at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(client.approvedAt))}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="text-center">N/A</div>
                          )}
                          {client.approvedBy && (
                            <span style={{ fontSize: "0.8rem" }}>
                              by {client.approvedBy}
                            </span>
                          )}
                          <div>
                            <a
                              className="cursor-pointer"
                              onClick={() => handleForm(client)}
                            >
                              Download
                            </a>
                          </div>
                        </td>
                        <td>
                          {client.isApproved ? (
                            <>
                              <pre>
                                {breakAddressLinesForTextArea(
                                  client?.approveRemark,
                                  10
                                ).join("\n")}
                              </pre>
                            </>
                          ) : (
                            <div className="text-center">---</div>
                          )}
                        </td>
                      </tr>

                      {/* //modal is here----------------------------------
                  ---------------------------------------------------
                  ------------------------------------------------------ */}
                      <FileViewModal
                        show={showModal}
                        onHide={closeModal}
                        title={modalTitle}
                        fileLink={file}
                      />
                    </tbody>
                  ))}
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={11} className="text-center">
                      No Matching Data
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ClientAdmin;

//data formatting
export function formatDate(inputDate) {
  const dateParts = inputDate.split("-");
  const day = parseInt(dateParts[2], 10);
  const monthIndex = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[0], 10);

  const month = new Date(year, monthIndex).toLocaleString("default", {
    month: "long",
  });
  const suffix = getDaySuffix(day);

  return `${day}${suffix} ${month} ${year}`;
}

// Function to get the day suffix
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

////-----------FOR DOCUMENT UPLOAD------------------------
// --------------------------------------------------------
// --------------------------------------------------------
const handleSendReqRegFiles = async (userId) => {
  try {
    const res = await axios.patch(getURLbyEndPoint("sendReqRegFile") + userId);

    if (res.status) {
      alert("Request Send Successfully");
    } else {
      alert("Something went wrong when sending mail");
    }
  } catch (error) {
    console.log(error);
    alert("Something went wrong when sending mail");
  }
};
