import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Pagination from 'react-bootstrap/Pagination';

// import EditPublicationModal from "../../../components/basic/EditPublicationModal";

import Table from "react-bootstrap/Table";

const Publication = () => {
  const [tableData, setTableData] = useState([]);
  const [formData, setformData] = useState({
    title: "",
    publicationType: "",
    publicationDate: "",
    bannerImg: null,
    pdfUrl: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editingPublication, setEditingPublication] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);


const itemsPerPage = 5;
const totalPages = Math.ceil(tableData.length / itemsPerPage);

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const PublicationformData = new FormData();
  PublicationformData.append("title", formData.title);
  PublicationformData.append("publicationType", formData.publicationType);
  PublicationformData.append("publicationDate", formData.publicationDate);
  PublicationformData.append("bannerImg", formData.bannerImg);
  PublicationformData.append("pdfUrl", formData.pdfUrl);

  useEffect(() => {
    fetchPublications();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const publicationFile = e.target.files[0];
    setformData((prevState) => ({ ...prevState, bannerImg: publicationFile }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await updatePublication(editingPublication._id, formData);
    } else {
      await postPublication(formData);
    }
    fetchPublications(); 
    setIsEditing(false); 
    setEditingPublication(null); 
  };

  const handleReset = () => {
    setformData({
      title: "",
      publicationType: "",
      publicationDate: "",
      bannerImg: null,
      pdfUrl: "",
    });
  };

  const handleEdit = (publication) => {
    setformData({
      title: publication.title,
      publicationType: publication.publicationType,
      publicationDate: formatDate(publication.publicationDate),
      bannerImg: null, 
      pdfUrl: publication.pdfUrl,
    });
    setIsEditing(true);
    setEditingPublication(publication);
  };

  const handleShowImage = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const postPublication = async (publicationData) => {
    const formData = new FormData();
    Object.keys(publicationData).forEach((key) => {
      formData.append(key, publicationData[key]);
    });
    console.log("Posting formData:", formData); // Check the formData being posted

    try {
      const response = await axios.post(
        getURLbyEndPoint("createPublication"),
        PublicationformData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("API response:", response);
      // Handle response here
    } catch (error) {
      console.error("Error posting publication:", error);
    }
  };

  const fetchPublications = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getPublication"));
      // Sort the publications by publicationDate in descending order
      const sortedPublications = response.data.sort(
        (a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)
      );
      setTableData(sortedPublications);
    } catch (error) {
      console.error("Error fetching publications:", error);
    }
  };

  const deletePublication = async (publicationId) => {
    try {
      const response = await axios.delete(
        getURLbyEndPoint("deletePublication", publicationId)
      );
      console.log("Publication deleted:", response.data);
      fetchPublications(); // Refetch publications to update the table
    } catch (error) {
      console.error("Error deleting publication:", error);
    }
  };

  const updatePublication = async (id, publicationData) => {
    const formData = new FormData();
    Object.keys(publicationData).forEach((key) => {
      formData.append(key, publicationData[key]);
    });
    try {
      const response = await axios.put(
        `${getURLbyEndPoint("updatePublication")}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("API response:", response);
      // Handle response here
    } catch (error) {
      console.error("Error updating publication:", error);
    }
  };

  return (
    <div>
      <Layout>
        <MasterNavigationBar>
          <DashboardHeader
            heading="Publication (Digital Library)"
            backUrl="/master-publication-ads"
          />
          <hr />

          <div className="mb-4">
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="publicationDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="publicationDate"
                      value={formData.publicationDate}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="publicationType">
                    <Form.Label>Select Publication</Form.Label>
                    <Form.Select
                      name="publicationType"
                      value={formData.publicationType}
                      onChange={handleChange}
                      aria-label="Publication Type"
                    >
                      <option>Select Option</option>
                      <option value="Viewpoint">Viewpoint</option>
                      <option value="Newsletter">Newsletter</option>
                      <option value="Magazine">Magazine</option>
                      <option value="Annual report">Annual report</option>
                      <option value="Country Book">Country Book</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Enter title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="bannerImg">
                    <Form.Label>Upload Cover Page</Form.Label>
                    <Form.Control
                      type="file"
                      name="bannerImg"
                      accept="image/*"
                      onChange={handleFileChange}
                    />

                    <Form.Text className="text-muted">
                      Please upload your Cover Page here.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="pdfUrl">
                <Form.Label>Add URL</Form.Label>
                <Form.Control
                  type="text"
                  name="pdfUrl"
                  placeholder="Enter PDF URL"
                  value={formData.pdfUrl}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
              <Button variant="secondary" type="reset" className="ml-3">
                Reset
              </Button>
            </Form>
          </div>

          <div className="form-table mt-10">
            <h4>Recent Posts</h4>
            <Table
              responsive="sm"
              className="text-center"
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Date</th>
                  <th>Publication</th>
                  <th>Tittle</th>
                  <th>Cover Page</th>
                  <th>URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{formatDate(item.publicationDate)}</td>
                    <td>{item.publicationType}</td>
                    <td>{item.title}</td>
                    <td>
                      {/* Render cover page or link */}
                      <FaEye onClick={() => handleShowImage(item.bannerImg)} />
                    </td>
                    <td>
                      <a
                        href={item.pdfUrl}
                        className="text-primary text-underline"
                      >
                        Preview Link
                      </a>
                    </td>
                    <td>
                      <FaEdit onClick={() => handleEdit(item)} />
                      <div onClick={() => deletePublication(item._id)}>
                        <MdDelete />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
            <Pagination>
 <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
 <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
 {[...Array(totalPages).keys()].map((pageNumber) => (
    <Pagination.Item
      key={pageNumber}
      active={pageNumber + 1 === currentPage}
      onClick={() => setCurrentPage(pageNumber + 1)}
    >
      {pageNumber + 1}
    </Pagination.Item>
  ))}
 <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
 <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
</Pagination>
            </div>
          </div>

          <Modal centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Preview Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image src={modalImageUrl} fluid />
            </Modal.Body>
          </Modal>
        </MasterNavigationBar>
      </Layout>
      {/* <EditPublicationModal
 showModal={showModal}
 handleCloseModal={handleCloseModal}
 handleSubmitForm={handleSubmitForm}
 formData={formData}
 setformData={setformData}
/> */}
    </div>
  );
};

export default Publication;
