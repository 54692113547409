import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Form, Table, Button, Pagination, Spinner } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPoint, getURLbyEndPointV2 } from "../../../store/api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";

const LegalWingRequest = () => {
  const [legalData, setLegalData] = useState([]);
  const [appDisApi, setAppDisApi] = useState(false);
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  //simple functions
  const handleInputChange = (itemId, name, value) => {
    setLegalData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

//   const handleApproveRejectTrialUser = async (userId, status) => {
//     setAppDisApi(true);
//     const userData = legalData.find((item) => item._id === userId);
//     try {
//       const res = await axios.post(
//         getURLbyEndPointV2("getAllLegalWingRequest") + userId,
//         { status, remark: userData.remark },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (res.data.status) {
//         alert(res.data.message);
//         setAppDisApi(false);
//         window.location.reload();
//       } else {
//         alert(res.data.message);
//         setAppDisApi(false);
//       }
//     } catch (error) {
//       alert("Error while handling Trial data.");
//       console.error("Error:", error);
//     } finally {
//       setAppDisApi(false);
//     }
//   };

  const fetchLegalData = async () => {
    try {
      const response = await axios.get(getURLbyEndPointV2("getAllLegalWingRequest"));
      if (response.status) {
        const data = response.data.data;
        // Add properties to each object in the data.data array
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
            };
          } else if (item.isApproved === "Approved") {
            return {
              ...item,
            };
          }
          return item;
        });

        setLegalData([...modifiedData]);
        // console.log("result", modifiedData);
      } else {
        alert("Failed to fetch trial user data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

//   const handleRegisterClick = (data) => {
//     const {
//       companyName,
//       email,
//       firstName,
//       lastName,
//       country,
//       pinCode,
//       phoneNo,
//       inputNumber,
//     } = data;

//     const url = new URL("https://e-platform.aecci.org.in/register-3");
//     url.searchParams.append("companyName", companyName);
//     url.searchParams.append("email", email);
//     url.searchParams.append("firstName", firstName);
//     url.searchParams.append("lastName", lastName);
//     url.searchParams.append("country", country);
//     url.searchParams.append("pinCode", pinCode);
//     url.searchParams.append("phoneNo", phoneNo);
//     url.searchParams.append("inputNumber", inputNumber);
//     window.open(url.toString(), "_blank");
//   };

  //filtering data
  const filteredData = legalData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on company Name and company email
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchLegalData();
  }, []);
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Profile/ Legal Wing"
              backUrl="/master-marketing-interest"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By company name/ company email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Date
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Company Details
                    </th>
                   
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      contact Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Address
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                    Purpose of Query 
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => (
                      <tbody className="text-center">
                        <tr key="index">
                          <td>
                            {filteredData.length - indexOfFirstItem - index}.
                          </td>
                          <td>
                            <h6 className="text-success text-nowrap">
                              {formatDateTimeStandard(data.createdAt)}
                            </h6>
                          </td>
                          <td>
                            <div>{data.companyName} </div>
                          </td>
                          <td>
                            {data.name}<br/>
                            {data.email}<br/>
                            {data.phoneNo}<br/>
                          </td>
                          <td>
                            Country: {data.country}
                            <br />City: {data.city}
                            <br />State: {data.state}
                          </td>

                          <td>
                            {data.query}<br/>
                            {data.topic}<br/>
                            {data.userMessage}<br/>

                          </td>
                          <td>
                            <>
                              {data.isApproved === "Approved" && (
                                <Button
                                  className="btn-sm"
                                //   onClick={() => handleRegisterClick(data)}
                                >
                                  Register Now
                                </Button>
                              )}
                              <h6
                                className={
                                  data.isApproved === "Rejected"
                                    ? "fw-semibold text-danger"
                                    : "fw-semibold text-success"
                                }
                              >
                                {data.isApproved}
                              </h6>

                              {data.isApproved === "Approved" ||
                              data.isApproved === "Rejected" ? (
                                <>
                                  <h6>
                                    Remark :{" "}
                                    <span className="text-secondary">
                                        {console.log(data)}
                                      {data.remark}
                                    </span>
                                  </h6>
                                </>
                              ) : (
                                <>
                                  <div className="max-content">
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      rows={3}
                                      maxLength={50}
                                      onChange={(e) =>
                                        handleInputChange(
                                          data._id,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      value={data.remark}
                                      name="remark"
                                    />

                                    {data.remark && (
                                      <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                        {appDisApi ? (
                                          <Spinner animation="border text-success" />
                                        ) : (
                                          <>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <AiFillCheckCircle
                                                className="w-100 icon-size green-color"
                                                // onClick={() =>
                                                //   handleApproveRejectTrialUser(
                                                //     data._id,
                                                //     true
                                                //   )
                                                // }
                                              />
                                            </Button>
                                            <Button
                                              className="m-0 p-0 border-none"
                                              style={{
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <AiFillCloseCircle
                                                className="w-100 icon-size text-danger"
                                                // onClick={() =>
                                                //   handleApproveRejectTrialUser(
                                                //     data._id,
                                                //     false
                                                //   )
                                                // }
                                              />
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={11} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LegalWingRequest;
