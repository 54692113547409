import React from "react";
import Employees from "../../assets/masterImgs/employees.jpg";
import { HiBackward } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getAuthUserCookie } from "../../store/services";

const DashboardHeader = ({ heading, backUrl }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("authToken");
    Cookies.remove("userId");
    Cookies.remove("userDetails");

    navigate("/login");
  };

  const handleDashClick = () => {
    if (getAuthUserCookie().role === "master") {
      navigate("/master-dashboard");
    } else if (getAuthUserCookie().role === "employee") {
      navigate("/employee-dashboard");
    } else {
      alert("something went wrong.");
    }
  };

  return (
    <div>
      <>
        <div className="d-flex align-items-center justify-content-between w-100 mb-3">
          <div
            className="text-spacing fs-1 fw-medium cursor-pointer"
            style={{ textDecoration: "none", color: "#2c2392" }}
            onClick={handleDashClick}
          >
            DASHBOARD
          </div>
          <div>
            <img src={Employees} style={{ height: "8vh" }} alt="" srcset="" />{" "}
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between w-100">
          
            <h4 className="mb-2">{heading}</h4>
          <div className="w-50 d-flex justify-content-end gap-3">
            <button
              type="button"
              className="bg-color mt-4 p-2 px-3 rounded-1 border-0 d-flex align-items-center gap-1"
              onClick={() => navigate(backUrl)}
            >
              <div className="icon">
                <HiBackward style={{ fontSize: "1.5em" }} />
              </div>
              BACK
            </button>

            <button
              type="button"
              className="bg-color-2 mt-4 p-2 px-3 rounded-1"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default DashboardHeader;
