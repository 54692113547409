import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";
import { getURLbyEndPoint } from "../../../store/api";
import axios from "axios";

const MasterProfile = () => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);

  const fetchNotification = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getNotificationStatus")
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data;
        setNotificationData(data);
      } else {
        alert("Failed to fetch notification data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);
  return (
    <>
      <Layout>
        <MasterNavigationBar>
          <div className="d-flex flex-column px-3">
            <DashboardHeader heading="PROFILE" backUrl="/master-dashboard" />
            <hr />
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: "100%" }}
            >
              <hr className="flex-grow-1" />

              <h2 className="mx-4 text-info">Sign-Up Form</h2>
              <hr className="flex-grow-1" />
            </div>


            <div className="d-flex justify-content-around mb-2">
              <button
                onClick={() => {
                  navigate("/master-profile-client-admin");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("ClientAdmin")
                }
              >
                CLIENT ADMIN
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.clientAdmin !== 0
                    ? notificationData.clientAdmin
                    : null}
                </span>
              </button>
              <button
                onClick={() => {
                  navigate("/master-profile-super-admin");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("ClientSuperAdmin")
                }
              >
                CLIENT SUPER ADMIN{" "}
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.clientSuperAdmin !== 0
                    ? notificationData.clientSuperAdmin
                    : null}
                </span>
              </button>
            </div>

            <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => navigate("/master-services-collab-portal")}
              disabled={
                !getAuthUserCookie().accessList.includes("CollabrationPortal")
              }
            >
              COLLABORATION PORTAL{" "}
            </button>
              <button
                onClick={() => {
                  navigate("/master-marketing-interest");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
              >
                MARKETING INTEREST
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.MarInterest !== 0
                    ? notificationData.MarInterest
                    : null}
                </span>
              </button>
            </div>
            <hr />
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: "100%" }}
            >
              <hr className="flex-grow-1" />
              <h2 className="mx-4 text-info">Profile Updation</h2>
              <hr className="flex-grow-1" />
            </div>

            <div className="d-flex justify-content-around mb-2">
              <button
                onClick={() => {
                  navigate("/master-profile-personal-details");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("PersonalDetails")
                }
              >
                PERSONAL DETAILS
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.personalDet !== 0
                    ? notificationData.personalDet
                    : null}
                </span>
              </button>
              <button
                onClick={() => {
                  navigate("/master-profile-company-details");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("CompanyDetails")
                }
              >
                COMPANY DETAILS
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.comDet !== 0
                    ? notificationData.comDet
                    : null}
                </span>
              </button>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              <button
                onClick={() => {
                  navigate("/master-profile-marketing-info");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("MarketingInfo")
                }
              >
                MARKETING INFO & SUBSCRIPTIONS
                <span
                  className="badge bg-primary position-absolute fs-6"
                  style={{ top: "-8px", right: "-8px" }}
                >
                  {notificationData.markInfo !== 0
                    ? notificationData.markInfo
                    : null}
                </span>
              </button>
              <button
                onClick={() => {
                  navigate("/master-profile-change-password");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("ChangePassword")
                }
              >
                CHANGE PASSWORD
                {notificationData.changePass !== 0 ? (
                  <span
                    className="badge bg-primary position-absolute fs-6"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData.changePass}
                  </span>
                ) : null}
              </button>
            </div>
            <hr />
          </div>
        </MasterNavigationBar>
      </Layout>
    </>
  );
};

export default MasterProfile;
