import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { useNavigate } from "react-router-dom";
import { getAuthUserCookie } from "../../../store/services";

const MasterArbitration = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="ARBITRATION CENTER"
            backUrl="/master-dashboard"
          />
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              // onClick={() => {
              //   navigate("/publication");
              // }}
              disabled={
                !getAuthUserCookie().accessList.includes("RuleAndPolicy")
              }
            >
              RULE & POLICIES
            </button>

            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              // onClick={() => {
              //   navigate("/ad-booking");
              // }}
              disabled={
                !getAuthUserCookie().accessList.includes("PanelName")
              }
            >
              PANEL NAME
            </button>
          </div>

          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/raise-your-dispute");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("RaiseYourDispute")
              }
            >
              RAISE YOUR DISPUTE
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              // onClick={() => {
              //   navigate("/ad-booking");
              // }}
              disabled={
                !getAuthUserCookie().accessList.includes("ListOfAgreements")
              }
            >
              LIST OF AGREEMENTS
            </button>
          </div>
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterArbitration;
