import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { getAuthUserCookie } from "../../../store/services";

const MasterHrDepartment = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="Hr Department"
            backUrl="/master-dashboard"
          />

          <hr />
          <div className="d-flex justify-content-center w-100 mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => navigate("/master-hr-jd-list")}
                disabled={!getAuthUserCookie().accessList.includes("EmpJdList")}
            >
              EMPLOYEE JD LIST
            </button>
          </div>
          <hr />
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterHrDepartment;
