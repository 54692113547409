import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { getAuthUserCookie } from "../../../store/services";

const MasterReportAnalysis = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="Report & Analysis"
            backUrl="/master-dashboard"
          />
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => navigate("/master-reports-dsr")}
              disabled={!getAuthUserCookie().accessList.includes("DSR")}
            >
              D.S.R. (DAILY SALES REPORT)
            </button>
          </div>
          <hr />

          <div className="mb-4">
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/master-reports")} 
                disabled={!getAuthUserCookie().accessList.includes("Reports")}
              >
                REPORTS
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/master-analysis")}
                disabled={
                  !getAuthUserCookie().accessList.includes("Analysis")
                }
              >
                ANALYSIS
              </button>
            </div>
            <hr />
          </div>
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterReportAnalysis;
