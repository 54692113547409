import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { Form, Table, Pagination } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { TruncatedText } from "../../../components/basic/textcustomized";

const CommertialDir = () => {
  const [commertialDirData, setCommertialDirData] = useState([]);
  const [RequestStatus, setRequestStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const handleUpdateRejectComDir = async (userId, status) => {
    try {
      const response = await axios.put(
        getURLbyEndPoint("updateRejectComDir") + userId,
        { status }
      );
      if (response.data.status) {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      alert("Internal Server Error");
      console.error("Error updating/rejecting:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getCommercialDirData")
      );
      if (response.status === 200) {
        const data = response.data;
        setCommertialDirData([...data.data]);
      } else {
        console.error("Failed to fetch commercial directory data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = commertialDirData.filter((data) => {
    if (RequestStatus === "") return true; // Show all data
    return data.isApproved === RequestStatus; // Filter based on status
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="d-flex justify-content-center align-items-start">
        <div className="py-1 align-items-center justify-content-center main-width">
          <hr />
          <DashboardHeader
            heading="Services/ Commercial Directory"
            backUrl="/master-services"
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex gap-3">
              <Form.Check
                inline
                label="All"
                name="group1"
                type="radio"
                defaultChecked
                onChange={() => setRequestStatus("")}
              />
              <Form.Check
                inline
                label="Processing"
                name="group1"
                type="radio"
                onChange={() => setRequestStatus("Processing")}
              />
              <Form.Check
                inline
                label="Approved"
                name="group1"
                type="radio"
                onChange={() => setRequestStatus("Approved")}
              />
              <Form.Check
                inline
                label="REJECTED"
                name="group1"
                type="radio"
                onChange={() => setRequestStatus("Rejected")}
              />
            </div>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search Company Name"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Table responsive striped bordered hover className="text-center">
            <thead>
              <tr>
                <th className="align-middle">SR NO.</th>
                <th className="align-middle">User</th>
                <th className="align-middle">Details-1</th>
                <th className="align-middle">Details-2</th>
                <th className="align-middle">Status & Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData) && currentData.length > 0 ? (
                <>
                  {currentData.map((data, index) => (
                    <tr key={index}>
                      <th className="text-start">
                        {index + 1}.{" "}
                        <div className="max-content">
                          Created at:
                          <br />
                          <span className="text-success">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(data.createdAt))}
                          </span>
                        </div>
                      </th>
                      <td>
                        <div className="text-start max-content">
                          <h6>
                            Company Name :{" "}
                            <span className="text-secondary">
                              {" "}
                              {data.clientCompanyName}

                            </span>
                          </h6>
                          <h6>
                            Membership No. :{" "}
                            <span className="text-secondary">
                              {data.clientMemberShipNo}
                            </span>
                          </h6>
                        </div>
                      </td>
                      <td>
                        <div className="text-start max-content">
                          <a href={data.companyLogo} target="_blank">
                            Company Logo
                          </a>{" "}
                          <h6 className="mt-1">
                            Company Name :{" "}
                            <span className="text-secondary">
                              {data.companyName}
                            </span>
                          </h6>
                          <h6>
                            Owner's Name :{" "}
                            <span className="text-secondary">
                              {data.ownersName}
                            </span>
                          </h6>{" "}
                          <h6>
                            email :{" "}
                            <span className="text-secondary">{data.email}</span>
                          </h6>{" "}
                        </div>
                      </td>

                      <td>
                        <div className="text-start max-content">
                          <h6>
                            Year of Establishment :{" "}
                            <span className="text-secondary">
                              {data.establishmentYear}
                            </span>
                          </h6>
                          <h6 className="d-flex">
                            <span>Company Address : </span>
                            <span className="text-secondary">
                              {" "}
                              <TruncatedText
                                text={data.companyAdd}
                                maxChars={20}
                              />
                            </span>
                          </h6>

                          <h6>
                            Mobile No. :{" "}
                            <span className="text-secondary">
                              {data.mobileNo}
                            </span>
                          </h6>
                          <h6>
                            Company Product :{" "}
                            <span className="text-secondary">
                              {data.companyProduct}
                            </span>
                          </h6>

                          <h6>
                            Company Activity :{" "}
                            <span className="text-secondary">
                              {" "}
                              <TruncatedText
                                text={data.companyActivity}
                                maxChars={20}
                              />
                            </span>
                          </h6>

                          {/* Add other address properties from data */}
                        </div>
                      </td>
                      <td>
                        {data.isApproved === "Processing" ? (
                          <div className="d-flex gap-3 w-100 mt-3 justify-content-evenly">
                            <AiFillCheckCircle
                              className="icon-size green-color cursor-pointer"
                              onClick={() =>
                                handleUpdateRejectComDir(data._id, true)
                              }
                            />{" "}
                            <AiFillCloseCircle
                              className="icon-size text-danger cursor-pointer"
                              onClick={() =>
                                handleUpdateRejectComDir(data._id, false)
                              }
                            />
                          </div>
                        ) : (
                          <div className="max-content fw-bold">
                            {data.isApproved === "Approved"
                              ? "Approved at:"
                              : "Rejected at:"}

                            <br />
                            <span className="text-danger">
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }).format(new Date(data.updatedAt))}
                            </span>
                          </div>
                        )}
                      </td>
                      {/* <td>
                            hello
                            <div className="d-flex w-100 mt-3 justify-content-evenly">
                              {data.status == "New" && (
                                <>
                                  <AiFillCheckCircle
                                    className="icon-size green-color cursor-pointer"
                                    onClick={() =>
                                      handleUpdateReject(
                                        data.clientId,
                                        data._id,
                                        "Updated"
                                      )
                                    }
                                  />
                                  <AiFillCloseCircle
                                    className="icon-size text-danger cursor-pointer"
                                    onClick={() =>
                                      handleUpdateReject(
                                        data.clientId,
                                        data._id,
                                        "Rejected"
                                      )
                                    }
                                  />
                                </>
                              )}
                              {data.status == "Updated" && (
                                <div className="max-content fw-bold align-top">
                                  Updated at:
                                  <br />
                                  <span className="text-success">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(new Date(data.updatedAt))}
                                  </span>
                                </div>
                              )}
                              {data.status == "Rejected" && (
                                <div className="max-content fw-bold">
                                  Rejected at:
                                  <br />
                                  <span className="text-danger">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(new Date(data.updatedAt))}
                                  </span>
                                </div>
                              )}
                            </div>
                          </td> */}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No matching data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination className="mt-3">
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CommertialDir;
