import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterLayout from "../../../components/Layouts/Layouts/MasterLayout";
import { AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";

const Administration = () => {
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <MasterNavigationBar>
          <div className="d-flex flex-column px-3">
            <DashboardHeader
              heading="ADMINISTRATION"
              backUrl="/master-dashboard"
            />
            <hr />
            <div className="d-flex justify-content-around mb-2">
              <button
                onClick={() => {
                  navigate("/master-profile-create-emp");
                }}
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("CreateEmpId")
                }
              >
                <div className="gap-2 d-flex justify-content-center align-items-center">
                  <span>CREATE EMPLOYEE ID </span>
                  <AiFillPlusCircle />
                </div>
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate("/master-profile-view-emp");
                }}
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                disabled={
                  !getAuthUserCookie().accessList.includes("ViewEmpList")
                }
              >
                VIEW EMPLOYEE LIST{" "}
              </button>
            </div>
            <hr />
          </div>
        </MasterNavigationBar>
      </Layout>
    </>
  );
};

export default Administration;
