import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Table, Form, Button, Pagination } from "react-bootstrap";

import { BsEye } from "react-icons/bs";
import { getURLbyEndPoint } from "../../../store/api";
import { jsPDF } from "jspdf";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import membCertSmall from "../../../assets/membershipCertTemplate/membershipCertSmallBuissness.jpg";
import membCertCorp from "../../../assets/membershipCertTemplate/membershipCertCoporate.jpg";
import membCertCorpplus from "../../../assets/membershipCertTemplate/membershipCertCoporate+.jpg";
import { formatDate } from "../profilePages/ClientSuperAdmin";
import axios from "axios";

const MembershipServices = () => {
  const [membRenewReq, setMembRenewReq] = useState([]);
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = membRenewReq.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on app NO
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handleInputChange = (e, userId, field) => {
    const updatedData = membRenewReq.map((item) =>
      item._id === userId ? { ...item, [field]: e.target.value } : item
    );

    setMembRenewReq(updatedData);
    console.log(membRenewReq);
  };

  //updating membership here
  const handleUpdateRejectMemb = async (userId, status) => {
    const apiData = membRenewReq.find((item) => item._id === userId);

    if (!apiData.membershipCertificate) {
      alert("Membership Certificate is required.");
      return;
    }

    const validationRules = {
      membershipCertificate: {
        message: "Please upload a valid PDF file below 5 MB.",
        isValid: () => {
          const isPdf =
            apiData.membershipCertificate.type === "application/pdf";
          const isSizeValid =
            apiData.membershipCertificate.size <= 5 * 1024 * 1024;
          return isPdf && isSizeValid;
        },
      },
      newMemberShipNo: {
        message: "Please enter Membership No.",
        isValid: () =>
          apiData.newMemberShipNo && apiData.newMemberShipNo.length <= 20,
      },
      validUpto: {
        message: "Please enter valid upto field.",
        isValid: () =>
          apiData.validUpto !== null && apiData.validUpto !== undefined,
      },
      remark: {
        message: "Please enter remark.",
        isValid: () => apiData.remark && apiData.remark.length <= 20,
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    const formData = new FormData();
    formData.append("status", status);
    formData.append("membershipCertificate", apiData.membershipCertificate);
    formData.append("newMemberShipNo", apiData.newMemberShipNo);
    formData.append("validUpto", apiData.validUpto);
    formData.append("remark", apiData.remark);

    try {
      console.log("data is here", formData.get("status"));
      const res = await axios.put(
        getURLbyEndPoint("updateRejectMembRenew") + userId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        window.location.reload();
        alert(res.data.message);
      }

      if (res.data.errorCode === "insufficientBalance") {
        alert("User don't have sufficient balance");
      }
    } catch (error) {
      alert("Internal Server Error");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getMembRenewReq"));
      if (response.status === 200) {
        const data = response.data;

        // Extracting properties from each object in the array
        const updatedData = data.data.map((item) => {
          const { membershipCertificate, newMemberShipNo, validUpto, remark } =
            item;

          // Returning an object with the extracted variables
          return {
            ...item,
            membershipCertificate: null,
            newMemberShipNo,
            validUpto,
            remark,
          };
        });

        console.log("Extracted Data:", updatedData);

        setMembRenewReq([...updatedData]);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    console.log(membRenewReq);
  }, []);

  const handleGenerateMembership = (userId) => {
    const updatedClientData = membRenewReq.find((user) => user._id === userId);

    // --------------------------------adding validation for fields--------------------------------------------------------------
    if (!updatedClientData) {
      alert("User not found");
      return;
    }
    if (!updatedClientData.newMemberShipNo) {
      alert("Please enter membership number");
      return;
    }
    if (!updatedClientData.validUpto) {
      alert("Please valid upto");
      return;
    }

    const membershipCertImage = {
      "Small Business (Associate)": membCertSmall,
      Corporate: membCertCorp,
      "Corporate +": membCertCorpplus,
    };

    const selectedMembership = updatedClientData.selectMembership;
    const imgSource = membershipCertImage[selectedMembership];

    if (!imgSource) {
      console.error(`Invalid membership type: ${selectedMembership}`);
      alert("Invalid membership type");
      return;
    }

    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
    });

    // Set background image
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgSource, "JPEG", 0, 0, imgWidth, imgHeight);

    pdf.setTextColor("#5d3e35");
    pdf.setFontSize(20);
    pdf.setFont("Eczar", "bold");
    const text = updatedClientData.companyName;
    const textWidth = pdf.getTextWidth(text);
    // Get the document width and calculate the center position
    const documentWidth = pdf.internal.pageSize.getWidth();
    const centerX = (documentWidth - textWidth) / 2;
    // Set the text position to the calculated center
    pdf.text(centerX, 150, text);

    //setting membership no. width
    pdf.setFontSize(16);
    pdf.setFont("Roboto", "bold");
    const membNo = updatedClientData.newMemberShipNo;
    const membNoTextWidth = pdf.getTextWidth(membNo); // Measure text width
    const membNoCenterX = 70 - membNoTextWidth / 2; // Calculate center X
    pdf.text(membNoCenterX, 200, membNo);

    // Usage example
    // const validUpto = "31st March 2026";
    const validUpto = updatedClientData.validUpto;
    const formattedDate = formatDate(validUpto);

    const validUptoTextWidth = pdf.getTextWidth(formattedDate); // Measure text width
    const validUptoCenterX = 132 - validUptoTextWidth / 2; // Calculate center X
    pdf.text(validUptoCenterX, 200, formattedDate);

    // Generate the PDF blob
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Create a URL from the blob
    // const url = URL.createObjectURL(blobPdf);

    // Open the PDF in a new window or tab
    //  window.open(url, '_blank');

    //seeting like this so state will update

    setMembRenewReq((prevClientData) =>
      prevClientData.map((user) =>
        user._id === userId
          ? {
              ...user,
              membershipCertificate: new Blob([blobPdf], {
                type: "application/pdf",
              }),
            }
          : user
      )
    );
  };

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
      <Layout>
        <div className="d-flex justify-content-center align-items-start">
          <div className="py-1 align-items-center justify-content-center main-width">
            <hr />
            <DashboardHeader
              heading="Services/ Membership Service"
              backUrl="/master-services"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />{" "}
                <Form.Check
                  inline
                  label="UPDATED"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={reqStatus === "Updated"}
                  onChange={() => setReqStatus("Updated")}
                />{" "}
                <Form.Check
                  inline
                  label="REJECTED"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />{" "}
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By company Name"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {Array.isArray(membRenewReq) && membRenewReq.length > 0 ? (
              <Table responsive striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th className="align-middle">SR NO.</th>
                    <th className="align-middle">Company Details</th>
                    <th className="align-middle">Transaction Details</th>
                    <th className="align-middle">Membership Update</th>
                    <th className="align-middle">Action</th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => (
                      <tbody className="text-center">
                        <tr key={index}>
                          <th className="text-start">
                          {filteredData.length - indexOfFirstItem - index}.{" "}
                            <div className="max-content">
                              Created at:
                              <br />
                              <span className="text-success">
                                {new Intl.DateTimeFormat("en-GB", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(new Date(data.createdAt))}
                              </span>
                            </div>
                          </th>
                          <td>
                            <div className="text-start max-content">
                              <h6>
                                Company Name :{" "}
                                <span className="text-secondary">
                                  {" "}
                                  {data.companyName}
                                </span>
                              </h6>
                              <h6>
                                Membership No. :{" "}
                                <span className="text-secondary">
                                  {data.memberShipNo}
                                </span>
                              </h6>
                              <h6>
                                Membership Type. :{" "}
                                <span className="text-secondary">
                                  {data.selectMembership}
                                </span>
                              </h6>
                            </div>
                          </td>
                          <td>
                            <div className="text-start max-content">
                              {data.utrNo && (
                                <h6>
                                  Utr No.:{" "}
                                  <span className="text-secondary">
                                    {data.utrNo}
                                  </span>
                                </h6>
                              )}
                              <h6>
                                Amount:{" "}
                                <span className="text-secondary">
                                  {data.amount}
                                </span>
                              </h6>
                              <h6>
                                Year:{" "}
                                <span className="text-secondary">
                                  {data.noOfYears}
                                </span>
                              </h6>

                              {data.paymentScreenShot && (
                                <h6>
                                  <a
                                    href={data.paymentScreenShot}
                                    target="_blank"
                                  >
                                    Payment ScreenShot
                                  </a>{" "}
                                </h6>
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex w-100 justify-content-evenly">
                              {data.isApproved == "Processing" ? (
                                <div>
                                  <Form.Control
                                    type="text"
                                    className="mb-2"
                                    maxLength={20}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        data._id,
                                        "newMemberShipNo"
                                      )
                                    }
                                    placeholder="Membership Number"
                                    name="memberShipNo"
                                    value={data.newMemberShipNo}
                                    style={{ display: "block" }}
                                  />
                                  <Form.Control
                                    type="date"
                                    name="validUpto"
                                    value={data.validUpto}
                                    className="mb-2"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        data._id,
                                        "validUpto"
                                      )
                                    }
                                  />
                                  {/* <div>
                                      <Button
                                        variant={
                                          data.membershipCertificate
                                            ? "success"
                                            : "primary"
                                        }
                                        className="py-2 mb-2 border-none w-100 fw-semibold"
                                        onClick={() => {
                                          document.getElementById(
                                            "fileInput"
                                          ).dataset.userId = data._id;
                                          document
                                            .getElementById("fileInput")
                                            .click();
                                        }}
                                      >
                                        {data.membershipCertificate
                                          ? "Uploaded"
                                          : "Upload Certificate"}
                                      </Button>
                                      <Form.Control
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => {
                                          handleFileChange(e);
                                        }}
                                        id="fileInput"
                                        style={{ display: "none" }}
                                      />
                                    </div> */}
                                  <div>
                                    {data.selectMembership !==
                                      "Digital User" && (
                                      <Button
                                        variant={
                                          data.membershipCertificate
                                            ? "success"
                                            : "primary"
                                        }
                                        onClick={() =>
                                          handleGenerateMembership(data._id)
                                        }
                                      >
                                        Generate Certificate
                                      </Button>
                                    )}{" "}
                                    {data.membershipCertificate && (
                                      <a
                                        href={URL.createObjectURL(
                                          data.membershipCertificate
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <BsEye className="icon-size" />
                                      </a>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="text-start max-content">
                                  <h6>
                                    Membership No. :{" "}
                                    <span className="text-secondary">
                                      {data.memberShipNo}
                                    </span>
                                  </h6>
                                </div>
                              )}
                            </div>

                            {/* <div className="max-content">
                          Updated at:
                          <br />
                          <span className="text-success">
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(data.updatedAt))}
                          </span>
                        </div> */}
                          </td>
                          <td>
                            <div className="text-center">
                              {data.isApproved === "Processing" && (
                                <div className="max-content">
                                  <Form.Control
                                    as="textarea"
                                    rows={1}
                                    placeholder="Enter a Remark"
                                    onChange={(e) =>
                                      handleInputChange(e, data._id, "remark")
                                    }
                                    value={data.remark}
                                    rows={2}
                                    name="remark"
                                    maxLength={75}
                                  />{" "}
                                </div>
                              )}

                              {data.remark &&
                              data.isApproved === "Processing" ? (
                                <div className="d-flex justify-content-evenly mt-3">
                                  <AiFillCheckCircle
                                    className="icon-size green-color cursor-pointer"
                                    onClick={() =>
                                      handleUpdateRejectMemb(data._id, true)
                                    }
                                  />
                                  <AiFillCloseCircle
                                    className="icon-size text-danger cursor-pointer"
                                    onClick={() =>
                                      handleUpdateRejectMemb(data._id, false)
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                            {data.isApproved !== "Processing" && (
                              <h6>
                                Remark :{" "}
                                <span className="text-secondary">
                                  {" "}
                                  {data.remark}
                                </span>
                              </h6>
                            )}

                            {data.isApproved == "Updated" && (
                              <div className="max-content fw-bold align-top">
                                Updated at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.updatedAt))}
                                </span>
                              </div>
                            )}
                            {data.isApproved == "Rejected" && (
                              <div className="max-content fw-bold">
                                Rejected at:
                                <br />
                                <span className="text-danger">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.updatedAt))}
                                </span>
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            ) : (
              <p>No data available</p>
            )}

            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MembershipServices;

{
  /* <Layout>
        <div className="d-flex justify-content-center align-items-start">
          <div className="py-1 align-items-center justify-content-center main-width">
            <hr />
            <DashboardHeader
              heading="Services/ Membership Service"
              backUrl="/master-services"
            />
            <hr />
            <Table
              responsive
              striped
              bordered
              hover
              className="mt-4 text-center"
            >
              <thead>
                <tr>
                  <th className="centerd-table">Sr No.</th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Company Name
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Membership No.
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Selected Membership
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    valid upto{" "}
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Uploads
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Days Left
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Extend & Mail Remainder
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Refrence No{" "}
                  </th>
                  <th
                    className="align-middle"
                    style={{ minWidth: "max-content" }}
                  >
                    Issue Date & Time{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Abdul Bhai</td>
                  <td>AECCI/1234</td>
                  <td>Small Buissness</td>
                  <td>31st March 2023</td>
                  <td>
                    <div className="text-center">
                      <Form>
                        <div>
                          <Form.Control
                            placeholder="Name"
                            className="mb-2"
                            type="text"
                          />
                          <Form.Control
                            placeholder="Member"
                            className="mb-2"
                            type="text"
                          />
                          <Form.Control
                            placeholder="Memb.no."
                            className="mb-2"
                            type="text"
                          />
                          <Form.Control
                            placeholder="Valid upto"
                            className="mb-2"
                            type="text"
                          />
                          <Button
                            variant="primary"
                            className="w-100 mb-2 rounded-5 border-none bg-color"
                          >
                            Upload Signature
                            <input type="file" style={{ display: "none" }} />
                          </Button>{" "}
                          <Button
                            variant="primary"
                            className="w-100 mb-2 rounded-5 border-none bg-color"
                          >
                            QR Code
                            <input type="file" style={{ display: "none" }} />
                          </Button>{" "}
                        </div>
                        <div className="d-flex gap-1">
                          <Button className="w-75 py-2 text-light rounded-5 bg-success">
                            Generate
                          </Button>
                          <div className="w-25">
                            <BsEye className="icon-size" />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </td>

                  <td> 45</td>
                  <td>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ minWidth: "max-content" }}
                    >
                      <h5>Extend Upto</h5>
                      <Button className="w-75 py-1 text-light rounded-5 bg-success mb-1">
                        5 days
                      </Button>
                      <Button className="w-75 py-1 text-light rounded-5 bg-success">
                        10 days
                      </Button>
                      <h5>Reminder</h5>
                      <Button className="w-75 py-1 text-light rounded-5 bg-success mb-1">
                        30 days
                      </Button>
                      <Button className="w-75 py-1 text-light rounded-5 bg-success mb-1">
                        15 days
                      </Button>{" "}
                      <Button className="w-75 py-1 text-light rounded-5 bg-success mb-1">
                        7 days
                      </Button>
                      <Button className="w-75 py-1 text-light rounded-5 bg-success">
                        1 days
                      </Button>
                    </div>
                  </td>
                  <td>876548</td>
                  <td>03.01.2023</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Layout> */
}
