import React, { useState, useEffect } from "react";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";

import { Table, Button, Modal, Form, Pagination } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import {
  PopUpModal,
  PopUpModalPriceRecord,
} from "../../../components/basic/fileViewModal";
import { BsFillInfoCircleFill } from "react-icons/bs";

const PriceControl = () => {
  const [priceControlData, setPriceControlData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  //extra features------------------------------
  // ----------------------------------------------
  const handleEditClick = (userId, field) => {
    // Find the item by its userId
    const updatedData = priceControlData.map((item) => {
      if (item._id === userId) {
        return { ...item, [field]: true }; // Use computed property name to set the property dynamically
      }
      return item;
    });
    setPriceControlData(updatedData); // Update the state with the modified data
  };

  const handleEditClickCancel = (userId, field) => {
    // Find the item by its userId
    const updatedData = priceControlData.map((item) => {
      if (item._id === userId) {
        return { ...item, [field]: false }; // Use computed property name to set the property dynamically
      }
      return item;
    });
    setPriceControlData(updatedData); // Update the state with the modified data
  };

  const handleChangePriceControl = (userId, fieldName, value) => {
    // Find the item by its userId

    if (value === "") {
      value = 0;
    }

    const updatedData = priceControlData.map((item) => {
      if (item._id === userId) {
        return { ...item, [fieldName]: parseInt(value, 10) }; // Use computed property name to set the property dynamically
      }
      return item;
    });
    setPriceControlData(updatedData); // Update the state with the modified data
  };

  // const handleAccessListChange = async (e, accessType, id) => {
  //   const isChecked = e.target.checked;
  //   const updatedEmpList = ExtraAccessControl.map((employee) => {
  //     if (employee._id === id) {
  //       const newAccessList = isChecked
  //         ? [...employee.accessList, accessType]
  //         : employee.accessList.filter((item) => item !== accessType);
  //       return { ...employee, accessList: newAccessList };
  //     }
  //     return employee;
  //   });
  //   setExtraAccessControl(updatedEmpList);
  // };

  // //chnaging acces for users------------------------------
  // --------------------------------------------------------
  // --------------------------------------------------------
  const handleUpdatePrice = async (userId) => {
    const userData = priceControlData.find((emp) => emp._id === userId);

    if (!userData) {
      alert("Something went Wrong");
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPoint("updatePriceControl") + userId,
        {
          ...userData,
        }
      );

      if (res.data.status) {
        alert("updated successfully");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error(error);
      alert("something went wrong when updating");
    }
  };

  const fetchPriceControlData = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getPriceControlsMaster")
      );
      if (response.status === 200) {
        const data = response.data.data.map((item) => ({
          ...item,
          isEditingECO: false,
          isEditingMCO: false,
          isEditingNonStand: false,
        }));
        setPriceControlData(data);
      } else {
        alert("Failed to fetch co data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPriceControlData();
  }, []);

  const filteredData = priceControlData.filter((data) => {
    if (
      searchTerm !== "" &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="Price Control"
            backUrl="/special-controls"
          />
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search Company Email"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>User Details</th>
                <th>Electronic-CO</th>
                <th>Semi-Electronic-CO</th>
                <th>Non-Standard</th>
              </tr>
            </thead>
            {currentFiltered.length > 0 ? (
              <>
                {currentFiltered.map((data, index) => (
                  <tbody>
                    <tr key="index">
                      <td>
                        {" "}
                        {filteredData.length - indexOfFirstItem - index}.
                      </td>

                      <td>
                        <span>{data.email}</span>
                        <br />
                        {data.updateList.length > 0 && (
                          <>
                            <h6 className="mb-2">List Of Changes:</h6>
                            {data.updateList.map((updateListData, index) => (
                              <>
                                <div key={index}>
                                  <PopUpModalPriceRecord
                                    title={new Date(
                                      updateListData.timestamp
                                    ).toLocaleString()}
                                    updateListData={updateListData.data}
                                  />
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </td>

                      <td>
                        {ecoDoc.map((ecoData, index) => (
                          <>
                            {data.isEditingECO ? (
                              <div className="d-flex align-items-center justify-content-between">
                                <h6 className="no-wrap">{ecoData.name}:</h6>
                                <Form.Control
                                  type="text"
                                  style={{ width: "55px" }}
                                  maxLength={3}
                                  value={data[ecoData.value]}
                                  onChange={(e) =>
                                    handleChangePriceControl(
                                      data._id,
                                      ecoData.value,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <h6 className="no-wrap">
                                {ecoData.name}: {data[ecoData.value]}
                              </h6>
                            )}
                          </>
                        ))}

                        {data.isEditingECO ? (
                          <div className="d-flex gap-2 mt-2">
                            <Button
                              className="m-0 text-nowrap text-white rounded small-font"
                              variant="danger"
                              onClick={() =>
                                handleEditClickCancel(data._id, "isEditingECO")
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              varient="success"
                              className="m-0 text-nowrap text-white rounded small-font"
                              onClick={() => handleUpdatePrice(data._id)}
                            >
                              UPDATE
                            </Button>
                          </div>
                        ) : (
                          <Button
                            className="m-0 text-nowrap text-white rounded small-font"
                            onClick={() =>
                              handleEditClick(data._id, "isEditingECO")
                            }
                          >
                            EDIT
                          </Button>
                        )}
                      </td>
                      <td>
                        <div className="d-flex justify-content-between gap-3">
                          <div>
                            {mcoDoc.map((mcoData, index) => (
                              <>
                                {data.isEditingMCO ? (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="no-wrap">{mcoData.name}:</h6>
                                    <Form.Control
                                      type="text"
                                      style={{ width: "55px" }}
                                      maxLength={3}
                                      value={data[mcoData.value]}
                                      onChange={(e) =>
                                        handleChangePriceControl(
                                          data._id,
                                          mcoData.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <h6 className="no-wrap">
                                    {mcoData.name}: {data[mcoData.value]}
                                  </h6>
                                )}
                              </>
                            ))}
                          </div>

                          <div>
                            {mcoDocPC.map((mcoData, index) => (
                              <>
                                {data.isEditingMCO ? (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="no-wrap">{mcoData.name}:</h6>
                                    <Form.Control
                                      type="text"
                                      style={{ width: "55px" }}
                                      maxLength={3}
                                      value={data[mcoData.value]}
                                      onChange={(e) =>
                                        handleChangePriceControl(
                                          data._id,
                                          mcoData.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <h6 className="no-wrap">
                                    {mcoData.name}: {data[mcoData.value]}
                                  </h6>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                        {data.isEditingMCO ? (
                          <div className="d-flex gap-2 mt-2">
                            <Button
                              className="m-0 text-nowrap text-white rounded small-font"
                              variant="danger"
                              onClick={() =>
                                handleEditClickCancel(data._id, "isEditingMCO")
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              varient="success"
                              className="m-0 text-nowrap text-white rounded small-font"
                              onClick={() => handleUpdatePrice(data._id)}
                            >
                              UPDATE
                            </Button>
                          </div>
                        ) : (
                          <Button
                            className="m-0 text-nowrap text-white rounded small-font"
                            onClick={() =>
                              handleEditClick(data._id, "isEditingMCO")
                            }
                          >
                            EDIT
                          </Button>
                        )}
                      </td>
                      <td>
                        {nonStandDoc.map((nonStandData, index) =>
                          data.isEditingNonStand ? (
                            <div
                              key={index}
                              className="d-flex align-items-center justify-content-between"
                            >
                              <h6 className="no-wrap">{nonStandData.name}:</h6>
                              <Form.Control
                                type="text"
                                style={{ width: "55px" }}
                                maxLength={3}
                                value={data[nonStandData.value]}
                                onChange={(e) =>
                                  handleChangePriceControl(
                                    data._id,
                                    nonStandData.value,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <h6 key={index} className="no-wrap">
                              {nonStandData.name}: {data[nonStandData.value]}
                            </h6>
                          )
                        )}

                        {data.isEditingNonStand ? (
                          <div className="d-flex gap-2 mt-2">
                            <Button
                              className="m-0 text-nowrap text-white rounded small-font"
                              variant="danger"
                              onClick={() =>
                                handleEditClickCancel(
                                  data._id,
                                  "isEditingNonStand"
                                )
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              varient="success"
                              className="m-0 text-nowrap text-white rounded small-font"
                              onClick={() => handleUpdatePrice(data._id)}
                            >
                              UPDATE
                            </Button>
                          </div>
                        ) : (
                          <Button
                            className="m-0 text-nowrap text-white rounded small-font"
                            onClick={() =>
                              handleEditClick(data._id, "isEditingNonStand")
                            }
                          >
                            EDIT
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}{" "}
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={12} className="text-center">
                    No Matching Data
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default PriceControl;

//change price modal
// function ChangePrice() {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button
//         className="rounded-4 border-none"
//         type="button"
//         style={{
//           padding: "6px 20px",
//           backgroundColor: "#0b3b5d",
//           color: "white",
//           fontSize: "14px",
//         }}
//         onClick={handleShow}
//         //   onClick={() =>
//         //     handleUpdateSpecialAccessControl(data._id)
//         //   }
//       >
//         EDIT
//       </Button>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Pricing Change</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="d-flex align-items-center">
//             <h6 className="me-3">E-CO : </h6> <Form.Control className="w-50" />
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

//list of fields-------------------------------------------------------
//eco documents
// List for data.isEditingECO condition
export const ecoDoc = [
  {
    name: "Eco Price",
    value: "eco",
  },
  {
    name: "Commercial Invoice",
    value: "comInvoiceEco",
  },
  {
    name: "Packing List",
    value: "packListEco",
  },
  {
    name: "Additional Document",
    value: "addDocEco",
  },
  {
    name: "Processing Charges",
    value: "ecoPc",
  },
];

//mco docuemnts
// List for data.isEditingMCO condition
export const mcoDoc = [
  {
    name: "Semi-Eco Price",
    value: "semiEco",
  },
  {
    name: "Commercial Invoice",
    value: "comInvoiceMco",
  },
  {
    name: "Packing List",
    value: "packListMco",
  },
  {
    name: "Additional Document",
    value: "addDocMco",
  },
  {
    name: "Intercity/Gujrat",
    value: "interGujDelMode",
  },
  {
    name: "Pan India",
    value: "panIndDelMode",
  },
];

//mco processing chrgesss

// List for data.isEditingMCO condition
export const mcoDocPC = [
  {
    name: "Semi-ECO(PC)",
    value: "mcoPc",
  },
  {
    name: "Commercial Invoice (PC)",
    value: "mcoComInvoicePc",
  },
  {
    name: "Packing List (PC)",
    value: "mcoPackListPc",
  },
  {
    name: "Additional Document (PC)",
    value: "mcoAddDocPc",
  },
];

//non standard documents
export const nonStandDoc = [
  {
    name: "A. Commercial Document",
    value: "A_ComDoc",
  },
  {
    name: "B. MCA Document",
    value: "B_McaDoc",
  },
  {
    name: "C. Export Permission related Document",
    value: "C_ExpDoc",
  },
  {
    name: "D. Pharma Reg. Document",
    value: "D_PharmDoc",
  },
  {
    name: "E. Agro Chemical",
    value: "E_AgroDoc",
  },
  {
    name: "F. Other document",
    value: "F_OtherDoc",
  },
  {
    name: "Processing Charges",
    value: "nonStandPc",
  },
];
