import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterServicesLayout from "../../../components/Layouts/Layouts/MasterServicesLayout";
import Layout2 from "../../../components/Layouts/Layouts/MasterLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
// import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/fa6";
import Pagination from "react-bootstrap/Pagination";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

const MarketingInfo = () => {
  const [marketingInfoData, setMarketingInfoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const fetchMarketingInfoData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getMarketingInfo"));
      if (response.status === 200) {
        const data = response.data;
        const latestData = data.data.reverse()
        setMarketingInfoData([...latestData]);
        console.log("Marketing Info", data.data);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMarketingInfoData();
  }, []);

  // Calculate current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMarketingInfoData = marketingInfoData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(marketingInfoData.length / itemsPerPage);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleUpdateRejectMarInfo = async (userId, status) => {
    try {
      const response = await axios.put(
        getURLbyEndPoint("updateRejectMarInfo") + userId,
        {
          status,
        }
      );

      if (response.data.status) {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      alert("Internal Server Error");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };
  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Profile/ Marketing Information"
            backUrl="/master-profile"
          />
          <hr />
          <Table striped bordered hover className="text-center">
            <thead>
              <tr>
                <th className="align-middle">SR NO.</th>
                <th className="align-middle">Company Details</th>
                <th className="align-middle">Requests</th>
                <th className="align-middle">Subscriptions</th>

                <th className="align-middle">Action & Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {currentMarketingInfoData
                // .slice()
                // .reverse()
                .map((data, relativeIndex) => (
                  <tr key={indexOfFirstItem + relativeIndex}>
                    <td className="fw-semibold">
                      {indexOfFirstItem + relativeIndex + 1}.
                    </td>
                    <td>
                      <div className="text-start max-content">
                        <h6>
                          Company Name :{" "}
                          <span className="text-secondary">
                            {" "}
                            {data.companyName}
                          </span>
                        </h6>
                        <h6>
                          Membership No. :{" "}
                          <span className="text-secondary">
                            {data.memberShipNo}
                          </span>
                        </h6>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="text-start max-content">
                        <div className="d-flex justify-content-between ">
                          <h6>emails : </h6>
                          <span className="text-secondary ms-2">
                            {data.emails ? (
                              <AiFillCheckCircle className="green-color icon-size-2" />
                            ) : (
                              <AiFillCloseCircle className="text-danger icon-size-2" />
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h6> Posts : </h6>
                          <span className="text-secondary ms-2">
                            {data.posts ? (
                              <AiFillCheckCircle className="green-color icon-size-2" />
                            ) : (
                              <AiFillCloseCircle className="text-danger icon-size-2" />
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h6> calls : </h6>
                          <span className="text-secondary ms-2">
                            {data.calls ? (
                              <AiFillCheckCircle className="green-color icon-size-2" />
                            ) : (
                              <AiFillCloseCircle className="text-danger icon-size-2" />
                            )}
                          </span>
                        </div>
                      </div>
                    </td>{" "}
                    <td>
                      {" "}
                      <div className="text-start d-flex justify-content-around">
                        <div className="me-3">
                          <div className="d-flex justify-content-between">
                            <h6> campaigns : </h6>
                            <span className="text-secondary ms-2">
                              {data.campaigns ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6> awards : </h6>
                            <span className="text-secondary ms-2">
                              {data.awards ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6> weeklyBullets : </h6>
                            <span className="text-secondary ms-2">
                              {data.weeklyBullets ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6> eventUpdates : </h6>
                            <span className="text-secondary ms-2">
                              {data.eventUpdates ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex justify-content-between">
                            <h6> magzine : </h6>
                            <span className="text-secondary ms-2">
                              {data.magzine ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6> newsLetters : </h6>
                            <span className="text-secondary ms-2">
                              {data.newsLetters ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>{" "}
                          <div className="d-flex justify-content-between">
                            <h6> sponsorship : </h6>
                            <span className="text-secondary ms-2">
                              {data.sponsorship ? (
                                <AiFillCheckCircle className="green-color icon-size-2" />
                              ) : (
                                <AiFillCloseCircle className="text-danger icon-size-2" />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {" "}
                      {data.IsApproved === null ? (
                        <div className="d-flex justify-content-around">
                          <AiFillCheckCircle
                            className="icon-size green-color cursor-pointer"
                            onClick={() =>
                              handleUpdateRejectMarInfo(data._id, true)
                            }
                          />
                          <AiFillCloseCircle
                            className="icon-size text-danger cursor-pointer"
                            onClick={() =>
                              handleUpdateRejectMarInfo(data._id, false)
                            }
                          />
                        </div>
                      ) : (
                        <div className="fw-semibold text-success">
                          <h6 className="fw-bold">
                            {data.IsApproved ? "Accepted At:" : "Rejected At:"}
                          </h6>
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          }).format(new Date(data.updatedAt))}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {totalPages > 1 && (
            <Pagination>
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {Array.from(
                { length: Math.ceil(marketingInfoData.length / itemsPerPage) },
                (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(marketingInfoData.length / itemsPerPage)
                }
              />{" "}
            </Pagination>
          )}
        </div>
      </div>
    </Layout>
  );
};


export default MarketingInfo;
