import React from "react";

const Message = ({ align, text, time }) => {
  // Determine the message alignment based on the 'align' prop
  const messageStyle = {
    backgroundColor: align === "Sent" ? "#ececec" : "#63b190",
    color: align === "Sent" ? "black" : "white",

    borderRadius:
      align === "Sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "75%",
    alignSelf: align === "Sent" ? "flex-end" : "flex-start", // Align self based on prop
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "Sent" ? "black" : "white",
    textAlign: "right", // Adjust textAlign based on alignment
    marginTop: "auto", // Pushes the time to the bottom
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "Sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <span>{text}</span>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};

export default Message;
