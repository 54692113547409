import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { useNavigate } from "react-router-dom";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";

const MasterServices = () => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);

  const fetchNotification = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getNotificationStatus")
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data;
        setNotificationData(data);
      } else {
        alert("Failed to fetch notification data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <Layout>
      <MasterNavigationBar>
        {" "}
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="Services" backUrl="/master-dashboard" />
          <hr />
          <div className="d-flex align-items-center">
            <hr className="flex-grow-1" />
            <h2 className="mx-3 text-info">Trade Document</h2>
            <hr className="flex-grow-1" />
          </div>
          <div className="mb-4">
            <div
              className="d-flex justify-content-around mx-auto mb-2"
              style={{ width: "85%" }}
            >
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative"
                style={{ width: "32%" }}
                onClick={() => navigate("/master-electronic-eco")}
                disabled={
                  !getAuthUserCookie().accessList.includes("ECOSuperadmin")
                }
              >
                Electronic COO (eCO)
                {notificationData.ECO !== 0 && (
                  <span
                    className="badge bg-primary position-absolute fs-6"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData.ECO}
                  </span>
                )}
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-4 rounded-1 position-relative"
                style={{ width: "32%" }}
                onClick={() => navigate("/master-semi-eco")}
                disabled={!getAuthUserCookie().accessList.includes("ECOAdmin")}
              >
                Semi-eCO
                {notificationData.MCO !== 0 && (
                  <span
                    className="badge bg-primary position-absolute fs-6"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData.MCO}
                  </span>
                )}
              </button>

              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative"
                style={{ width: "32%" }}
                onClick={() => navigate("/master-non-standard")}
                disabled={!getAuthUserCookie().accessList.includes("NonStandDoc")}
              >
                Non-Standard Doc
                {notificationData.nonstand !== 0 && (
                  <span
                    className="badge bg-primary position-absolute fs-6"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData.nonstand}
                  </span>
                )}
              </button>
            </div>
            <hr />

            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/master-eco-resources")}
                disabled={
                  !getAuthUserCookie().accessList.includes("EcoResources")
                }
              >
                Trade Document Resources{" "}
              </button>

              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/master-verification")}
                disabled={
                  !getAuthUserCookie().accessList.includes("ClientVerification")
                }
              >
                CLIENT VERIFICATION
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <hr className="flex-grow-1" />
            <h2 className="mx-3 text-info">Other Services</h2>
            <hr className="flex-grow-1" />
          </div>
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => navigate("/master-membership-services")}
              disabled={
                !getAuthUserCookie().accessList.includes("MembershipServices")
              }
            >
              MEMBERSHIP SERVICES{" "}
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.membSer !== 0
                  ? notificationData.membSer
                  : null}
              </span>
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => navigate("/master-recomendation-letter")}
              disabled={
                !getAuthUserCookie().accessList.includes("RecommendationLetter")
              }
            >
              RECOMMENDATION LETTER
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.recomLetter !== 0
                  ? notificationData.recomLetter
                  : null}
              </span>
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => navigate("/master-services-commertial-dir")}
              disabled={
                !getAuthUserCookie().accessList.includes("CommertialDir")
              }
            >
              COMMERCIAL DIRECTORY
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-center w-100 mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative"
              style={{ width: "85%" }}
              onClick={() => navigate("/master-services-payment")}
              disabled={!getAuthUserCookie().accessList.includes("Payment")}
            >
              PAYMENT
              {notificationData.payments !== 0 && (
                  <span
                    className="badge bg-primary position-absolute fs-6"
                    style={{ top: "-8px", right: "-8px" }}
                  >
                    {notificationData.payments}
                  </span>
                )}
            </button>
          </div>
          <hr />
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterServices;
