import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Col, Pagination, Row } from "react-bootstrap";
import * as XLSX from "xlsx";
import Image from "react-bootstrap/Image";
import RefExelFormat from "../../../assets/RefExelFormat.png";
import { FaFileUpload } from "react-icons/fa";

import Layout from "../../../components/Layouts/Layouts/Layout";
import {
  Nav,
  Table,
  NavLink,
  NavItem,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { country_list, nonStandDocType } from "../../../store/AllListData";

const EcoResources = () => {
  const [tabVal, setTabVal] = useState("Manual COO (NP)");
  const handleSelect = (eventKey) => {
    setTabVal(eventKey);
  };

  //add more modal setup
  const [showAddMore, setShowAddMore] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const handleCloseAddMore = () => setShowAddMore(false);
  const handleShowAddMore = () => setShowAddMore(true);

  const handleCloseImportModal = () => setShowImportModal(false);
  const handleShowImportModal = () => setShowImportModal(true);

  //officer List
  const [officerList, setOfficerList] = useState([]);
  const [coResource, setCoResource] = useState([]);
  const [addOff, setAddOff] = useState(false);
  const [offName, setOffName] = useState("");

  const handleUpdateOfficerList = async (action, value) => {
    try {
      if (!value.trim()) {
        alert("Please add officer name");
        return;
      }

      if (action === "add") {
        if (officerList.includes(value)) {
          alert("Officer name already exists");
          return;
        }
      }

      const res = await axios.put(getURLbyEndPoint("updateOfficerList"), {
        action,
        value,
      });

      if (res.data.status) {
        setOffName("");
        if (action === "add") {
          setOfficerList([...officerList, value]);
        } else if (action === "delete") {
          setOfficerList(officerList.filter((officer) => officer !== value));
        }
      }
      alert(res.data.message);
    } catch (error) {
      alert("Internal Server Error");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  // //---------------------manual entries for coo ---------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  const [formData, setFormData] = useState({
    modeOfSub: "",
    appSubDate: "",
    refNo: "",
    officerName: "",
    dateOfStamp: "",
    documentType: "",
    country: "",
  });

  //import exel in mongoDB
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImportData = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Please select a file to import.");
      return;
    }

    // Read the Excel file
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      try {
        const response = await axios.post(
          getURLbyEndPoint("uploadCoResource"),
          jsonData
        );
        if (response.status === 200) {
          alert("Data imported successfully.");
          setSelectedFile(null);
          // Fetch the updated data and update the state
          await fetchCoResource();
        } else {
          alert("Failed to import data.");
        }
      } catch (error) {
        console.error("Error importing data:", error);
        alert("Error importing data.");
      }
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleNewDocInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    console.log(formData)
  };

  const handleCreateNewCo = async () => {
    if (formData.modeOfSub === "Digital eCo (NP)") {
      formData.officerName = "Jaheer Bukhari (Chairman)";
    }
  
    const validationRules = {
      modeOfSub: {
        message: "Please enter Mode of Submission.",
        isValid: () => formData.modeOfSub.trim() !== "",
      },
      appSubDate: {
        message: "Please enter Application submitted Date.",
        isValid: () => formData.appSubDate.trim() !== "",
      },
      refNo: {
        message: "Please enter COO Ref No.",
        isValid: () => formData.refNo.trim() !== "",
      },
      officerName: {
        message: "Please select Officer Name.",
        isValid: () => formData.officerName.trim() !== "",
      },
      dateOfStamp: {
        message: "Please enter Date of Stamp.",
        isValid: () => formData.dateOfStamp.trim() !== "",
      },
    };


    if (
      formData.modeOfSub !== "Manual COO (NP)" &&
      formData.modeOfSub !== "Digital eCo (NP)"
    ) {
      validationRules.documentType = {
        message: "Please select Document Type.",
        isValid: () => formData.documentType.trim() !== "",
      };
      validationRules.country = {
        message: "Please select country.",
        isValid: () => formData.country.trim() !== "",
      };
    }
  
    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }
  
    try {
      const res = await axios.post(getURLbyEndPoint("createNewCo"), {
        ...formData,
      });
  
      if (res.data.status) {
        setFormData({
          modeOfSub: "",
          appSubDate: "",
          refNo: "",
          officerName: "",
          dateOfStamp: "",
          documentType: "", // Clear documentType after successful submission
        });
      }
  
      alert(res.data.message);
    } catch (error) {
      alert("Internal Server Error");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };
  
  
  const handleDelCoResource = async (userId) => {
    try {
      const response = await axios.delete(
        getURLbyEndPoint("delCoResource") + userId
      );
      if (response.status === 200) {
        setCoResource((prevState) =>
          prevState.filter((item) => item._id !== userId)
        );
        alert("CO Resource Deleted.");
      } else {
        alert("Failed to delete CO Resource.");
      }
    } catch (error) {
      alert("Error deleting CO Resource:", error);
    }
  };

  const fetchOfficerList = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getOfficerName"));
      if (response.status === 200) {
        const data = response.data.data;
        setOfficerList(data);
      } else {
        console.error("Failed to fetch users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCoResource = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getCoResource"));
      if (response.status === 200) {
        const data = response.data.data;
        setCoResource(data);
      } else {
        alert("Failed to fetch co resource data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOfficerList();
    fetchCoResource();
  }, []);

  // Filtered coResource based on selected tab
  const filteredCoResource = coResource.filter(
    (data) => data.modeOfSub === tabVal
  );

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentfilteredCoResource = filteredCoResource.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredCoResource.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 10);

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 10) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <div className="d-flex justify-content-center align-items-start">
        <div className="py-1 align-items-center justify-content-center main-width">
          <hr />
          <DashboardHeader
            heading="Services/ CO Resources"
            backUrl="/master-services"
          />
          <hr />

          <div className="mb-4 d-flex justify-content-between">
            <Nav
              variant="pills"
              defaultActiveKey="Manual COO (NP)"
              onSelect={handleSelect}
              className="primary-color fw-semibold gap-3 verification-tabs"
              style={{ color: "#0b3b5d" }}
            >
              <NavItem style={{ "min-width": "15%" }} className="text-center">
                <NavLink eventKey="Manual COO (NP)">Manual COO (NP)</NavLink>
              </NavItem>

              <NavItem style={{ "min-width": "15%" }} className="text-center">
                <NavLink eventKey="Digital eCo (NP)">Digital eCo (NP)</NavLink>
              </NavItem>

              <NavItem style={{ "min-width": "15%" }} className="text-center">
                <NavLink eventKey="Non Standard (NSD)">
                  Non Standard Document(NSD)
                </NavLink>
              </NavItem>

              <Button className="bg-success" onClick={handleShowImportModal}>
                Upload
                <FaFileUpload />
              </Button>
            </Nav>

            <div>
              <Form.Select aria-label="Time Filter">
                <option>Select Time Range</option>
                <option value="1">Today</option>
                <option value="2">Yesterday</option>
                <option value="3">Last 3 Months</option>
                <option value="3">Last 6 Months</option>
                <option value="3">Last 1 Year</option>
              </Form.Select>
            </div>
          </div>

          {/* <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Mode of Submission</th>

                <th>Application submitted Date</th>
                <th>COO Ref No.</th>
                <th>Officer Name</th>
                <th>Date of Stamp</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentfilteredCoResource.length > 0 ? (
                currentfilteredCoResource.map((data, index) => (
                  <tr key={indexOfFirstItem + index}>
                    <td>
                      {filteredCoResource.length - indexOfFirstItem - index}.
                    </td>
                    <td>{data.modeOfSub}</td>
                    <td>{data.appSubDate}</td>
                    <td>{data.refNo}</td>
                    <td>{data.officerName}</td>
                    <td>{data.dateOfStamp}</td>
                    <td className="fw-semibold text-success">Verified</td>
                    <td>
                      <a href="#" onClick={() => handleDelCoResource(data._id)}>
                        Delete
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="8">No matching data</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Button
            style={{ backgroundColor: "#0b3b5d", border: "none" }}
            onClick={handleShowAddMore}
          >
            Add New
          </Button> */}

          {tabVal === "Non Standard (NSD)" ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Mode of Submission</th>
                    <th>Application submitted Date</th>
                    <th>NSD Ref No.</th>
                    <th>Date of Stamp</th>
                    <th>Document Type</th>
                    <th>Country</th>
                    <th>status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentfilteredCoResource.length > 0 ? (
                    currentfilteredCoResource.map((data, index) => (
                      <tr key={indexOfFirstItem + index}>
                        <td>
                          {filteredCoResource.length - indexOfFirstItem - index}
                          .
                        </td>
                        <td>{data.modeOfSub}</td>
                        <td>{data.appSubDate}</td>
                        <td>{data.refNo}</td>
                        <td>{data.dateOfStamp}</td>
                        <td>{data.documentType}</td>
                        <td>{data.country}</td>
                        <td className="fw-semibold text-success">Verified</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => handleDelCoResource(data._id)}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="8">No matching data</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Button
                style={{ backgroundColor: "#0b3b5d", border: "none" }}
                onClick={handleShowAddMore}
              >
                Add New
              </Button>
            </>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Mode of Submission</th>

                    <th>Application submitted Date</th>
                    <th>COO Ref No.</th>
                    <th>Officer Name</th>
                    <th>Date of Stamp</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentfilteredCoResource.length > 0 ? (
                    currentfilteredCoResource.map((data, index) => (
                      <tr key={indexOfFirstItem + index}>
                        <td>
                          {filteredCoResource.length - indexOfFirstItem - index}
                          .
                        </td>
                        <td>{data.modeOfSub}</td>
                        <td>{data.appSubDate}</td>
                        <td>{data.refNo}</td>
                        <td>{data.officerName}</td>
                        <td>{data.dateOfStamp}</td>
                        <td className="fw-semibold text-success">Verified</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => handleDelCoResource(data._id)}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="8">No matching data</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <Button
                style={{ backgroundColor: "#0b3b5d", border: "none" }}
                onClick={handleShowAddMore}
              >
                Add New
              </Button>
            </>
          )}

          {/* -------------------------------------------------------------------------------
          -----------------------------adding new document modal------------------------------
          ----------------------------------------------------------------------------------- */}
          <Modal
            show={showAddMore}
            onHide={handleCloseAddMore}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {addOff ? "Add New Officer" : "Add New Document"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                {addOff ? (
                  <>
                    <Form.Group
                      className="mb-3 d-flex gap-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Officer Name"
                        maxLength={50}
                        value={offName}
                        onChange={(e) => setOffName(e.target.value)}
                      />
                      <Button
                        variant="primary"
                        onClick={() => handleUpdateOfficerList("add", offName)}
                      >
                        Add
                      </Button>
                    </Form.Group>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Officer Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {officerList.map((officerName, index) => (
                          <tr key={index}>
                            <td className="fw-semibold">{officerName}</td>
                            <td>
                              <a
                                href="#"
                                onClick={() =>
                                  handleUpdateOfficerList("delete", officerName)
                                }
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="modeOfSub">
                          <Form.Label className="fw-semibold">
                            Mode of Submission
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={formData.modeOfSub}
                            onChange={handleNewDocInputChange}
                          >
                            <option value="">Select mode of submission</option>
                            <option value="Manual COO (NP)">
                              Manual COO (NP)
                            </option>
                            <option value="Digital eCo (NP)">
                              Digital e-Co (NP )
                            </option>
                            <option value="Non Standard (NSD)">
                            Non Standard (NSD)
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    {formData.modeOfSub === "Non Standard (NSD)" && (
    <>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="documentType">
            <Form.Label className="fw-semibold">
              Document Type
            </Form.Label>
            <Form.Control
              as="select"
              value={formData.documentType}
              onChange={handleNewDocInputChange}
            >
              <option value="">Select document type</option>
              {nonStandDocType.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                  ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="country">
            <Form.Label className="fw-semibold">Country</Form.Label>
            <Form.Control
              as="select"
              value={formData.country}
              onChange={handleNewDocInputChange}
            >
              <option value="">Select country</option>
                  {country_list.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                  ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
    </>
  )}

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="appSubDate">
                          <Form.Label className="fw-semibold">
                            Application submitted Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={formData.appSubDate}
                            onChange={handleNewDocInputChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="refNo">
                        <Form.Label className="fw-semibold">
                        {formData.modeOfSub === "Digital eCo (NP)"
        ? "eCo Ref No."
        : formData.modeOfSub === "Manual COO (NP)"
            ? "COO Ref No."
            : "Ref No."}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={formData.modeOfSub === "Digital eCo (NP)"
                            ? "eCO/001/24-25"
                            : formData.modeOfSub === "Manual COO (NP)"
                                ? "12345/24-25"
                                : "eNSD/0001/24-25"}
                            maxLength={20}
                            value={formData.refNo}
                            onChange={handleNewDocInputChange}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="officerName">
                          <Form.Label className="fw-semibold me-2">
                            Officer Name
                          </Form.Label>
                          <a href="#">
                            <FaPen
                              onClick={() => setAddOff(true)}
                              className="small-icon"
                            />
                          </a>
                          <Form.Control
                            as="select"
                            value={formData.officerName}
                            onChange={handleNewDocInputChange}
                            disabled={formData.modeOfSub === "Digital eCo (NP)"}
                          >
                            {formData.modeOfSub === "Digital eCo (NP)" ? (
                              <option
                                value="Jaheer Bukhari (Chairman)"
                                disabled
                              >
                                Jaheer Bukhari (Chairman)
                              </option>
                            ) : (
                              <>
                                <option value="">Select officer</option>

                                {officerList.map((officerName, index) => (
                                  <option key={index} value={officerName}>
                                    {officerName}
                                  </option>
                                ))}
                              </>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="dateOfStamp">
                          <Form.Label className="fw-semibold">
                            Date of Stamp
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={formData.dateOfStamp}
                            onChange={handleNewDocInputChange}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              {addOff ? (
                <Button
                  style={{ backgroundColor: "#0b3b5d" }}
                  onClick={() => setAddOff(false)}
                >
                  Back
                </Button>
              ) : (
                <>
                  <Button
                    style={{ backgroundColor: "#0b3b5d" }}
                    onClick={handleCreateNewCo}
                  >
                    Create New Co
                  </Button>

                  {/* <Button variant="primary" onClick={handleCloseAddMore}>
                    Add More{" "}
                  </Button> */}
                </>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            show={showImportModal}
            onHide={handleCloseImportModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Importing CO Resources</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <Form.Group className="mb-3" controlId="formImportCsv">
                    <Form.Control
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                    />
                    <Form.Text className="text-muted">
                      Please select Excel or CSV file
                    </Form.Text>
                  </Form.Group>

                  <Button
                    style={{ backgroundColor: "#0b3b5d", border: "none" }}
                    onClick={handleImportData}
                  >
                    Import Data
                  </Button>
                </div>
              </Form>
              <div className="mt-3">
                <h5>How to Upload an Excel Sheet</h5>
                <p>
                  Follow these steps to upload an Excel sheet to our
                  application:
                </p>
                <hr />
                <ul>
                  <p>
                    <strong>Select the File:</strong>
                  </p>
                  <li>Click on the "Select File" button.</li>
                  <li>
                    Navigate to the location on your computer where the Excel
                    file is saved.
                  </li>
                  <li>Select the Excel file you wish to upload.</li>
                </ul>
                <ul>
                  <p>
                    <strong>File Format:</strong>
                  </p>

                  <li>
                    Ensure the file is in a supported format. We accept .xlsx
                    and .csv files.
                  </li>
                </ul>
                <ul>
                  <p>
                    <strong>File Content:</strong>
                  </p>
                  <li>
                    The Excel sheet should contain the data you wish to upload.
                    Ensure the data is organized in a clear and consistent
                    manner. For example, each row should represent a single
                    entry, and each column should represent a specific attribute
                    of that entry. The first row of the sheet is often used for
                    headers, which describe the data in each column.
                  </li>
                </ul>
                <ul>
                  <p>
                    <strong>Upload the File:</strong>
                  </p>

                  <li>
                    Once you've selected the correct file, click the "Import"
                    button. The application will process the file and upload the
                    data. This may take a few moments depending on the size of
                    the file and the speed of your internet connection.
                  </li>
                </ul>
                <ul>
                  <p>
                    <strong>Verification:</strong>
                  </p>

                  <li>
                    After the upload is complete, you should see a confirmation
                    message indicating that the data has been successfully
                    imported. You can also verify the upload by checking the
                    data in your application.
                  </li>
                </ul>
                <br />
                <h5 className="text-center">
                  <strong>Ref of Exel Format</strong>
                </h5>
                <Image src={RefExelFormat} alt="Excel Format" fluid />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseImportModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EcoResources;
