import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";

const MasterB2bVirtualForum = () => {
    const navigate = useNavigate();
    return (
        <Layout>
            <MasterNavigationBar>
                <div className="d-flex flex-column px-3">
                    <DashboardHeader
                        heading="VIRTUAL B2B FORUM OCT 2024"
                        backUrl="/master-events"
                    />
                    <hr />
                    <div className="d-flex justify-content-around mb-2">
                        <button
                            type="button"
                            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                            style={{ width: "35%" }}
                            onClick={() => {
                                navigate("/master-b2b-bookings");
                            }}
                            disabled={
                                !getAuthUserCookie().accessList.includes("b2bBookings")
                            }

                        >
                            B2B BOOKINGS
                        </button>
                        <button
                            type="button"
                            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                            style={{ width: "35%" }}
                            onClick={() => {
                                navigate("/master-b2b-events");
                            }}
                            disabled={
                                !getAuthUserCookie().accessList.includes("b2bConnect")
                            }>
                            B2B EVENT REQUEST
                        </button>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-around mb-2">
                        <button
                            type="button"
                            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                            style={{ width: "35%" }}
                            onClick={() => {
                                navigate("/master-country-slot-control");
                            }}
                            disabled={
                                !getAuthUserCookie().accessList.includes("b2bSlotControl")
                            }

                        >
                            B2B COUNTRY SLOTS CONTROL
                        </button>
                        <button
                            type="button"
                            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
                            style={{ width: "35%" }}
                            // onClick={() => {
                            //     navigate("/master-country-slot-control");
                            // }}
                            // disabled={
                            //     !getAuthUserCookie().accessList.includes("b2bSlotControl")
                            // }
                            disabled

                        >
                            B2B VIRTUAL FORUM REPORT
                        </button>
                    </div>
                </div>
            </MasterNavigationBar>
        </Layout>
    );
};

export default MasterB2bVirtualForum;