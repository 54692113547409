import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { getURLbyEndPoint } from "../../../store/api";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HrJdList = () => {
  const [empList, setEmpList] = useState([]); // Move useState out of useEffect

  const navigate = useNavigate();

  const handleNavigate = (empId) => {
    const selectedEmployee = empList.find((emp) => emp._id === empId);
    if (selectedEmployee) {
      navigate("/master-hr-emp-jd", {
        state: { data: selectedEmployee },
      });
    } else {
      console.error(`Employee with ID ${empId} not found in empList.`);
    }
  };

  useEffect(() => {
    const fetchEmpList = async () => {
      try {
        const response = await axios.get(getURLbyEndPoint("getActiveEmpList"));
        if (response.status === 200) {
          const data = response.data;
          setEmpList([...data.data]);
        } else {
          console.error("Failed to employee list data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEmpList();
  }, []);

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Hr/ EMPLOYEE JD LIST"
            backUrl="/master-hr-department"
          />
          <hr />
          <div className="d-flex justify-content-between">
            <h4>EMPLOYEE LIST</h4>
            <h5>Date : {formatDate()}</h5>
          </div>
          <hr />
          <Row>
            {empList.map((data, index) => (
              <Col xs={12} sm={6} md={4} key={index} className="mb-4">
                <Button
                  variant="secondary"
                  className="setup-bg-color border-0 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center w-100"
                  onClick={() => handleNavigate(data._id)}
                >
                  {data.designation}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default HrJdList;

const formatDate = () => {
  const dateObj = new Date();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};
