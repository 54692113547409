import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";

import axios from "axios";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { getURLbyEndPoint } from "../../../store/api";

const CollabrationPortal = () => {
  const [collabrationPortalData, setCollabrationPortalData] = useState("");
  const [accountStatus, setAccountStatus] = useState("");

  const fetchCollabrationPortal = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getCollabrationPortal")
      );
      if (response.status === 200) {
        const data = response.data;

        setCollabrationPortalData([...data.data]);
        console.log("Update Personal Details:", data.data);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCollabrationPortal();
  }, []);
  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Profile / Collabration Portal"
            backUrl="/master-profile"
          />
          <hr />
          <div className="d-flex gap-3 my-4">
            <Form.Check
              inline
              label="All"
              name="group1"
              type="radio"
              defaultChecked
              checked={accountStatus === ""}
              onChange={() => setAccountStatus("")}
            />
            <Form.Check
              inline
              label="NEW"
              name="group1"
              type="radio"
              defaultChecked
              checked={accountStatus === "New"}
              onChange={() => setAccountStatus("New")}
            />{" "}
            <Form.Check
              inline
              label="UPDATED"
              name="group1"
              type="radio"
              defaultChecked
              checked={accountStatus === "Updated"}
              onChange={() => setAccountStatus("Updated")}
            />{" "}
            <Form.Check
              inline
              label="REJECTED"
              name="group1"
              type="radio"
              defaultChecked
              checked={accountStatus === "Rejected"}
              onChange={() => setAccountStatus("Rejected")}
            />{" "}
          </div>
          {Array.isArray(collabrationPortalData) &&
            collabrationPortalData.length > 0 ? (
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th className="align-middle">SR NO.</th>
                  <th className="align-middle">Administrator Details</th>
                  <th className="align-middle">Video Meetings</th>
                  <th className="align-middle">Documents and Forms</th>
                  <th className="align-middle">Review</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(collabrationPortalData) &&
                  collabrationPortalData.length > 0 ? (
                  (() => {
                    const filteredData = collabrationPortalData.filter(
                      (data) => {
                        if (accountStatus === "") return true; // Show all data
                        return data.status === accountStatus; // Filter based on status
                      }
                    );
                    if (filteredData.length > 0) {
                      return filteredData.map((data, index) => (
                        <tr key={index}>
                          <th className="text-start">
                            {index + 1}.{" "}
                            <div className="max-content">
                              Created at:
                              <br />
                              <span className="text-success">
                                {new Intl.DateTimeFormat("en-GB", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(new Date(data.createdAt))}
                              </span>
                            </div>
                          </th>
                          <td>
                            {" "}
                            <div>
                              <h6>
                                <span className="text-secondary">
                                  {" "}
                                  AECCI TEST COLLABORATION LAW
                                  <h6 className="text-secondary">Swapnil Mahadik</h6>
                                  <h6 className="text-secondary">aeccimarketing@gmail.com</h6>
                                  <h6 className="text-secondary">
                                    7057332679
                                  </h6>
                                  <h6 className="text-secondary">
                                    Export Adviser - Sales department
                                  </h6>
                                  <h6 className="text-secondary">
                                    L01631KA2010PTC096843
                                  </h6>
                                  <h6 className="text-secondary">
                                    Navi Mumbai, Maharashtra, india, Panvel, Maharashtra 410206
                                  </h6>
                                </span>
                              </h6>
                            </div>
                            {/* <div className="d-flex">
                              <AiFillCheckCircle className="w-100 icon-size green-color" />
                              <AiFillCloseCircle className="w-100 icon-size text-danger" />
                            </div> */}
                          </td>
                          <td>
                            {" "}
                          </td>
                          <td>
                          </td>
                          <td>

                          </td>
                        </tr>
                      ));
                    } else {
                      return (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No matching data
                          </td>
                        </tr>
                      );
                    }
                  })()
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </Layout>
  );
};


export default CollabrationPortal;
