import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";

const handleSwitchChange = async (empList, setEmpList, e, accessType, id) => {
  const isChecked = e.target.checked;
  const updatedEmpList = empList.map((employee) => {
    if (employee._id === id) {
      const newAccessList = isChecked
        ? [...employee.accessList, accessType]
        : employee.accessList.filter((item) => item !== accessType);
      return { ...employee, accessList: newAccessList };
    }
    return employee;
  });
  setEmpList(updatedEmpList);
};

const handleUpdateEmpAccess = async (empList, userId) => {
  const empData = empList.find((emp) => emp._id === userId);

  try {
    const res = await axios.post(getURLbyEndPoint("updateEmpAccess") + userId, {
      accessList: empData.accessList,
    });

    if (res.data.status) {
      alert("updated successfully");
    } else {
      alert(res.data.message);
    }
  } catch (error) {
    console.error(error);
    alert("something went wrong when updating");
  }
};

const handleEnableAll = (setEmpList, titleVal, id) => {
  setEmpList((prevEmpList) => {
    return prevEmpList.map((employee) => {
      if (employee._id === id) {
        return {
          ...employee,
          accessList: [
            ...new Set([
              ...employee.accessList,
              ...titleVal.map((item) => Object.values(item)[0]),
            ]),
          ],
        };
      }
      return employee;
    });
  });
};

const handleDisableAll = (setEmpList, titleVal, id) => {
  setEmpList((prevEmpList) => {
    return prevEmpList.map((employee) => {
      if (employee._id === id) {
        return {
          ...employee,
          accessList: employee.accessList.filter(
            (item) => !titleVal.some((val) => Object.values(val)[0] === item)
          ),
        };
      }
      return employee;
    });
  });
};

const isAllOptionsIncluded = (titleVal, data) => {
  // Extract all access types from titleVal list
  const allOptions = titleVal.map((item) => Object.values(item)[0]);
  // Check if all options are included in data.accessList
  return allOptions.every((option) => data.accessList.includes(option));
};

export {
  handleSwitchChange,
  handleUpdateEmpAccess,
  handleEnableAll,
  handleDisableAll,
  isAllOptionsIncluded,
};
