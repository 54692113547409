import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { useNavigate } from "react-router-dom";
import { getAuthUserCookie } from "../../../store/services";

const MasterWings = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="The Wings" backUrl="/master-dashboard" />
          <hr />

          <div className="mb-4">
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/export-wing")}
                disabled={!getAuthUserCookie().accessList.includes("ExportWing")}
              >
                EXPORT WING
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/legal-wing")}
                disabled={
                  !getAuthUserCookie().accessList.includes("Legalwing")
                }
              >
                LEGAL WING{" "}
              </button>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/Hr-wing")}
                disabled={!getAuthUserCookie().accessList.includes("Hrwing")}
              >
                HR SUPPORT WING{" "}
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/Business-wing")}
                disabled={
                  !getAuthUserCookie().accessList.includes("Businesswing")
                }
              >
                BUSINESS ADVICE WING{" "}
              </button>
            </div>
            <hr />{" "}
            <div className="d-flex justify-content-around mb-2">
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/Professional-wing")}
                disabled={!getAuthUserCookie().accessList.includes("Professionalwing")}
              >
                PROFESSIONAL WING{" "}
              </button>
              <button
                type="button"
                className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
                style={{ width: "35%" }}
                onClick={() => navigate("/event-and-seminar-wing")}
                disabled={
                  !getAuthUserCookie().accessList.includes("Eventwing")
                }
              >
                EVENT & SEMINAR WING{" "}
              </button>
            </div>
            <hr />
          </div>
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterWings;
