import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Employees from "../../assets/masterImgs/employees.jpg";
import Layout from "../../components/Layouts/Layouts/Layout";
import MasterLayout from "../../components/Layouts/Layouts/MasterLayout";
import { getURLbyEndPoint } from "../../store/api";
import { getAuthUserCookie } from "../../store/services";

const MasterDashboard = () => {
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState("");
  const [notificationData, setNotificationData] = useState([]);

  //notofication values
  const fetchNotification = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getNotificationStatus")
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data;
        setNotificationData(data);
      } else {
        alert("Failed to fetch notification data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      setGreeting("Good Morning");
    } else if (currentTime >= 12 && currentTime < 17) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
    fetchNotification();
  }, []);

  return (
    <Layout>
      <MasterLayout>
        <div className="d-flex flex-column px-3">
          <div className="d-flex align-items-center justify-content-between w-100 mb-3">
            <div
              className="text-spacing fs-1 fw-medium"
              style={{ textDecoration: "none", color: "#2c2392" }}
            >
              DASHBOARD
            </div>
            <div>
              <img src={Employees} style={{ height: "8vh" }} alt="" srcset="" />{" "}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <h3>{greeting},</h3>
              <h6>Welcome, {getAuthUserCookie().email}</h6>
            </div>
            <div className="w-50 d-flex justify-content-end gap-3">
              <button
                type="button"
                className="bg-color-2 mt-4 p-2 px-3 rounded-1"
                onClick={() => navigate("/login")}
              >
                Log Out
              </button>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => {
                navigate("/master-administration");
              }}
            >
              ADMIN DEPARTMENT{" "}
            </button>
          </div>
          <hr />

          <div className="d-flex justify-content-around mb-2">
            <button
              onClick={() => {
                navigate("/master-profile");
              }}
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
            >
              PROFILE{" "}
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.Profile !== 0
                  ? notificationData.Profile
                  : null}
              </span>
            </button>

            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-services");
              }}
            >
              SERVICES{" "}
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.Services !== 0
                  ? notificationData.Services
                  : null}
              </span>
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "85%" }}
              onClick={() => {
                navigate("/master-hr-department");
              }}
            >
              HR DEPARTMENT
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-wings");
              }}
            >
              THE WINGS
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-arbitration");
              }}
            >
              ARBITRATION CENTER
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            {/* <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-news");
              }}
            >
              NEWS ALERT
            </button> */}

            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-events");
              }}
            >
              EVENTS
            </button>

            {/* <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              onClick={() => navigate("/publications")}
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-publication");
              }}
            > */}
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-publication-ads");
              }}
              // disabled={
              //   !getAuthUserCookie().accessList.includes("Publications")
              // }
            >
              PUBLICATIONS & AD's
            </button>
          </div>

          <hr />

          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/special-controls");
              }}
            >
              SPECIAL CONTROLS
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-report-analysis");
              }}
            >
              REPORT & ANALYSIS
            </button>
          </div>

          <hr />
        </div>
      </MasterLayout>
    </Layout>
  );
};

export default MasterDashboard;

// <EPlatformHeader />
// <div className="d-flex flex-column justify-content-center align-items-center">
//   <div className="text-center main-width">
//     <hr />
//   </div>
//   <div className="d-flex justify-content-between align-items-center main-width">
//     <div
//       className="w-25 p-4 text-center mb-4 bg-grey rounded"
//       style={{ minHeight: "70vh" }}
//     >
//       <img
//         src={chiefImg}
//         className="mb-3"
//         alt=""
//         srcset=""
//         height="230vh"
//       />
//       <h5 className="mb-5">Welcome to AECCI..!</h5>
//       <h5 className="mb-5">
//         “Our e-Platform bridges cultures, connects economies, and opens
//         doors to prosperity.”
//       </h5>
//       <h6 className="text-secondary">
//         <i>-Shri Jaheer J. Bukhari Hon’ble Chairman- AECCI</i>
//       </h6>
//     </div>
//     <div className="w-75">hello</div>
//   </div>
// </div>
