import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import { Doughnut, Line } from "react-chartjs-2";
import axios from "axios";
import { getURLbyEndPoint, getURLbyEndPointV2 } from "../../../store/api";
import { FaQuestionCircle } from "react-icons/fa";
import { Chart, registerables, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(...registerables, ArcElement, Tooltip, Legend);

const MasterAnalysis = () => {
  const [walletData, setWalletData] = useState([]);
  const [analysisData, setAnalysisData] = useState([]);

  //for Sales Filters
  const [saleStartDate, setSaleStartDate] = useState("");
  const [saleEndDate, setSaleEndDate] = useState("");

  //for Analysis
  const [selSer, setSelSer] = useState("Electronic Certificate");
  const [analysisDate, setAnalysisDate] = useState("");

  useEffect(() => {
    const fetchEcoData = async () => {
      try {
        const res = await axios.get(getURLbyEndPointV2("walletData"));
        if (res.status === 200) {
          const data = res.data.data;
          setWalletData(data);
        } else {
          alert("Failed to fetch co data.");
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };

    const fetchAllSerData = async () => {
      try {
        const res = await axios.get(getURLbyEndPointV2("getAllAnalysisData"));
        if (res.status === 200) {
          const data = res.data.data;
          setAnalysisData(data);
        } else {
          alert("Failed to fetch co data.");
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };
    fetchAllSerData();
    fetchEcoData();
  }, []);

  const [selectedAnalysisData, setSelectedAnalysisData] = useState(null);
  useEffect(() => {
    if (selSer) {
      const data = analysisData.find((item) => item.label === selSer);
      setSelectedAnalysisData(data);
    } else {
      setSelectedAnalysisData(null);
    }
  }, [selSer, analysisData]);

  const calculateSerTotalAmount = (particular) => {
    const ecoTotal = walletData.reduce((total, transaction) => {
      const transactionDate = new Date(transaction.updatedAt)
        .toISOString()
        .split("T")[0];
      if (
        transaction.Particular === particular &&
        (!saleStartDate || transactionDate >= saleStartDate) &&
        (!saleEndDate || transactionDate <= saleEndDate)
      ) {
        return total + transaction.amount;
      }
      return total;
    }, 0);
    return ecoTotal;
  };

  const calculateTotalAmount = (field) => {
    const total = walletData.reduce((total, transaction) => {
      const transactionDate = new Date(transaction.createdAt)
        .toISOString()
        .split("T")[0];
      if (
        transaction.paymentType === field &&
        (!saleStartDate || transactionDate >= saleStartDate) &&
        (!saleEndDate || transactionDate <= saleEndDate)
      ) {
        return total + transaction.amount;
      }
      return total;
    }, 0);
    return total;
  };

  //ALL GRAPH SETUP------------------------------------------------------------------
  //---------------------------------------------------------------------------------
  const data = {
    labels: [
      "Electronic-CO",
      "Semi-Electronic-CO",
      "Non-Standard",
      "Visa Letter",
    ],
    datasets: [
      {
        label: "Total Sale",
        data: [
          calculateSerTotalAmount("Electronic Certificate"),
          calculateSerTotalAmount("Semi-Electronic Certificate"),
          calculateSerTotalAmount("Non-Standard"),
          calculateSerTotalAmount("Recommendation Letter"),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 0, 130, 0.6)",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const getCurrentHour = () => {
    const date = new Date();
    return date.getHours();
  };

  const getXAxisLabels = () => {
    const currentHour = getCurrentHour();
    const labels = [];

    for (let i = currentHour - 12 + 1; i <= currentHour; i++) {
      const hour = i < 0 ? 24 + i : i; // Handle negative hours by wrapping around midnight
      const timeLabel =
        hour === 0
          ? `12 AM`
          : hour === 12
          ? `12 PM`
          : hour > 12
          ? `${hour - 12} PM`
          : `${hour} AM`;
      labels.push(timeLabel);
    }

    return labels;
  };
  // Data for Line chart
  const getColor = (label) => {
    switch (label) {
      case "Electronic Certificate":
        return "rgb(255, 99, 132)"; // Red
      case "Semi-Electronic Certificate":
        return "rgb(54, 162, 235)"; // Blue
      case "Non-Standard":
        return "rgb(255, 205, 86)"; // Yellow
      default:
        return "rgb(75, 192, 192)"; // Green, as a default color
    }
  };

  const lineData = {
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: analysisData.map((item) => ({
      label: item.label,
      data: item.data,
      fill: false,
      borderColor: getColor(item.label),
      tension: 0.1,
    })),
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category", // Specify the x-axis scale type as category
        labels: getXAxisLabels(), // Provide the category labels
      },
    },
  };

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Analysis"
            backUrl="/master-report-analysis"
          />
          <hr />

          <div className="d-flex justify-content-between gap-3 mb-3">
            <Card
              className="text-black w-50"
              style={{
                backgroundColor: "#f0f0f0",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                border: "none",
              }}
            >
              <Card.Body>
                <Card.Title className="mb-3">
                  Analysis <FaQuestionCircle className="fs-3 ms-1 text-info" />
                </Card.Title>

                {/* <Row className="align-items-center mb-5"> */}
                <div className="d-flex align-items-center w-50 mb-5">
                  <Card.Subtitle className="no-wrap me-2">
                    {" "}
                    Select Service :
                  </Card.Subtitle>

                  <Form.Control
                    as="select"
                    name="serType"
                    value={selSer}
                    onChange={(e) => setSelSer(e.target.value)}
                  >
                    <option value="Electronic Certificate">Electronic Certificate</option>
                    <option value="Semi-Electronic Certificate">
                      Semi-Electronic Certificate
                    </option>
                    <option value="Non-Standard">Non-Standard Document</option>
                  </Form.Control>
                </div>
                {/* <div className="d-flex align-items-center w-50">
                    <Card.Subtitle className="no-wrap me-2">
                      {" "}
                      Select Date :
                    </Card.Subtitle>

                    <Form.Control
                      type="date"
                      name="FromDate"
                      value={analysisDate}
                      onChange={(e) => setAnalysisDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </Row> */}
                <Row>
                  <Col>
                    <Card.Subtitle className="text-success">
                      Applications
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      {selectedAnalysisData?.app}
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Total Approved
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      {selectedAnalysisData?.appApp}
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Total Rejected
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      {selectedAnalysisData?.appRej}
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Total Sale
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      ₹ {selectedAnalysisData?.totSale}/-
                    </Card.Text>
                  </Col>
                  <Col>
                    <div style={{ height: "400px", width: "450px" }}>
                      <Line data={lineData} options={lineOptions} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* //---------------ANALYSIS------------------------------------------
            -------------------------------------------------------------------
            ------------------------------------------------------------------- */}
            <Card
              className="text-black w-50"
              style={{
                backgroundColor: "#f0f0f0",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                border: "none",
              }}
            >
              <Card.Body>
                <Card.Title className="mb-3">Sales Details</Card.Title>

                <Row className="align-items-center mb-5">
                  <div className="d-flex align-items-center w-50 mb-3">
                    <Card.Subtitle className="no-wrap me-2">
                      {" "}
                      Select Date :
                    </Card.Subtitle>

                    <Form.Control
                      type="date"
                      name="FromDate"
                      value={saleStartDate}
                      onChange={(e) => setSaleStartDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <div className="d-flex align-items-center w-50 mb-3">
                    <Card.Subtitle className="no-wrap me-2">
                      {" "}
                      End Date :
                    </Card.Subtitle>

                    <Form.Control
                      type="date"
                      name="saleEndDate"
                      value={saleEndDate}
                      onChange={(e) => setSaleEndDate(e.target.value)}
                      min={saleStartDate}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <Card.Subtitle className="text-success">
                      Total Sale
                    </Card.Subtitle>
                    <Card.Text className="fs-4">
                      ₹ {calculateTotalAmount("debit")}/-
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Subtitle className="text-success">
                      Total Credit
                    </Card.Subtitle>
                    <Card.Text className="fs-4">
                      ₹ {calculateTotalAmount("credit")}/-
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Subtitle className="text-success">
                      Total Debit
                    </Card.Subtitle>
                    <Card.Text className="fs-4">
                      ₹ {calculateTotalAmount("debit")}/-
                    </Card.Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card.Subtitle className="text-success">
                      Sale By eCO
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      ₹ {calculateSerTotalAmount("Electronic Certificate")}/-
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Sale By SeCO
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      ₹ {calculateSerTotalAmount("Semi-Electronic Certificate")}
                      /-
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Sale By NSD
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      ₹ {calculateSerTotalAmount("Non-Standard")}/-
                    </Card.Text>
                    <Card.Subtitle className="text-success">
                      Sale By Visa Letter
                    </Card.Subtitle>
                    <Card.Text className="mb-5 fs-4">
                      ₹ {calculateSerTotalAmount("Recommendation Letter")}/-
                    </Card.Text>
                  </Col>
                  <Col>
                    <div style={{ height: "400px", width: "450px" }}>
                      <Doughnut data={data} options={options} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MasterAnalysis;
