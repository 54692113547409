import React, { useState, useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../components/Layouts/Layouts/MasterServicesLayout";
import { useNavigate } from "react-router-dom";
import Employees from "../../assets/masterImgs/employees.jpg";
import { getAuthUserCookie } from "../../store/services";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";

const EmpDashboard = () => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);

  const date =new Date()
console.log(date.toLocaleTimeString());

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          getURLbyEndPoint("getNotificationStatus")
        );
        console.log(response);
        if (response.status === 200) {
          const data = response.data.data;
          setNotificationData(data);
        } else {
          alert("Failed to fetch notification data.");
        }
      } catch (error) {
        alert("Error fetching data:", error);
      }
    };

    fetchNotification();
  }, []);

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex align-items-center justify-content-between w-100 mb-3">
          <div
            className="text-spacing fs-1 fw-medium"
            style={{ textDecoration: "none", color: "#2c2392" }}
          >
            DASHBOARD
          </div>
          <div>
            <img src={Employees} style={{ height: "8vh" }} alt="" srcset="" />{" "}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div>
            <h3>Good Morning,</h3>
            <h6>Welcome, {getAuthUserCookie().email}</h6>
          </div>
          <div className="w-50 d-flex justify-content-end gap-3">
            <button
              type="button"
              className="bg-color-2 mt-4 p-2 px-3 rounded-1"
              onClick={() => navigate("/login")}
            >
              Log Out
            </button>
          </div>
        </div>
        <hr />

        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "85%" }}
            onClick={() => {
              navigate("/master-administration");
            }}
          >
            ADMIN DEPARTMENT
          </button>
        </div>
        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            onClick={() => {
              navigate("/master-profile");
            }}
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
            style={{ width: "35%" }}
          >
            PROFILE{" "}
            <span
              className="badge bg-primary position-absolute fs-6"
              style={{ top: "-8px", right: "-8px" }}
            >
              {notificationData.Profile !== 0 ? notificationData.Profile : null}
            </span>
          </button>

          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/master-services");
            }}
          >
            SERVICES{" "}
            <span
              className="badge bg-primary position-absolute fs-6"
              style={{ top: "-8px", right: "-8px" }}
            >
              {notificationData.Services !== 0
                ? notificationData.Services
                : null}
            </span>
          </button>
        </div>
        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "85%" }}
            onClick={() => navigate("/master-hr-department")}
          >
            HR DEPARTMENT
          </button>
        </div>
        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => navigate("/master-wings")}
          >
            THE WINGS
          </button>
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/master-arbitration");
            }}
          >
            ARBITRATION CENTER
          </button>
        </div>
        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/master-events");
            }}
          >
            EVENTS
          </button>
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/master-publication-ads");
            }}
          >
            PUBLICATION & AD's
          </button>
        </div>
        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/special-controls");
            }}
          >
            SPECIAL CONTROLS
          </button>
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "35%" }}
            onClick={() => {
              navigate("/master-report-analysis");
            }}
          >
            REPORT & ANALYSIS
          </button>
        </div>

        <hr />
        <div className="d-flex justify-content-around mb-2">
          <button
            type="button"
            className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
            style={{ width: "85%" }}
            onClick={() => navigate("/master-hr-daily-report")}
          >
            CREATE DAILY REPORT
          </button>
        </div>
        <hr />
      </MasterNavigationBar>
    </Layout>
  );
};

export default EmpDashboard;
