import React from "react";
import chiefImg from "../../assets/chief.png";

const MasterSideBar = () => {
  return (
    <div
      className="p-4 text-center mb-4 bg-grey rounded d-flex flex-column justify-content-center align-items-center mobileViews"
      style={{ minHeight: "70vh", height: "100%" }}
    >
      <img
        src={chiefImg}
        className="mb-3"
        alt=""
        srcset=""
        height="80%"
        width="80%"
      />
      <h5 className="mb-5">Welcome to AECCI..!</h5>
      <h5 className="mb-5">
        “Our e-Platform bridges cultures, connects economies, and opens doors to
        prosperity.”
      </h5>
      <h6 className="text-secondary">
        <i>-Shri Jaheer J. Bukhari Hon’ble Chairman- AECCI</i>
      </h6>
    </div>
  );
};

export default MasterSideBar;
