export const formatDateAndTime = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(date);

  return formattedDate;
};
